import {
	EuiButton,
	EuiFilePicker,
	EuiFlexGroup,
	EuiEmptyPrompt,
	EuiFlexItem,
	EuiPanel,
	EuiProgress,
	EuiSpacer,
	EuiTitle,
	EuiText,
	EuiCallOut,
	EuiLoadingContent,
} from "@elastic/eui";
import { navigate, useMatch } from "@reach/router";
import axios from "axios";
import { useEffect, useState } from "react";
import { API_PATH, useApi } from "../../effects/useApi";
import {
	Formation,
	FormationVersionInstance,
	PracticalEvaluationModule,
	PracticalEvaluationModuleInstance,
	User,
} from "../../types";

interface CurrentAttempt {
	started_at: Date | null;
	submitted_at: Date | null;
	score: number;
	is_validated: boolean;
	answers: Array<{
		question: string;
		answer: string;
	}>;
}

export default function PracticalEvaluationModulePage({
	module_instance,
	formation_instance_id,
	refetch_formation,
	refetch_module_instance,
	formation_version_instance,
}: {
	module_instance: PracticalEvaluationModuleInstance;
	formation_instance_id?: string;
	refetch_formation?: () => void;
	refetch_module_instance: () => void;
	formation_version_instance: FormationVersionInstance;
}) {
	const module =
		module_instance.module as unknown as PracticalEvaluationModule;

	// const match = useMatch("/formation_instances/:formation_instance_id/:module_instance_id/:page_id") as unknown as { page_id: string }

	const [publish_video] = useApi(
		"POST",
		`formation_instances/${formation_instance_id}/module_instances/${module_instance._id}/publish_video`,
		async (err, module_instance) => {
			if (err) return console.error(err);
			// setCurrentAttempt(module_instance.current_attempt)

			await refetch_module_instance();
			await refetch_formation?.();

			if (is_corrector) navigate("/");
		}
	);

	const [bypasss_video] = useApi(
		"POST",
		`formation_instances/${formation_instance_id}/module_instances/${module_instance._id}/publish_video`,
		async (err, _) => {
			if (err) return console.error(err);
			// setCurrentAttempt(module_instance.current_attempt)

			// await refetch_module_instance()
			// await refetch_formation?.()

			navigate(`/corrections/${module_instance._id}`);
		}
	);

	const [retry_module] = useApi(
		"POST",
		`formation_instances/${formation_instance_id}/module_instances/${module_instance._id}/retry`,
		async (err, module_instance) => {
			if (err) return console.error(err);
			await refetch_module_instance();
			await refetch_formation?.();
		}
	);

	const [progress, setProgress] = useState(0);
	const [file_size, setFileSize] = useState(0);

	const [media_id, setMediaId] = useState<string | null>(null);
	const [uploaded_file, setUploadedFile] = useState<{
		name: string;
		size: number;
		type: string;
		utl: string;
	} | null>(null);

	const [me, setMe] = useState<User | null>(null);

	const [fetch_me] = useApi("GET", `users/me`, async (err, me) => {
		if (err) return console.error(err);
		setMe(me);
	});

	useEffect(() => {
		fetch_me();
	}, []);

	const is_corrector = me?.formation_corrector_of.includes(
		formation_version_instance.formation._id
	);

	if (!module_instance) return <EuiLoadingContent lines={5} />;

	const onChange = (files: FileList) => {
		const formData = new FormData();

		for (let i = 0; i < files.length; i++) {
			formData.append("file", files[i]);
		}

		const config = {
			onUploadProgress: (progressEvent) => {
				setProgress(progressEvent.loaded);
				setFileSize(progressEvent.total);
			},
			headers: { "content-type": "multipart/form-data" },
		};

		axios
			.post(API_PATH + "upload", formData, config)
			.then(({ data }: any) => {
				setMediaId(data.media_id);
				setUploadedFile(data.file);
			})
			.catch((error) => {
				console.error(error);
			});
	};

	if (module_instance.is_rejected) {
		return (
			<EuiEmptyPrompt
				iconType="cross"
				title={<h2>Evaluation pratique rejetée</h2>}
				body={<p>Votre évaluation pratique n'a pas été validée.</p>}
				actions={[
					<EuiButton onClick={() => retry_module()}>
						Ré-essayer
					</EuiButton>,
				]}
			/>
		);
	}

	if (
		(module_instance.ignore_video || module_instance.has_video) &&
		!module_instance.is_validated
	) {
		return (
			<>
				<EuiEmptyPrompt
					iconType="clock"
					title={<h2>En cours de correction</h2>}
					body={
						<>
							<p>
								Votre évaluation pratique est en train d'être
								corrigée. Vous recevrez bientôt une notification
								vous indiquant le résultat.
							</p>
							<p>
								Si le résultat est positif, vous n'aurez rien
								d'autre à faire.
							</p>
							<p>
								Si le résultat est négatif, vous devrez reposter
								une vidéo.
							</p>
						</>
					}
					// actions={[<EuiButton>Module suivant</EuiButton>]}
				/>
			</>
		);
	}

	if (module_instance.is_validated) {
		return (
			<>
				<EuiCallOut color="success" iconType="check" size="m">
					<EuiFlexGroup
						gutterSize="none"
						style={{ placeItems: "center" }}
					>
						<EuiFlexItem>
							<EuiText size="m" color="success">
								Ce module est complet, vous pouvez accéder à vos
								attestations.
							</EuiText>
						</EuiFlexItem>
						<EuiFlexItem grow={false}>
							<EuiButton
								color="success"
								onClick={() =>
									navigate(
										"/formation_instances/" +
											formation_version_instance._id +
											"/certificates"
									)
								}
							>
								Mes attestations
							</EuiButton>
						</EuiFlexItem>
					</EuiFlexGroup>
				</EuiCallOut>
				<EuiSpacer size="l" />

				<EuiEmptyPrompt
					iconType="check"
					title={<h2>Evaluation pratique validée</h2>}
					body={
						<p>
							Félicitations, votre évaluation pratique a été
							validée par un vérificateur.
						</p>
					}
				/>
			</>
		);
	}

	return (
		<>
			<EuiPanel paddingSize="s" hasBorder>
				{/* {JSON.stringify(module.formation)} */}
				<EuiFlexGroup gutterSize="s">
					<EuiFlexItem
						style={{ textAlign: "center", placeContent: "center" }}
					></EuiFlexItem>

					{(module.formation as unknown as Formation).is_live &&
					is_corrector ? (
						<EuiFlexItem grow={false}>
							<EuiButton
								size="s"
								color="primary"
								onClick={async () => {
									bypasss_video({
										submitted_on: new Date(),
										ignore_video: true,
									});
								}}
							>
								Passer au questionnaire d’évaluation
							</EuiButton>
						</EuiFlexItem>
					) : (
						<></>
					)}

					<EuiFlexItem grow={false}>
						<EuiButton
							size="s"
							color="primary"
							iconSide="right"
							iconType="check"
							isDisabled={!media_id}
							onClick={async () => {
								publish_video({
									submitted_on: new Date(),
									media_id,
									has_video: true,
								});
							}}
						>
							Publier la vidéo
						</EuiButton>
					</EuiFlexItem>
				</EuiFlexGroup>
			</EuiPanel>

			<EuiSpacer size="m" />

			<EuiCallOut color="primary">
				Merci de filmer votre pratique et de téléverser votre vidéo.
				{/* <br />
				L’évaluateur vérifiera que tous les critères de compétences
				listés ci-dessous sont acquis. */}
			</EuiCallOut>

			<EuiSpacer size="m" />

			<EuiPanel paddingSize="s" hasBorder>
				<EuiText>
					{/* Vous devez valider {module.minimal_ratio}% des compétences
					pour réussir l’examen. Il n'y a pas de temps limite. */}
					Vous devez respecter les consignes ci-dessous.
				</EuiText>
			</EuiPanel>

			<EuiSpacer size="m" />

			<EuiPanel hasBorder>
				<EuiProgress value={progress} max={file_size} size="m" />

				<EuiSpacer size="m" />

				<EuiFilePicker
					id={"file-picker"}
					initialPromptText="Choisissez une vidéo à téléverser"
					onChange={onChange}
					display={"large"}
					fullWidth
				/>
			</EuiPanel>

			<EuiSpacer size="l" />

			<EuiPanel hasBorder>
				<EuiTitle>
					<h2>Consignes</h2>
				</EuiTitle>

				<EuiSpacer size="m" />

				{module_instance.achievements.map((achievement, index) => (
					<EuiText>
						<p>- {achievement.text}</p>
					</EuiText>
				))}
			</EuiPanel>

			{/* <pre>
			{JSON.stringify(module_instance, null, 2)}
		</pre> */}
		</>
	);
}
