import {
	EuiBasicTable,
	EuiButton,
	EuiCallOut,
	EuiComboBox,
	EuiDatePicker,
	EuiDatePickerRange,
	EuiDescribedFormGroup,
	EuiFlexGroup,
	EuiFlexItem,
	EuiFormRow,
	EuiIcon,
	EuiModal,
	EuiModalBody,
	EuiModalFooter,
	EuiModalHeader,
	EuiModalHeaderTitle,
	EuiPanel,
	EuiSelect,
	EuiSpacer,
	EuiTab,
	EuiTabs,
	EuiTextArea,
} from "@elastic/eui";
import { Redirect, Router, useMatch } from "@reach/router";
import moment from "moment";
import { Types } from "mongoose";
import { useEffect, useState } from "react";
import { useApi } from "../../effects/useApi";
import { Formation, User } from "../../types";
import Comment from "../../components/Comment";

interface Stat {
	_id: string;
	operator_id: string;
	operator_name: string;

	formation_id: string;
	formation_name: string;

	old_status: string;
	new_status: string;

	date: string;

	commentary: string;
	validated: boolean;
	validation_date: string;

	recycled: boolean;
}

export default function StatusChanges({
	path,
	laboratory_id,
	navigate,
	me,
}: {
	path: string;
	laboratory_id?: string;
	navigate?: (string) => void;
	me: User;
}) {
	const match = useMatch("/laboratories/:lab_id/status_changes/:tab");

	const [stats, setStats] = useState<Stat[]>([]);

	const [show_modal, setShowModal] = useState<boolean>(false);

	const [possible_operators, setPossibleOperators] = useState<
		{ label: string; value?: string }[]
	>([]);
	const [possible_formations, setPossibleFormations] = useState<
		{ label: string; value?: string }[]
	>([]);

	const [operators_filter, setOperatorsFilter] = useState<
		{ label: string; value?: string }[]
	>([]);
	const [formations_filter, setFormationsFilter] = useState<
		{ label: string; value?: string }[]
	>([]);

	const [start_date_filter, setStartDateFilter] =
		useState<moment.Moment | null>(null);
	const [end_date_filter, setEndDateFilter] = useState<moment.Moment | null>(
		null
	);

	const [status_filter, setStatusFilter] = useState<string>("all");

	const [page, setPage] = useState<number>(0);
	const [count, setCount] = useState<number>(0);

	const [fetch_ranges] = useApi(
		"GET",
		`status_changes/laboratories/${laboratory_id}/ranges`,
		(err, { possible_formations, possible_operators }) => {
			if (err) {
				console.error(err);
				return;
			}
			setPossibleFormations(
				possible_formations
					.filter((formation) => {
						if (me.referent_of.length > 0) return true;
						if (
							me.biologist_of.length > 0 &&
							me.biologist_of.includes(formation._id)
						)
							return true;
						return false;
					})
					.map((formation: Formation) => ({
						_id: formation._id,
						label: formation.header,
					}))
			);
			setPossibleOperators(
				possible_operators.map((operator: User) => ({
					label: `${operator.firstname} ${operator.lastname}`,
					_id: operator._id,
				}))
			);
		}
	);

	const [fetch_stats] = useApi(
		"GET",
		`status_changes/laboratories/${laboratory_id}`,
		(err, { stats, page, count }) => {
			if (err) {
				console.error(err);
				return;
			}

			setPage(page);
			setCount(count);

			setStats(
				stats.filter((stat) => {
					if (me.referent_of.length > 0) return true;
					if (
						me.biologist_of.length > 0 &&
						me.biologist_of.includes(stat.formation_id)
					)
						return true;

					return false;
				})
			);
		}
	);

	const [mark_as_validated] = useApi(
		"POST",
		`status_changes/handle`,
		(err) => {
			if (err) {
				console.error(err);
				return;
			}

			fetch_stats(build_body({}));
		}
	);

	const [update_commentary] = useApi(
		"POST",
		`status_changes/commentary`,
		(err) => {
			if (err) {
				console.error(err);
				return;
			}

			fetch_stats(build_body({}));

			setShowModal(false);
		}
	);

	const [opened_commentary, setOpenedCommentary] = useState<Stat | null>(
		null
	);

	const modal = (
		<EuiModal
			onClose={() => {
				setShowModal(false);
			}}
		>
			<EuiModalHeader>
				<EuiModalHeaderTitle>
					<h1>Modifier le commentaire</h1>
				</EuiModalHeaderTitle>
			</EuiModalHeader>

			<EuiModalBody>
				{opened_commentary && (
					<>
						<EuiTextArea
							placeholder="Commentaire"
							value={opened_commentary.commentary}
							onChange={(e) => {
								setOpenedCommentary({
									...opened_commentary,
									commentary: e.target.value,
								});
							}}
						/>

						{opened_commentary.commentary && (
							<>
								<EuiSpacer size="m" />
								<Comment text={opened_commentary.commentary} />
							</>
						)}
					</>
				)}
			</EuiModalBody>

			<EuiModalFooter>
				<EuiButton
					onClick={() => {
						update_commentary({
							_id: opened_commentary._id,
							commentary: opened_commentary.commentary,
						});

						setOpenedCommentary(null);
						setShowModal(false);
					}}
					color="primary"
					fill
				>
					Valider
				</EuiButton>
			</EuiModalFooter>
		</EuiModal>
	);

	const show_comment_modal = (status_change: Stat, comment: string) => {
		setOpenedCommentary(status_change);
		setShowModal(true);
	};

	useEffect(() => {
		fetch_ranges({ as: me._id });
		fetch_stats({ as: me._id });
	}, [laboratory_id, path]);

	function build_body(body: any) {
		return {
			page,
			as: me._id,
			// @ts-ignore
			selected_operators: operators_filter.map((v) => v._id),
			// @ts-ignore
			selected_formations: formations_filter.map((v) => v._id),
			start_date: start_date_filter?.format("YYYY-MM-DD"),
			end_date: end_date_filter?.format("YYYY-MM-DD"),
			selected_status: status_filter,
			validated: match?.tab || "unhandled",
			...body,
		};
	}

	if (!match) return <Redirect to={`unhandled`} noThrow />;

	let columns = [
		// {
		// field: 'instance',
		// name: "id"
		// },
		{
			field: "operator_name",
			name: "Opérateur",
			render: (name, stat) => <strong>{name}</strong>,
		},
		{
			field: "formation_name",
			name: "Évaluation",
		},
		{
			field: "date",
			name: "Date de changement de statut",
			// render: (start_date, stat) => <>{moment(start_date).format('DD/MM/YYYY')} — {moment(stat.end_date).format('DD/MM/YYYY')}</>
			render: (date, stat) => {
				return moment(date).format("DD/MM/YYYY");
			},
		},
		{
			field: "old_status",
			name: "Ancien statut",
			render: (old_status) => {
				switch (old_status) {
					case "ss":
						return (
							<EuiCallOut
								style={{
									padding: 4,
									borderRadius: 4,
								}}
								color="danger"
							>
								Non évalué
							</EuiCallOut>
						);
					case "expired":
						return (
							<EuiCallOut
								style={{
									padding: 4,
									borderRadius: 4,
								}}
								color="warning"
							>
								Évaluation expirée
							</EuiCallOut>
						);
					case "validated":
						return (
							<EuiCallOut
								style={{
									padding: 4,
									borderRadius: 4,
								}}
								color="success"
							>
								Évaluation validée
							</EuiCallOut>
						);
					case "canceled":
						return (
							<EuiCallOut
								style={{
									padding: 4,
									borderRadius: 4,
									background: "#eee",
								}}
								color="danger"
							>
								Évaluation invalidée
							</EuiCallOut>
						);
					case "suspended":
						return (
							<EuiCallOut
								style={{
									padding: 4,
									borderRadius: 4,
								}}
								color="danger"
							>
								Évaluation suspendue
							</EuiCallOut>
						);

					case "unknown":
						return <>—</>;
				}
			},
		},
		{
			field: "new_status",
			name: "Nouveau statut",
			render: (new_status) => {
				switch (new_status) {
					case "in_progress":
						return (
							<EuiCallOut
								style={{
									padding: 4,
									borderRadius: 4,
								}}
								color="danger"
							>
								Non évalué
							</EuiCallOut>
						);
					case "expired":
						return (
							<EuiCallOut
								style={{
									padding: 4,
									borderRadius: 4,
								}}
								color="warning"
							>
								Évaluation expirée
							</EuiCallOut>
						);
					case "validated":
						return (
							<EuiCallOut
								style={{
									padding: 4,
									borderRadius: 4,
								}}
								color="success"
							>
								Évaluation validée
							</EuiCallOut>
						);
					case "canceled":
						return (
							<EuiCallOut
								style={{
									padding: 4,
									borderRadius: 4,
									background: "#eee",
								}}
								color="danger"
							>
								Évaluation invalidée
							</EuiCallOut>
						);
					case "suspended":
						return (
							<EuiCallOut
								style={{
									padding: 4,
									borderRadius: 4,
								}}
								color="danger"
							>
								Évaluation suspendue
							</EuiCallOut>
						);
				}
			},
		},
		{
			field: "commentary",
			name: "Commentaire",
			render: (commentary, status_change) => {
				return (
					<EuiButton
						size="s"
						color="text"
						iconType={commentary ? "documentEdit" : "plus"}
						onClick={() =>
							show_comment_modal(status_change, commentary)
						}
					>
						Commentaire
					</EuiButton>
				);
			},
		},
		{
			field: "recycled",
			name: "Évaluation recyclé",

			render: (recycled, status_change) => {
				return recycled ? <>♻️</> : <></>;
			},
		},
	];

	if (match && match.tab === "unhandled") {
		columns.push({
			field: "_id",
			name: "Marquer comme traité",
			render: (id, obj) => {
				return (
					<EuiButton
						size="s"
						color="primary"
						onClick={() =>
							mark_as_validated({
								_id: obj._id as unknown as string,
							})
						}
					>
						Traiter
					</EuiButton>
				);
			},
		});
	} else {
		columns.push({
			field: "validation_date",
			name: "Date de traitement",
			render: (validation_date, obj) => {
				return moment(validation_date).format("DD/MM/YYYY");
			},
		});
	}

	return (
		<>
			{show_modal && modal}
			<EuiTabs>
				{(me.referent_of.length > 0 ||
					me.laboratory_biologist_of.length > 0) && (
					<EuiTab
						isSelected={match?.tab === "unhandled"}
						onClick={() => {
							navigate("unhandled");

							setOperatorsFilter([]);
							setFormationsFilter([]);
							setStartDateFilter(null);
							setEndDateFilter(null);

							fetch_stats(build_body({ validated: "unhandled" }));
						}}
					>
						En attente
					</EuiTab>
				)}
				{(me.referent_of.length > 0 ||
					me.laboratory_biologist_of.length > 0) && (
					<EuiTab
						isSelected={match?.tab === "handled"}
						onClick={() => {
							navigate("handled");

							setOperatorsFilter([]);
							setFormationsFilter([]);
							setStartDateFilter(null);
							setEndDateFilter(null);

							fetch_stats(build_body({ validated: "handled" }));
						}}
					>
						Terminé
					</EuiTab>
				)}
			</EuiTabs>

			<EuiSpacer size="m" />

			<EuiPanel hasBorder>
				<EuiDescribedFormGroup
					fullWidth
					title={<h3>Opérateurs</h3>}
					description={
						<>
							Laissez vide pour sélectionner tous les opérateurs
							de l'espace.
						</>
					}
				>
					<EuiFormRow fullWidth>
						<EuiComboBox
							placeholder="Sélectionnez un ou plusieurs opérateur(s)"
							options={possible_operators}
							// @ts-ignore
							onChange={(values) => {
								setOperatorsFilter(values);
								fetch_stats(
									build_body({
										selected_operators: values.map(
											// @ts-ignore
											(v) => v._id
										),
									})
								);
							}}
							selectedOptions={operators_filter}
							isClearable={true}
							fullWidth
						/>
					</EuiFormRow>
				</EuiDescribedFormGroup>

				<EuiDescribedFormGroup
					fullWidth
					title={<h3>Évaluations</h3>}
					description={
						<>
							Laissez vide pour sélectionner toutes les
							évaluations de l'espace.
						</>
					}
				>
					<EuiFormRow fullWidth>
						<EuiComboBox
							placeholder="Sélectionnez une ou plusieurs évaluation(s)"
							options={possible_formations}
							// @ts-ignore
							onChange={(values) => {
								setFormationsFilter(values);
								fetch_stats(
									build_body({
										selected_formations: values.map(
											// @ts-ignore
											(v) => v._id
										),
									})
								);
							}}
							selectedOptions={formations_filter}
							isClearable={true}
							fullWidth
						/>
					</EuiFormRow>
				</EuiDescribedFormGroup>

				<EuiDescribedFormGroup
					fullWidth
					title={<h3>Date de changement de statut</h3>}
					description={<>Date à laquelle le statut a été modifié.</>}
				>
					<EuiFormRow fullWidth>
						<EuiDatePickerRange
							fullWidth
							startDateControl={
								<EuiDatePicker
									selected={start_date_filter}
									// @ts-ignore
									onChange={(date) => {
										setStartDateFilter(date);
										fetch_stats(
											build_body({
												start_date:
													date?.format("YYYY-MM-DD"),
											})
										);
									}}
									isInvalid={
										start_date_filter &&
										end_date_filter &&
										start_date_filter.isAfter(
											end_date_filter
										)
									}
									dateFormat="DD/MM/YYYY"
								/>
							}
							endDateControl={
								<EuiDatePicker
									selected={end_date_filter}
									onChange={(date) => {
										setEndDateFilter(date);
										fetch_stats(
											build_body({
												end_date:
													date?.format("YYYY-MM-DD"),
											})
										);
									}}
									isInvalid={
										start_date_filter &&
										end_date_filter &&
										start_date_filter.isAfter(
											end_date_filter
										)
									}
									dateFormat="DD/MM/YYYY"
								/>
							}
						/>
					</EuiFormRow>
				</EuiDescribedFormGroup>
			</EuiPanel>

			<EuiSpacer size="xxl" />

			<div>
				{/* add pagination */}
				<EuiBasicTable
					noItemsMessage="Aucun résultat trouvé"
					items={stats}
					columns={columns}
					pagination={{
						pageIndex: page,
						pageSize: 25,
						totalItemCount: count,
						showPerPageOptions: false,
					}}
					onChange={({ page }) => {
						setPage(page.index);
						fetch_stats(
							build_body({
								page: page.index,
							})
						);
					}}
				/>
			</div>
		</>
	);
}

function Default({ path }: { path: string }) {
	// return <Redirect to="habilitations" />
	return <p>changes</p>;
}
