// import { Button, FormGroup, InputGroup } from "@blueprintjs/core"
import { EuiButton, EuiCallOut, EuiFieldPassword, EuiFieldText, EuiForm, EuiFormRow, EuiHeader, EuiHeaderLink, EuiHeaderSectionItem, EuiLink, EuiPageTemplate, EuiSpacer, EuiText } from '@elastic/eui';
import { navigate } from '@reach/router';
import { FormEvent, useContext, useState } from "react";
import { UserContext } from "../context/UserContext";
import { API_PATH, useApi } from '../effects/useApi';

export const Login = () => {
	const [isSubmitting, setIsSubmitting] = useState(false)
	const [error, setError] = useState("")
	const [email, setEmail] = useState("")
	const [password, setPassword] = useState("")
	const [userContext, setUserContext] = useContext(UserContext)

	const [should_reset_password, setShouldResetPassword] = useState(false)

	const [email_to_reset, setEmailToReset] = useState("")

	const [error_reset, setErrorReset] = useState("")

	const [reset_success, setResetSuccess] = useState(false)

	const [reset_password, is_resetting] = useApi('POST', 'users/reset_password', async (err, res) => {

		if (err) {
			console.error(err)
			setErrorReset(err.message)
			return
		}
		setErrorReset("")
		setEmailToReset("")
		setResetSuccess(true)
	})

	const formSubmitHandler = (e: FormEvent) => {
		e.preventDefault()
		setIsSubmitting(true)
		setError("")

		fetch(API_PATH + "users/login", {
			method: "POST",
			credentials: "include",
			headers: { "Content-Type": "application/json" },
			body: JSON.stringify({ username: email, password }),
		})
			.then(async response => {
				setIsSubmitting(false)
				if (!response.ok) {

					console.log(response)


					console.log(error)

					if (response.status === 403) {
						setError(`Votre compte est archivé, si vous pensez qu’il s’agit d’une erreur vous pouvez contacter votre administrateur.`)
					} else if (response.status === 400) {
						// const error = await response.json()
						setError("Votre compté a été désactivé, si vous pensez qu’il s’agit d’une erreur vous pouvez contacter votre administrateur.")
					} else if (response.status === 401) {
						// @ts-ignore
						setError("La combinaison identifiant / mot de passe est incorrecte.")
					}
				} else {
					const data = await response.json()

					setUserContext(oldValues => {
						console.log("REFRESH AUTH DATA 4", oldValues, data)
						localStorage.setItem("auth_data", JSON.stringify({ ...oldValues, token: data.token, refresh_token: data.refresh_token, expires_on: new Date().getTime() + (5 * 60 * 1000), loggedAs: undefined }))
						return { ...oldValues, token: data.token, refresh_token: data.refresh_token }
					})
				}
			})
			.catch(error => {
				setIsSubmitting(false)
				setError("La combinaison identifiant / mot de passe est incorrecte.")
			})
	}

	return <>

		<EuiHeader>
			<EuiHeaderSectionItem border="right">
				<EuiHeaderLink>
					<EuiHeaderLink size='m' onClick={() => navigate('/')}>
						<img src="/logo.png" alt="logo" className="h-8" width={70} style={{ marginTop: 4 }} />
					</EuiHeaderLink>
				</EuiHeaderLink>
			</EuiHeaderSectionItem>
		</EuiHeader>

		<EuiPageTemplate>



			<EuiPageTemplate.EmptyPrompt
				title={should_reset_password ? <span>Mot de passe oublié</span> : <span>Connexion</span>}
				style={{ minWidth: 450, marginTop: -150 }}
			>
				{!should_reset_password ? <EuiForm component="form" style={{}}>
					<EuiFormRow label="Identifiant" fullWidth>
						<EuiFieldText name="username" fullWidth value={email} onChange={e => setEmail(e.target.value)} />
					</EuiFormRow>

					<EuiFormRow label="Mot de passe" fullWidth>
						<EuiFieldPassword type="dual" name="password" fullWidth value={password} onChange={e => setPassword(e.target.value)} />
					</EuiFormRow>

					<EuiFormRow fullWidth>
						<EuiButton type="submit" fill fullWidth onClick={formSubmitHandler} isLoading={isSubmitting}>
							Se connecter
						</EuiButton>
					</EuiFormRow>

					<EuiSpacer size="m" />

					<EuiText textAlign='center'>
						<EuiLink onClick={() => { setShouldResetPassword(true) }} >
							Mot de passe oublié ?
						</EuiLink>
					</EuiText>

					{error && <>
						<EuiSpacer size="m" />
						<EuiCallOut title="Une erreur s'est produite" color="danger" iconType="alert">
							<p>{error}</p>
						</EuiCallOut>
					</>}
				</EuiForm> : <>


					<EuiFormRow label="Identifiant" fullWidth>
						<EuiFieldText name="username" disabled={reset_success} fullWidth value={email_to_reset} onChange={e => setEmailToReset(e.target.value)} />
					</EuiFormRow>

					<EuiFormRow fullWidth>
						<EuiButton type="submit" disabled={reset_success} fill fullWidth onClick={() => { reset_password({ email: email_to_reset }) }} isLoading={is_resetting}>
							Réinitialiser mon mot de passe
						</EuiButton>
					</EuiFormRow>

					{reset_success && <>
						<EuiSpacer size="m" />
						<EuiCallOut title="Un email de réinitialisation a été envoyé" color="success" iconType="check">
							<p>Un email de réinitialisation vous a été envoyé par email. Veuillez suivre les instructions pour réinitialiser votre mot de passe.</p>
						</EuiCallOut>
					</>}

					{error_reset && <>
						<EuiSpacer size="m" />
						<EuiCallOut title="Une erreur s'est produite" color="danger" iconType="alert">
							<p>{error_reset}</p>
						</EuiCallOut>
					</>}

				</>}
			</EuiPageTemplate.EmptyPrompt>
		</EuiPageTemplate>
	</>
}

export const ResetPassword = ({ path, reset_key }: { path: string, reset_key?: string }) => {

	const [password, setPassword] = useState<string>('')
	const [password_confirm, setPasswordConfirm] = useState<string>('')

	const [password_changed, setPasswordChanged] = useState<boolean>(false)

	const [error, setError] = useState<string | null>(null)

	const [update_password, updating_password] = useApi('POST', 'users/password_by_reset_key', async (err, me) => {
		if (err) {
			console.error(err)
			setError(err.message)
			return
		}
		setPassword('')
		setPasswordConfirm('')

		setPasswordChanged(true)

		setTimeout(() => {
			navigate('/login')
		}, 2000)
	})

	return <>

		<EuiHeader>
			<EuiHeaderSectionItem border="right">
				<EuiHeaderLink>
					<EuiHeaderLink size='m' onClick={() => navigate('/')}>
						<img src="/logo.png" alt="logo" className="h-8" width={70} style={{ marginTop: 4 }} />
					</EuiHeaderLink>
				</EuiHeaderLink>
			</EuiHeaderSectionItem>
		</EuiHeader>

		<EuiPageTemplate>



			<EuiPageTemplate.EmptyPrompt
				title={<span>Réinitialisation de votre mot de passe</span>}
				style={{ minWidth: 450, marginTop: -150 }}
			>
				<>

					{error && <>
						<EuiCallOut title="Une erreur s'est produite" color="danger" iconType="alert">
							<p>{error}</p>
						</EuiCallOut>
						<EuiSpacer size="m" />
					</>}


					{password_changed && <><EuiCallOut title="Le mot de passe a été modifié" color="success" iconType="check">
						{/* Le mot de passe a été modifié */}
					</EuiCallOut><EuiSpacer /></>}

					<EuiFormRow fullWidth label="Nouveau mot de passe">
						<EuiFieldPassword
							fullWidth
							value={password}
							onChange={(e) => { setPassword(e.target.value) }}
						/>
					</EuiFormRow>

					<EuiFormRow fullWidth label="Confirmation du nouveau mot de passe">
						<EuiFieldPassword
							fullWidth
							value={password_confirm}
							onChange={(e) => { setPasswordConfirm(e.target.value) }}
							isInvalid={password !== password_confirm}
						/>

					</EuiFormRow>


					<EuiFormRow fullWidth>
						<EuiButton
							fullWidth
							onClick={() => { update_password({ password, reset_key }) }}
							isDisabled={(password !== password_confirm && password.length > 0)}
						>
							Modifier mon mot de passe
						</EuiButton>

					</EuiFormRow>
				</>
			</EuiPageTemplate.EmptyPrompt>
		</EuiPageTemplate>
	</>
}

// ns113.ovh.net
// dns113.ovh.net