import { EuiDescribedFormGroup, EuiCallOut, EuiButton, EuiFieldText, EuiFormRow, EuiMarkdownEditor, EuiPanel, EuiSpacer, EuiTitle, EuiCheckbox, EuiBadge, EuiText, EuiLink, EuiModal, EuiModalHeader, EuiModalHeaderTitle, EuiModalBody, EuiModalFooter, EuiAccordion, EuiBasicTable, EuiSelect } from '@elastic/eui';
import { useLocation } from '@reach/router';

import { Fragment, useEffect, useState } from 'react';
import RichText from '../../../../components/RichText';
import { useApi } from '../../../../effects/useApi';
import { Formation, SpecialCommunication, SpecialCommunicationTemplate, User } from '../../../../types';


export default function Communications({ formation_id, path, formation, reload_formation }: { formation_id: string, path: string, formation: Formation, reload_formation: () => void }) {

	const [content, setContent] = useState<unknown>("")

	const [message, setMessage] = useState<string>("")

	const [critical, setCritical] = useState<boolean>(false)

	const [communications_sent, setCommunicationsSent] = useState<SpecialCommunicationTemplate[]>([])

	const [communication_dunning, setCommunicationDunning] = useState<number>(0)

	const [fetch_communications_sent] = useApi('get', `communications/${formation_id}`, (err, communications_sent) => {

		if (err) {
			console.error(err)
			return;
		}

		setCommunicationsSent(communications_sent)
	})

	const [select_communication_dunning] = useApi('post', `formations/${formation_id}/communication_dunning`, (err, formation) => {

		if (err) {
			console.error(err)
			return;
		}

		setCommunicationDunning(formation.communication_dunning_delay)
	})



	useEffect(() => {
		fetch_communications_sent()
		setCommunicationDunning(formation.communication_dunning_delay)
	}, [formation_id])

	const state = useLocation().state || {}


	const [publish, publishing] = useApi('POST', `communications`, async (err, communication) => {
		if (err) {
			console.error(err)
			return
		}
		setHeader("")
		setContent("")

		setMessage("Message envoyé !")

		fetch_communications_sent()

		window.location.reload()
	})

	const [header, setHeader] = useState<string>((state as { title: string }).title || "")

	const [isModalVisible, setIsModalVisible] = useState<{ firstname: string, lastname: string, is_signed: boolean }[] | null>(null);

	const closeModal = () => setIsModalVisible(null);
	// const showModal = (communication_id: string) => setIsModalVisible(communication_id);

	let modal;

	if (isModalVisible) {
		modal = (
			<EuiModal onClose={closeModal}>
				<EuiModalHeader>
					<EuiModalHeaderTitle>
						<h1>Opérateurs</h1>
					</EuiModalHeaderTitle>
				</EuiModalHeader>

				<EuiModalBody>
					<EuiBasicTable
						noItemsMessage="Aucun résultat trouvé"
						// @ts-ignore
						items={isModalVisible}
						columns={[
							{
								field: 'firstname',
								name: 'Prénom',
								render: (firstname: string) => <span>{firstname}</span>,
							},
							{
								field: 'lastname',
								name: 'Nom',
								render: (lastname: string) => <span>{lastname}</span>,
							},
							{
								field: 'is_signed',
								name: 'Signé',
								render: (is_signed: boolean) => <span>{is_signed ? "✅" : "❌"}</span>,
							},
						]}
					/>

				</EuiModalBody>

				<EuiModalFooter>
					<EuiButton onClick={closeModal} fill>
						Fermer
					</EuiButton>
				</EuiModalFooter>
			</EuiModal>
		);
	}


	let options = [{
		value: "0",
		text: "Pas de relance"
	}, {
		value: '1',
		text: 'Tous les jours',
	}, {
		value: '2',
		text: 'Tous les 2 jours',
	}, {
		value: '3',
		text: 'Tous les 3 jours',
	}, {
		value: '7',
		text: 'Toutes les semaines',
	}]

	if (window.location.hostname.indexOf("localhost") > -1 || window.location.hostname.indexOf("netlify") > -1) {
		options = [{
			value: "99",
			text: "Toutes les 10 minutes (test)"
		}, ...options]
	}


	return <div>

		{message && <>
			<EuiCallOut size="m" title={"La communication spéciale a bien été envoyée aux opérateurs de l'évaluation !"} iconType="doubleArrowRight" />
			<EuiSpacer size="xxl" />
		</>}

		{!message && (state as { is_new_version: boolean }).is_new_version && <>
			<EuiCallOut size="m" title={"La nouvelle version a été publiée. Vous pouvez avertir les opérateurs avec une communication spéciale."} iconType="doubleArrowRight" />
			<EuiSpacer size="xxl" />
		</>}



		<EuiPanel hasBorder>

			<EuiTitle size="l">
				<h1>Créer une communication spéciale</h1>
			</EuiTitle>

			<EuiSpacer size="l" />

			<EuiDescribedFormGroup
				fullWidth
				title={<h3>Titre</h3>}
				description={
					<Fragment>
						Le titre de la communication que recevront les opérateurs de l'évaluation
					</Fragment>
				}
			>
				<EuiFormRow fullWidth
				// label="Text field"
				>
					<EuiFieldText fullWidth required value={header} onChange={(e) => setHeader(e.target.value)} />
				</EuiFormRow>
			</EuiDescribedFormGroup>

			<EuiDescribedFormGroup
				fullWidth
				title={<h3>Contenu</h3>}
			>
				<EuiFormRow fullWidth>
					<RichText
						value={content as any[]}
						onChange={(value) => { setContent(value) }}
						page_id={"course content"}
					/>
				</EuiFormRow>
			</EuiDescribedFormGroup>


			<EuiDescribedFormGroup
				fullWidth
				title={<h3>Statut</h3>}
				description={<>
					En cochant cette case, les évaluations des opérateurs seront suspendues. Cette suspension est levée à la lecture de cette communication.
				</>}
			>
				<EuiFormRow fullWidth>

					<EuiCheckbox
						id={"critical"}
						label="Communication spéciale critique"
						checked={critical}
						onChange={e => setCritical(e.target.checked)}
					/>

				</EuiFormRow>
			</EuiDescribedFormGroup>



			<EuiDescribedFormGroup
				fullWidth
				title={<h3>Envoyer</h3>}
				description={
					<Fragment>
						Envoyez la communication à tous les opérateurs de l'évaluation pour qu’ils en accusent lecture. Retrouvez la liste des communications en attente de lecture dans le module statistiques.
					</Fragment>
				}
			>
				<EuiFormRow fullWidth
				// label="Text field"
				>
					<EuiButton fullWidth isLoading={publishing} onClick={() => { publish({ header, content, formation_id, is_critical: critical }) }} iconType="plus">Publier la communication</EuiButton>
				</EuiFormRow>
			</EuiDescribedFormGroup>

		</EuiPanel>

		<EuiSpacer size="m" />

		<EuiPanel hasBorder>

			<EuiTitle size="m">
				<h2>Relance de signature</h2>
			</EuiTitle>

			<EuiSpacer size="l" />

			<EuiDescribedFormGroup
				fullWidth
				title={<h3>Délai de relance</h3>}
				description={
					<Fragment>
						Délai après lequel les opérateurs de l'évaluation recevront une relance de signature de la communication spéciale.
					</Fragment>
				}
			>
				<EuiFormRow fullWidth
				// label="Text field"
				>
					<EuiSelect
						isLoading={false}
						fullWidth
						options={options}
						value={communication_dunning.toString() || '0'}
						onChange={(e) => select_communication_dunning({ dunning: parseInt(e.target.value) })}
					/>
				</EuiFormRow>
			</EuiDescribedFormGroup>

		</EuiPanel>

		<EuiSpacer size="m" />

		{communications_sent.length > 0 && <EuiPanel hasBorder>

			<EuiTitle size="l">
				<h1>Communications spéciales envoyées</h1>
			</EuiTitle>

			<EuiSpacer size="l" />

			{communications_sent.map((communication, i) => <><EuiPanel hasBorder key={i}>
				<EuiDescribedFormGroup
					fullWidth
					title={<h3>{communication.header}</h3>}
					description={<>
						<EuiText>
							Le {new Date(communication.createdAt).toLocaleDateString('fr-FR', { timeZone: 'Europe/Paris' })} à {new Date(communication.createdAt).toLocaleTimeString('fr-FR', { timeZone: 'Europe/Paris' })}
						</EuiText>

						<EuiText>
							Envoyée à <EuiLink onClick={() => {
								setIsModalVisible((communication.communications as unknown as SpecialCommunication[]).map(co => ({
									firstname: (co.operator as unknown as User).firstname,
									lastname: (co.operator as unknown as User).lastname,
									is_signed: co.is_signed,
								})))
							}}>
								{communication.communications.length} opérateur{communication.communications.length > 1 ? "s" : ""} ({communication.communications.filter(c => (c as unknown as SpecialCommunication).is_signed).length} ✅ / {communication.communications.filter(c => !(c as unknown as SpecialCommunication).is_signed).length} ❌)
							</EuiLink>
						</EuiText>

						{modal}
					</>}
				>
					<RichText read_only onChange={() => { }} value={communication.description} />
				</EuiDescribedFormGroup>
				<EuiSpacer size="m" />
			</EuiPanel><EuiSpacer size="m" /></>)}

		</EuiPanel>}
	</div>
}