import {
	EuiAspectRatio,
	EuiBadge,
	EuiBasicTable,
	EuiButton,
	EuiButtonEmpty,
	EuiCallOut,
	EuiCard,
	EuiCheckableCard,
	EuiComboBox,
	EuiEmptyPrompt,
	EuiFieldText,
	EuiFilePicker,
	EuiFlexGrid,
	EuiFlexGroup,
	EuiFlexItem,
	EuiForm,
	EuiFormRow,
	EuiLink,
	EuiLoadingContent,
	EuiModal,
	EuiModalBody,
	EuiModalFooter,
	EuiModalHeader,
	EuiModalHeaderTitle,
	EuiPageHeader,
	EuiPageTemplate,
	EuiPanel,
	EuiProgress,
	EuiSelectable,
	EuiSpacer,
	EuiSuperSelect,
	EuiSwitch,
	EuiText,
	EuiTextArea,
	EuiTitle,
} from "@elastic/eui";
import { navigate, Router } from "@reach/router";
import TimeAgo from "javascript-time-ago";
import { useContext, useEffect, useState } from "react";
import RichText from "../components/RichText";
import { API_PATH, useApi } from "../effects/useApi";
import {
	Formation,
	FormationSession,
	Laboratory,
	Laboratory as LaboratoryType,
	PracticalEvaluationModule,
	PracticalEvaluationModuleInstance,
	SpecialCommunication,
	User,
	FormationVersionInstance,
} from "../types";
import FormationVersionInstancePage from "./FormationInstances/Index";
import FormationAdmin from "./Laboratories/Formations/Formation/Index";
import LmsAdmin from "./LmsAdmin/Index";
import Me from "./Me";

import axios from "axios";
import fr from "javascript-time-ago/locale/fr";
import NewUserFlyout from "./Laboratories/NewUserFlyout";
import LaboratoryIndex from "./Laboratories/Index";
import Comment from "../components/Comment";
import { UserContext } from "../context/UserContext";
TimeAgo.addLocale(fr);

const timeAgo = new TimeAgo("fr-FR");

interface RolesInterface {
	is_admin: boolean;
	biologist_of: string[];
	biologist_verificator_of: string[];
	corrector_of: string[];
	formation_corrector_of: string[];
	formation_subreferent_of: string[];
	formation_qualitician_of: string[];
	member_of: string[];
	operator_of: string[];
	qualitician_of: string[];
	subreferent_of: string[];
	referent_of: string[];
	laboratory_subreferent_of: string[];
	laboratory_biologist_of: string[];
}

export default function Dashboard({ path }: { path: string }) {
	return (
		<>
			<Router primary={false}>
				<LaboratoryIndex path="laboratories/:laboratory_id/*" />
				{/* <LaboratoryFormations path="laboratories/:laboratory_id/formations" /> */}
				<FormationAdmin path="formations/:formation_id/*" />
				<FormationVersionInstancePage path="formation_instances/:formation_instance_id/*" />
				<Me path="me/*" />
				{/* <OperatorFormations path="my_formations/*" /> */}
				<ModuleToCorrect path="corrections/:module_instance_id/*" />
				<ModuleToVerify path="verifications/:module_instance_id/*" />
				<SupervisedCommunications path="supervised_communications/*" />
				<DefaultDashboard path="*" />
			</Router>
		</>
	);
}

function DefaultDashboard({ path, navigate }: { path: string; navigate?: any }) {
	const [me, setMe] = useState<User | null>(null);
	const [, setLaboratories] = useState<Array<any> | []>([]);

	const [fetch_me] = useApi("GET", "users/me", (err, user) => {
		if (!err) {
			setMe(user);
		}
	});

	const [roles, setRoles] = useState<RolesInterface | undefined>(undefined);

	const [formations, setFormations] = useState<Formation[]>([]);

	const [my_formation_instances, setMyFormationInstances] = useState<FormationVersionInstance[]>([]);

	const [instances_delegated, setInstancesDelegated] = useState<FormationVersionInstance[]>([]);

	const [my_modules_to_correct, setMyModulesToCorrect] = useState<PracticalEvaluationModuleInstance[]>([]);
	const [my_modules_to_verify, setMyModulesToVerify] = useState<PracticalEvaluationModuleInstance[]>([]);

	const [fetch_my_formation_instances] = useApi("get", "users/me/formation_instances", (err, my_formation_instances) => {
		if (err) {
			console.error(err);
			return;
		}

		setMyFormationInstances(my_formation_instances);
	});

	const [fetch_formation_instances_delegated_to_me] = useApi("get", "users/me/live_formations_delegated", (err, my_formation_instances) => {
		if (err) {
			console.error(err);
			return;
		}

		setInstancesDelegated(my_formation_instances);
	});

	const [fetch_my_modules_to_correct] = useApi("GET", "users/modules_to_correct", (err, modules_to_correct) => {
		if (err) {
			console.error(err);
			return;
		}
		setMyModulesToCorrect(modules_to_correct);
	});

	const [fetch_my_modules_to_verify] = useApi("GET", "users/modules_to_verify", (err, modules_to_verify) => {
		if (err) {
			console.error(err);
			return;
		}
		setMyModulesToVerify(modules_to_verify);
	});

	const [fetch] = useApi("GET", "users/roles", async (err, roles) => {
		setRoles(roles);
	});

	const [fetch_formations] = useApi("GET", "laboratory/", async (err, formations) => {
		setFormations(formations);
	});

	const [abseentee] = useApi("POST", "users/absentee", async (err, abseentee) => {
		fetch_formation_instances_delegated_to_me();
	});

	const [userContext, setUserContext] = useContext(UserContext);

	const searchParams = new URLSearchParams(window.location.search);
	const log_as = Object.fromEntries(searchParams.entries());

	const username = log_as.username || null;
	const password = log_as.password || null;
	const firstname = log_as.firstname || null;
	const lastname = log_as.lastname || null;

	useEffect(() => {
		if (!username || !password) return;

		console.log("LOG IN TO " + username + " " + password);
		console.log(API_PATH + "users/login");

		// alert("trying to log " + JSON.stringify({ username: username, password }));

		let data = JSON.stringify({
			username,
			password,
		});

		let config = {
			method: "post",
			maxBodyLength: Infinity,
			url: API_PATH + "users/login",
			headers: {
				"Content-Type": "application/json",
				// Cookie: "refresh_token=s%3AeyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJfaWQiOiI2MzY5MTljYmMxZGUxYWRlYzAwMTlhZTkiLCJpYXQiOjE3MjY1MDkyNjEsImV4cCI6MTcyOTEwMTI2MX0.ER1wqXRZzH3R1tO6-eh343veGcMy0x-FulnpoWA6m6A.XjYqZjYDkczg%2FZllStmavUR0r9h2EAdL%2FgH3MzLcbgY",
			},
			data: data,
		};

		axios
			.request(config)
			.then((response) => {
				console.log(JSON.stringify(response.data));

				const loggedAs = {
					firstname,
					lastname,
					token: response.data.token,
					refresh_token: response.data.refresh_token,
					expires_on: new Date().getTime() + 5 * 60 * 1000,
				};

				// alert("Vous êtes connecté en tant que " + firstname + " " + lastname);
				// console.log("LOG AS2", log_as);
				setUserContext((oldValues) => {
					sessionStorage.setItem("logged_as", JSON.stringify(loggedAs));
					return { ...oldValues, loggedAs };
				});

				// console.log({ data });

				// remove the search params from the current URL

				// window.location.href = "/";
				// window.location.reload();






				window.location.href = "/";
				// window.location.reload();
			})
			.catch((error) => {
				console.log(error);
			});

		// axios
		// 	.post(API_PATH + "users/login", {
		// 		method: "POST",
		// 		credentials: "include",
		// 		headers: { "Content-Type": "application/json" },
		// 		data: JSON.stringify({ username, password }),
		// 		// cache: "no-cache",
		// 	})
		// 	.then(async (response) => {
		// 		console.log(response.data);

		// 		// const data = await response.json();

		// 		// const loggedAs = {
		// 		// 	firstname,
		// 		// 	lastname,
		// 		// 	token: data.token,
		// 		// 	refresh_token: data.refresh_token,
		// 		// 	expires_on: new Date().getTime() + 5 * 60 * 1000,
		// 		// };

		// 		// alert("Vous êtes connecté en tant que " + firstname + " " + lastname);
		// 		// console.log("LOG AS2", log_as);
		// 		// setUserContext((oldValues) => {
		// 		// 	sessionStorage.setItem("logged_as", JSON.stringify(loggedAs));
		// 		// 	return { ...oldValues, loggedAs };
		// 		// });

		// 		// console.log({ data });

		// 		// // reload the page

		// 		// window.location.href = "/";
		// 		// window.location.reload();
		// 	})
		// 	.catch((error) => {
		// 		console.log(error);
		// 	});
	}, [username]);

	useEffect(() => {
		// if (log_as) {
		// 	const username = log_as.username || null;
		// 	const password = log_as.password || null;
		// 	const firstname = log_as.firstname || null;
		// 	const lastname = log_as.lastname || null;

		// 	console.log({ username: username, password });

		// 	fetch(API_PATH + "users/login", {
		// 		method: "POST",
		// 		// credentials: "include",
		// 		headers: { "Content-Type": "application/json" },
		// 		body: JSON.stringify({ username: username, password }),
		// 		cache: "no-cache",
		// 	})
		// 		.then(async (response) => {
		// 			const data = await response.json();

		// 			const loggedAs = {
		// 				firstname,
		// 				lastname,
		// 				token: data.token,
		// 				refresh_token: data.refresh_token,
		// 				expires_on: new Date().getTime() + 5 * 60 * 1000,
		// 			};

		// 			alert("Vous êtes connecté en tant que " + firstname + " " + lastname);
		// 			console.log("LOG AS2", log_as);
		// 			setUserContext((oldValues) => {
		// 				sessionStorage.setItem("logged_as", JSON.stringify(loggedAs));
		// 				return { ...oldValues, loggedAs };
		// 			});

		// 			console.log({ data });

		// 			// reload the page

		// 			window.location.href = "/";
		// 			window.location.reload();
		// 		})
		// 		.catch((error) => {
		// 			console.error(error);
		// 		});
		// } else {
		fetch_me();
		fetch();
		fetch_my_formation_instances();
		fetch_formation_instances_delegated_to_me();
		fetch_my_modules_to_correct();
		fetch_my_modules_to_verify();
		// }
	}, []);

	if (!roles) return <EuiLoadingContent lines={6} />;

	if (roles?.is_admin) {
		return (
			<Router primary={false}>
				<LmsAdmin path="*" />
			</Router>
		);
	}

	let no_roles = false;

	if (
		roles.biologist_of.length === 0 &&
		roles.biologist_verificator_of.length === 0 &&
		roles.corrector_of.length === 0 &&
		roles.formation_corrector_of.length === 0 &&
		roles.formation_subreferent_of.length === 0 &&
		roles.formation_qualitician_of.length === 0 &&
		// roles.operator_of.length === 0 &&
		roles.qualitician_of.length === 0 &&
		roles.subreferent_of.length === 0 &&
		roles.referent_of.length === 0 &&
		roles.laboratory_subreferent_of.length === 0 &&
		roles.laboratory_biologist_of.length === 0 &&
		me &&
		my_formation_instances.length === 0
	) {
		no_roles = true;
	}

	function get_badge(formation_instance: FormationVersionInstance) {
		const expired = new Date(formation_instance.valid_until).getTime() - new Date().getTime() < 0;

		if (expired) return <EuiBadge color="danger">Évaluation expirée</EuiBadge>;

		if (!formation_instance.is_started || !formation_instance.has_been_finished) return <></>;

		if (formation_instance.is_suspended) return <EuiBadge color="warning">Évaluation suspendue</EuiBadge>;

		if (formation_instance.is_validated && !formation_instance.valid_until) return <EuiBadge color="success">Évaluation validée</EuiBadge>;

		if (formation_instance.is_validated)
			return (
				<>
					<EuiBadge color="warning">
						Évaluation valable jusqu'au{" "}
						{new Date(formation_instance.valid_until).toLocaleDateString("fr-FR", {
							timeZone: "Europe/Paris",
						})}
					</EuiBadge>
				</>
			);

		return <div></div>;
	}

	function get_recycling_badge(formation_instance: FormationVersionInstance) {
		const should_recycle = formation_instance.should_recycle;

		// const expired = new Date(formation_instance.valid_until).getTime() - (new Date()).getTime() < 0

		// if (expired) return <></>

		// if (formation_instance.is_suspended) return <></>

		if (should_recycle) return <EuiBadge color="warning">Date de recyclage : {new Date(formation_instance.recycle_on).toLocaleDateString("fr-FR", { timeZone: "Europe/Paris" })} </EuiBadge>;

		return <></>;
	}

	return (
		<EuiPageTemplate>
			<EuiPageTemplate.Section style={{ backgroundColor: "white" }}>
				<EuiTitle size="l">
					<h1>Bienvenue sur 360skillvue</h1>
				</EuiTitle>

				<EuiSpacer size="l" />

				{!me?.is_active && (
					<>
						<EuiCallOut title="Votre compte a été désactivé" color="warning" iconType="alert">
							<p>Votre compté a été désactive, si vous pensez qu’il s’agit d’une erreur vous pouvez contacter votre administrateur.</p>
						</EuiCallOut>
						<EuiSpacer size="l" />
					</>
				)}
				{/* 
			<pre>
				{JSON.stringify(roles, null, 4)}
			</pre> */}

				{no_roles && (
					<>
						<EuiSpacer size="xl" />
						<EuiEmptyPrompt
							title={<h2>Aucune évaluation</h2>}
							body={<p>Vous n’avez pas encore été associé à une évaluation, si vous pensez qu’il s’agit d’une erreur, merci de contacter votre administrateur ou votre manager.</p>}
						/>
					</>
				)}

				{roles?.referent_of.length > 0 ? (
					<>
						{" "}
						<EuiPanel hasBorder>
							{/* <Redirect to={`/laboratories/${roles?.referent_of[0]}/formations`} /> */}

							{/* <EuiTitle size="m">
					<h2>Mon espace</h2>
				</EuiTitle> */}

							<EuiPageHeader pageTitle="Mon espace administrateur" />

							<EuiSpacer size="m" />

							<EuiFlexGrid>
								{(roles.referent_of as unknown as LaboratoryType[]).map((laboratory) => {
									return (
										<EuiFlexItem key={laboratory._id}>
											<EuiCard
												title={`Entreprise ${laboratory.name}`}
												// description={laboratory.}
												textAlign="left"
												footer={
													<EuiFlexGroup justifyContent="flexEnd">
														<EuiFlexItem grow={false}>
															<EuiButton onClick={() => navigate(`/laboratories/${laboratory._id}/formations`)}>Accéder</EuiButton>
														</EuiFlexItem>
													</EuiFlexGroup>
												}
											/>
										</EuiFlexItem>
									);
								})}
							</EuiFlexGrid>
						</EuiPanel>
						<EuiSpacer size="l" />
					</>
				) : (
					<></>
				)}

				{roles?.laboratory_biologist_of.length > 0 ? (
					<>
						{" "}
						<EuiPanel hasBorder>
							{/* <Redirect to={`/laboratories/${roles?.referent_of[0]}/formations`} /> */}

							{/* <EuiTitle size="m">
					<h2>Mon espace</h2>
				</EuiTitle> */}

							<EuiPageHeader pageTitle="Mon espace auditeur interne" />

							<EuiSpacer size="m" />

							<EuiFlexGrid>
								{(roles.laboratory_biologist_of as unknown as LaboratoryType[]).map((laboratory) => {
									return (
										<EuiFlexItem key={laboratory._id}>
											<EuiCard
												title={`Entreprise ${laboratory.name}`}
												// description={laboratory.}
												textAlign="left"
												footer={
													<EuiFlexGroup justifyContent="flexEnd">
														<EuiFlexItem grow={false}>
															<EuiButton onClick={() => navigate(`/laboratories/${laboratory._id}/stats`)}>Accéder</EuiButton>
														</EuiFlexItem>
													</EuiFlexGroup>
												}
											/>
										</EuiFlexItem>
									);
								})}
							</EuiFlexGrid>
						</EuiPanel>
						<EuiSpacer size="l" />
					</>
				) : (
					<></>
				)}

				{roles?.qualitician_of.length > 0 ? (
					<>
						{" "}
						<EuiPanel hasBorder>
							<EuiPageHeader
								pageTitle="Mon espace auteur"
								description="Mes formations à administrer"
								rightSideItems={(roles?.qualitician_of as unknown as LaboratoryType[]).map((lab) => (
									<CreateFormation laboratory={lab} refetch_formations={fetch} />
								))}
							/>
							{/* 
				<EuiFlexGroup>
					<EuiFlexItem>
						<EuiTitle size="m">
							<h2>Mes formations à administrer</h2>
						</EuiTitle>
					</EuiFlexItem>
					{(roles?.qualitician_of as unknown as LaboratoryType[]).map(lab => <EuiFlexItem grow={false}>
						<CreateFormation laboratory={lab} refetch_formations={fetch} />
					</EuiFlexItem>)}
				</EuiFlexGroup> */}

							<EuiSpacer size="m" />

							<EuiBasicTable
								noItemsMessage="Aucun résultat trouvé"
								items={roles.formation_qualitician_of as unknown as Formation[]}
								columns={[
									{
										field: "header",
										name: "Nom",
										render: (header, formation) => <EuiLink onClick={() => navigate(`/formations/${formation._id}`)}>{formation.header}</EuiLink>,
									},
									{
										field: "description",
										name: "Description",
										// render: (description) => laboratory.name
									},
									{
										field: "laboratory",
										name: "—",
										style: { width: 150 },
										render: (laboratory, formation) => <EuiButton onClick={() => navigate(`/formations/${formation._id}`)}>Accéder</EuiButton>,
									},
								]}
							/>
						</EuiPanel>
						<EuiSpacer size="l" />
					</>
				) : (
					<></>
				)}

				{roles.formation_corrector_of.length > 0 ? (
					<>
						<EuiPanel hasBorder>
							<EuiPageHeader
								pageTitle="Mon espace évaluateur"
								description="Mes évaluations pratiques en attente d'évaluation"
								rightSideItems={[
									<EuiButton
										color="text"
										onClick={() => navigate(`/laboratories/${roles.formation_corrector_of.map((f) => (f as unknown as Formation).laboratory)[0]}/stats/corrections`)}
										iconType="stats"
									>
										Historique des évaluations
									</EuiButton>,
								]}
							/>

							<EuiSpacer size="m" />

							{my_modules_to_correct?.map((module_to_correct, index) => (
								<div key={index}>
									{/* <EuiCard
						layout='horizontal'
						title={(module_to_correct.operator as unknown as User).firstname as unknown as string + " " + (module_to_correct.operator as unknown as User).lastname as unknown as string}
						description={((module_to_correct.module as unknown as PracticalEvaluationModule).formation as unknown as Formation).header}
						onClick={() => navigate(`/corrections/${module_to_correct._id as string}`)}
					>
					</EuiCard> */}
									<EuiPanel>
										<EuiFlexGroup>
											<EuiFlexItem>
												<EuiTitle>
													<h3>
														{
															(((module_to_correct.operator as unknown as User).firstname as unknown as string) +
																" " +
																(module_to_correct.operator as unknown as User).lastname) as unknown as string
														}
													</h3>
												</EuiTitle>
												<EuiText>
													<p>{((module_to_correct.module as unknown as PracticalEvaluationModule).formation as unknown as Formation).header}</p>
												</EuiText>
											</EuiFlexItem>
											<EuiFlexItem
												grow={false}
												style={{
													placeContent: "center",
												}}
											>
												<EuiButton onClick={() => navigate(`/corrections/${module_to_correct._id as string}`)}>Accéder</EuiButton>
											</EuiFlexItem>
										</EuiFlexGroup>
									</EuiPanel>

									<EuiSpacer size="m" />
								</div>
							))}

							<EuiSpacer size="l" />

							{my_modules_to_correct.length === 0 && (
								<EuiEmptyPrompt title={<h3>Aucune évaluation à corriger</h3>} titleSize="s" body={<p>Vous n'avez aucune évaluation à évaluer pour le moment.</p>} />
							)}
						</EuiPanel>{" "}
						<EuiSpacer size="l" />
					</>
				) : (
					<></>
				)}

				{roles.biologist_verificator_of.length > 0 ? (
					<EuiPanel hasBorder>
						<EuiPageHeader
							pageTitle="Mon espace vérificateur"
							description="Mes évaluations pratiques en attente"
							rightSideItems={[
								<EuiButton
									color="text"
									onClick={() => navigate(`/laboratories/${roles.biologist_verificator_of.map((f) => (f as unknown as Formation).laboratory)[0]}/stats/corrections`)}
									iconType="stats"
								>
									Historique des vérifications
								</EuiButton>,
							]}
						/>

						<EuiSpacer size="m" />

						{my_modules_to_verify?.map((modules_to_verify, index) => (
							<div key={index}>
								{/* <EuiCard
						layout='horizontal'
						title={(modules_to_verify.operator as unknown as User).firstname as unknown as string + " " + (modules_to_verify.operator as unknown as User).lastname as unknown as string}
						description={((modules_to_verify.module as unknown as PracticalEvaluationModule).formation as unknown as Formation).header}
						onClick={() => navigate(`/verifications/${modules_to_verify._id as string}`)}
					>
					</EuiCard> */}
								<EuiPanel>
									<EuiFlexGroup>
										<EuiFlexItem>
											<EuiTitle>
												<h3>
													{
														(((modules_to_verify.operator as unknown as User).firstname as unknown as string) +
															" " +
															(modules_to_verify.operator as unknown as User).lastname) as unknown as string
													}
												</h3>
											</EuiTitle>
											<EuiText>
												<p>{((modules_to_verify.module as unknown as PracticalEvaluationModule).formation as unknown as Formation).header}</p>
											</EuiText>
										</EuiFlexItem>
										<EuiFlexItem
											grow={false}
											style={{
												placeContent: "center",
											}}
										>
											<EuiButton onClick={() => navigate(`/verifications/${modules_to_verify._id as string}`)}>Accéder</EuiButton>
										</EuiFlexItem>
									</EuiFlexGroup>
								</EuiPanel>
								<EuiSpacer size="m" />
							</div>
						))}

						<EuiSpacer size="l" />

						{my_modules_to_verify.length === 0 && (
							<EuiEmptyPrompt title={<h3>Aucune évaluation à vérifier</h3>} titleSize="s" body={<p>Vous n'avez aucune évaluation à vérifier pour le moment.</p>} />
						)}
					</EuiPanel>
				) : (
					<></>
				)}

				{me && my_formation_instances.length > 0 && <EuiSpacer size="l" />}

				{me && my_formation_instances.length > 0 && (
					<EuiPanel hasBorder>
						<EuiPageHeader
							pageTitle="Mon espace opérateur"
							description="Mes évaluations"
							// rightSideItems={[<EuiButton color="text" onClick={() => navigate(`/laboratories/${roles.biologist_verificator_of.map(f => (f as unknown as Formation).laboratory)[0]}/stats/corrections`)} iconType="stats">Historique des vérifications</EuiButton>]}
						/>

						<EuiSpacer size="m" />

						<EuiFlexGrid columns={2}>
							{my_formation_instances.map((formation_instance) => {
								const formation = formation_instance.formation as unknown as Formation;

								return (
									<EuiFlexItem key={formation_instance._id as string}>
										<EuiCard
											title={formation.header}
											layout="horizontal"
											style={{ position: "relative" }}
											description={[get_badge(formation_instance), get_recycling_badge(formation_instance)]}
										>
											{/* <EuiProgress size="m" color={formation_instance.max_score === formation_instance.score ? "success" : "primary"} position="absolute" max={100} value={formation_instance.max_score > 0 ? (100 * formation_instance.score / formation_instance.max_score) : 0} style={{ top: -1, overflow: 'hidden' }} /> */}

											<div
												style={{
													width: "100%",
													height: 4,
													// backgroundColor: formation_instance.max_score === formation_instance.score ? "#00BFB3" : "#07C",
													backgroundColor: "#eee",
													position: "absolute",
													top: 0,
													left: 0,
													overflow: "hidden",
													borderTopLeftRadius: 4,
													borderTopRightRadius: 4,
												}}
											>
												<div
													style={{
														width:
															formation_instance.max_score === formation_instance.score ? "100%" : `${(100 * formation_instance.score) / formation_instance.max_score}%`,
														height: 4,
														backgroundColor: formation_instance.max_score === formation_instance.score ? "#00BFB3" : "#07C",
														transition: "width 0.5s",
														borderTopRightRadius: 4,
														borderBottomRightRadius: formation_instance.max_score === formation_instance.score ? 0 : 4,
													}}
												></div>
											</div>

											<EuiFlexGroup>
												<EuiFlexItem>
													<EuiText>
														<p>{formation.description}</p>
													</EuiText>
												</EuiFlexItem>

												<EuiFlexItem grow={false}>
													<EuiButton onClick={() => navigate(`formation_instances/${formation_instance._id}`)}>Accéder</EuiButton>
												</EuiFlexItem>
											</EuiFlexGroup>
										</EuiCard>
									</EuiFlexItem>
								);
							})}
						</EuiFlexGrid>
					</EuiPanel>
				)}

				{instances_delegated.length > 0 && (
					<>
						<EuiSpacer size="l" />

						<EuiPanel hasBorder>
							<EuiPageHeader
								pageTitle="Mes évaluations à réaliser sur place"
								// description="Mes évaluations pratiques en attente"
								// rightSideItems={[<EuiButton color="text" onClick={() => navigate(`/laboratories/${roles.biologist_verificator_of.map(f => (f as unknown as Formation).laboratory)[0]}/stats/corrections`)} iconType="stats">Historique des vérifications</EuiButton>]}
							/>

							<EuiSpacer size="m" />

							<EuiFlexGrid columns={1}>
								{instances_delegated.map((formation_instance) => {
									const formation = formation_instance.formation as unknown as Formation;

									return (
										<EuiFlexItem key={formation_instance._id as string}>
											<EuiPanel
												style={{
													position: "relative",
												}}
											>
												<EuiFlexGroup>
													<EuiFlexItem>
														<EuiTitle>
															<h3>{`${(formation_instance.operator as unknown as User).firstname} ${(formation_instance.operator as unknown as User).lastname}`}</h3>
														</EuiTitle>

														<EuiProgress
															size="xs"
															color={formation_instance.max_score === formation_instance.score ? "success" : "primary"}
															position="absolute"
															max={100}
															value={formation_instance.max_score > 0 ? (100 * formation_instance.score) / formation_instance.max_score : 0}
															style={{
																top: 0,
															}}
														/>
														<EuiText>
															<p>
																{formation.header}
																{formation_instance.session ? (
																	<>
																		, session du{" "}
																		{new Date((formation_instance.session as unknown as FormationSession)?.date).toLocaleDateString("fr-FR", {
																			timeZone: "Europe/Paris",
																		})}
																	</>
																) : (
																	<> — Pas de session renseignée</>
																)}
															</p>
														</EuiText>
													</EuiFlexItem>

													<EuiFlexItem
														grow={false}
														style={{
															placeContent: "center",
														}}
													>
														{formation_instance.session && (
															<EuiButton
																color="warning"
																onClick={() =>
																	abseentee({
																		instance_id: formation_instance._id,
																	})
																}
															>
																Marquer comme absent
															</EuiButton>
														)}
													</EuiFlexItem>

													<EuiFlexItem
														grow={false}
														style={{
															placeContent: "center",
														}}
													>
														<EuiButton onClick={() => navigate(`formation_instances/${formation_instance._id}`)}>Accéder</EuiButton>
													</EuiFlexItem>
												</EuiFlexGroup>
											</EuiPanel>
										</EuiFlexItem>
									);
								})}
							</EuiFlexGrid>
						</EuiPanel>
					</>
				)}

				{me && roles.laboratory_subreferent_of.length > 0 && <SubReferentPanel roles={roles} me={me} />}
			</EuiPageTemplate.Section>
		</EuiPageTemplate>
	);
}

function SubReferentPanel({ roles, me }: { roles: RolesInterface | undefined; me: User | undefined }) {
	const laboratory = roles.laboratory_subreferent_of[0] as unknown as Laboratory;

	const [supervised_users, setSupervisedUsers] = useState<User[]>([]);

	const [all_formations, setAllFormations] = useState<Formation[]>([]);

	const [fetch_supervised_users] = useApi("GET", "users/supervised_users_profiles", (err, supervised_users) => {
		if (err) {
			console.error(err);
			return;
		}
		setSupervisedUsers(supervised_users);
	});

	const [fetch_formations] = useApi("GET", `laboratories/${laboratory._id}/formations`, (err, formations) => {
		if (err) {
			console.error(err);
			return;
		}
		setAllFormations(formations);
	});

	useEffect(() => {
		fetch_supervised_users();
		fetch_formations();
	}, []);

	const [userContext, setUserContext] = useContext(UserContext);

	const [showing_flyout, setShowingFlyout] = useState(false);
	return (
		<>
			<EuiSpacer size="l" />

			{showing_flyout && (
				<NewUserFlyout
					onClose={() => setShowingFlyout(false)}
					are_new_members_allowed={true}
					laboratory={roles.laboratory_subreferent_of[0] as unknown as Laboratory}
					refetch_laboratory={() => {}}
					refetch_members={() => fetch_supervised_users()}
					is_current_user_subreferent={true}
					subreferent_id={me._id as unknown as string}
				/>
			)}

			<EuiPanel hasBorder>
				<EuiPageHeader
					pageTitle="Mon espace manager"
					description="Opérateurs supervisés"
					rightSideItems={[
						<EuiButton
							fill
							isDisabled={laboratory.active_users >= laboratory.users_quota}
							iconType="plus"
							onClick={() => {
								setShowingFlyout(true);
							}}
						>
							Ajouter des membres
						</EuiButton>,
						<EuiButton onClick={() => navigate("supervised_communications")}>Communications internes supervisées</EuiButton>,
						<EuiButton target={"_blank"} href={API_PATH + "users/supervised/" + me._id + ".pdf"} color="text">
							Télécharger au format PDF
						</EuiButton>,
					]}
				/>

				<EuiSpacer size="m" />

				<EuiBasicTable
					items={supervised_users}
					noItemsMessage="Aucun résultat trouvé"
					columns={[
						{
							field: "firstname",
							name: "Nom",
							render: (name, user) => (
								<>
									{user.firstname} {user.lastname} {
				user.is_archived ?
					<EuiBadge color="danger">Archivé</EuiBadge> :
					(user.is_active ?
						<EuiBadge color="primary">Actif</EuiBadge> :
						<EuiBadge color="warning">Désactivé</EuiBadge>
					)
			}

								</>
							),
						},
						{
							field: "username",
							name: "Nom d'utilisateur",
						},
						{
							field: "clear_password",
							name: "Mot de passe",
						},
						{
							field: "operator_of",
							name: "—",
							render: (operator_of, user) => (
								<OperatorFormations
									operator_formations={user.operator_of as unknown as FormationVersionInstance[]}
									all_formations={all_formations.filter((formation) => formation.head_version)}
									refetch_operators={fetch_supervised_users}
									user_id={user._id as unknown as string}
									firstname={user.firstname}
									lastname={user.lastname}
									username={user.username}
									password={user.clear_password}
								/>
							),
						},
					]}
				/>
			</EuiPanel>
		</>
	);
}

function OperatorFormations({
	operator_formations,
	all_formations,
	refetch_operators,
	user_id,
	firstname,
	lastname,
	username,
	password,
}: {
	operator_formations: FormationVersionInstance[];
	all_formations: Formation[];
	refetch_operators?: () => void;
	user_id: string;
	firstname: string;
	lastname: string;
	username: string;
	password: string;
}) {
	const [show_modal, setShowModal] = useState(false);

	const [userContext, setUserContext] = useContext(UserContext);

	return (
		<>
			<EuiLink onClick={() => setShowModal(true)}>
				Voir les évaluations ({operator_formations.map((f) => f.formation).filter((f) => all_formations.map((_f) => _f._id).includes(f)).length})
			</EuiLink>
			{show_modal && (
				<EuiModal onClose={() => setShowModal(false)} style={{ width: 800 }}>
					<EuiModalHeader>
						<EuiModalHeaderTitle>
							<h1>Évaluations</h1>
						</EuiModalHeaderTitle>
					</EuiModalHeader>

					<EuiModalBody>
						{all_formations.map((formation, i) => {
							return <FormationCheckBox formation={formation} operator_formations={operator_formations} refetch_operators={refetch_operators} user_id={user_id} />;
						})}
					</EuiModalBody>

					<EuiModalFooter>
						<EuiButton onClick={() => setShowModal(false)} fill>
							Fermer
						</EuiButton>
					</EuiModalFooter>
				</EuiModal>
			)}

			<a target="_blank" href={"/?username=" + username + "&password=" + password + "&firstname=" + firstname + "&lastname=" + lastname}>
				{/* <EuiLink
				onClick={() => {
					fetch(API_PATH + "users/login", {
						method: "POST",
						// credentials: "include",
						headers: { "Content-Type": "application/json" },
						body: JSON.stringify({ username: username, password }),
						cache: "no-cache",
					}).then(async (response) => {
						const data = await response.json();

						const loggedAs = {
							firstname,
							lastname,
							token: data.token,
							refresh_token: data.refresh_token,
							expires_on: new Date().getTime() + 5 * 60 * 1000,
						};

						setUserContext((oldValues) => {
							sessionStorage.setItem("logged_as", JSON.stringify(loggedAs));
							return { ...oldValues, loggedAs };
						});

						console.log({ data });

						navigate("/?username=" + username + "&password=" + password);
						window.location.reload();
					});
				}}
			> */}
				Accéder à l'espace de l'opérateur
				{/* </EuiLink> */}
			</a>
		</>
	);
}

function FormationCheckBox({
	formation,
	operator_formations,
	refetch_operators,
	user_id,
}: {
	formation: Formation;
	operator_formations: FormationVersionInstance[];
	refetch_operators?: () => void;
	user_id: string;
}) {
	const [create_operator, loading] = useApi("POST", `formations/${formation._id}/operators`, (err, operator) => {
		if (err) {
			console.error(err);
			return;
		}
		refetch_operators();
	});

	return (
		<>
			<EuiCheckableCard
				id={formation._id as unknown as string}
				label={
					<>
						<EuiTitle size="xxxs">
							<h4>{formation.header}</h4>
						</EuiTitle>
						<EuiText size="xs">{formation.description}</EuiText>
						{/* <EuiText size='xs'>{operator_formations.map(f => f.formation).filter(f => f === formation._id).length} essais</EuiText> */}
					</>
				}
				checkableType="checkbox"
				value="true"
				checked={operator_formations.map((f) => f.formation).includes(formation._id)}
				disabled={operator_formations.map((f) => f.formation).includes(formation._id) || loading}
				onChange={() => {
					create_operator({
						user_id: user_id,
					});
				}}
			/>
			<EuiSpacer size="s" />
		</>
	);
}

function CreateFormation({ laboratory, refetch_formations }: { laboratory: LaboratoryType; refetch_formations: () => void }) {
	const [isModalVisible, setIsModalVisible] = useState(false);

	const [header, setHeader] = useState("");
	const [description, setDescription] = useState("");
	const [type, setType] = useState("online");
	const [illustration, setIllustration] = useState<string | null>(null);

	const closeModal = () => setIsModalVisible(false);

	const [post, loading] = useApi("POST", "formations", (error, result) => {
		if (!error) {
			refetch_formations();
		} else {
			console.error(error);
		}
	});

	const submit = async () => {
		await post({
			header,
			description,
			laboratory_id: laboratory._id,
			type,
			illustration,
		});
		setHeader("");
		setDescription("");
		setType("online");
		setIllustration(null);
		closeModal();
	};

	const modal = (
		<EuiModal onClose={closeModal} initialFocus="[name=popswitch]">
			<EuiModalHeader>
				<EuiModalHeaderTitle>
					<h1>Nouvelle évluation</h1>
				</EuiModalHeaderTitle>
			</EuiModalHeader>

			<EuiModalBody>
				<EuiForm id={"modal"} component="form" onSubmit={() => {}}>
					<EuiFormRow label="Nom de l'évaluation">
						<EuiFieldText required name="header" value={header} onChange={(e) => setHeader(e.target.value)} />
					</EuiFormRow>

					<EuiFormRow label="Description">
						<EuiTextArea name="description" value={description} onChange={(e) => setDescription(e.target.value)} />
					</EuiFormRow>

					<EuiFormRow label="Image de l'automate">
						<>
							<EuiFilePicker
								id={"file"}
								multiple={false}
								initialPromptText={"Choisissez un fichier"}
								onChange={(files: FileList) => {
									const formData = new FormData();
									for (let i = 0; i < files.length; i++) {
										formData.append("file", files[i]);
									}
									const config = {
										onUploadProgress: (progressEvent) => {},
										headers: {
											"content-type": "multipart/form-data",
										},
									};
									axios
										.post(API_PATH + "upload", formData, config)
										.then(({ data }: any) => {
											// const new_proofs = proofs.map(a => { if (a._id === proof._id) { return { ...a, media_id: data.media_id } } else { return a; } })
											// setProofs(new_proofs);
											// update(update_module_instance_proof, { proofs: new_proofs });
											setIllustration(data.media_id);
										})
										.catch((error) => {
											console.error(error);
										});
								}}
								fullWidth
							/>
						</>
					</EuiFormRow>

					<EuiFormRow label="Type">
						<EuiSuperSelect
							options={[
								{
									value: "online",
									inputDisplay: "Évaluation en ligne",
								},
								{
									value: "live",
									inputDisplay: "Évaluation présentielle",
								},
							]}
							valueOfSelected={type}
							onChange={(value) => setType(value)}
						/>
					</EuiFormRow>
				</EuiForm>
			</EuiModalBody>

			<EuiModalFooter>
				<EuiButtonEmpty onClick={closeModal}>Annuler</EuiButtonEmpty>

				<EuiButton
					type="submit"
					// form={"modal"}
					onClick={submit}
					fill
					isLoading={loading as boolean}
				>
					Créer
				</EuiButton>
			</EuiModalFooter>
		</EuiModal>
	);

	return (
		<div>
			<EuiButton onClick={() => setIsModalVisible(true)} iconType="plus">
				Créer une évaluation
			</EuiButton>
			{isModalVisible && modal}
		</div>
	);
}

function ModuleToCorrect({ path, module_instance_id }: { path: string; module_instance_id?: string }) {
	const [module_to_correct, setModuleToCorrect] = useState<PracticalEvaluationModuleInstance | undefined>(undefined);

	const [comment, setComment] = useState<string>("");

	const [fetch] = useApi("post", "modules/instance", (err, module_to_correct) => {
		if (err) {
			console.error(err);
			return;
		}
		setModuleToCorrect(module_to_correct);
	});

	const [update_module, updating] = useApi("put", `modules/instance/correct/${module_instance_id}`, (err, module_to_correct) => {
		if (err) {
			console.error(err);
		} else {
			fetch();
			navigate("/");
		}
	});

	useEffect(() => {
		if (module_instance_id) fetch({ module_instance_id });
	}, [module_instance_id]);

	if (!module_to_correct)
		return (
			<EuiPageTemplate>
				<EuiPageTemplate.Header description={<EuiLoadingContent lines={1} />}></EuiPageTemplate.Header>
				<EuiPageTemplate.Section>
					<EuiLoadingContent lines={5} />
				</EuiPageTemplate.Section>
			</EuiPageTemplate>
		);

	const operator = module_to_correct.operator as unknown as User;

	// @ts-ignore
	const formation = module_to_correct.module.formation as unknown as Formation;

	return (
		<EuiPageTemplate>
			<EuiPageTemplate.Header pageTitle={`Évaluation de la pratique de ${operator.firstname} ${operator.lastname} pour l'évaluation ${formation.header}`} tabs={[]} />

			<EuiPageTemplate.Section>
				{module_to_correct.media_id && (
					<>
						<EuiAspectRatio width={16} height={9}>
							<video controls style={{ maxWidth: 800, objectFit: "contain" }}>
								<source src={API_PATH + "mp4videos/" + module_to_correct.media_id + ".mp4"} type="video/mp4" />
								<EuiText>
									<a href={API_PATH + "mp4videos/" + module_to_correct.media_id + ".mp4"}>Télécharger la vidéo</a>
								</EuiText>
							</video>
						</EuiAspectRatio>

						<EuiSpacer size="xl" />
					</>
				)}

				<EuiFlexGroup>
					<EuiFlexItem>
						<EuiTitle>
							<h3>Consignes</h3>
						</EuiTitle>
					</EuiFlexItem>
				</EuiFlexGroup>

				<EuiSpacer size="m" />

				{module_to_correct.achievements?.map((achievement) => {
					return (
						<>
							<EuiPanel key={achievement._id}>
								<EuiFlexGroup>
									<EuiFlexItem>{achievement.text}</EuiFlexItem>
									<EuiFlexItem grow={false} style={{ placeContent: "center" }}>
										<EuiSwitch
											label="Critère de compétence validé"
											checked={achievement.is_validated}
											onChange={(e) => {
												const new_module_to_correct = {
													...module_to_correct,
												};
												const new_achievements = new_module_to_correct.achievements.map((a) => {
													if (a._id === achievement._id) {
														return {
															...a,
															is_validated: e.target.checked,
														};
													}
													return a;
												});
												new_module_to_correct.achievements = new_achievements;
												setModuleToCorrect(new_module_to_correct);
											}}
										/>
									</EuiFlexItem>
								</EuiFlexGroup>
							</EuiPanel>
							<EuiSpacer size="m" />
						</>
					);
				})}

				<EuiSpacer size="xl" />

				<EuiPanel hasBorder>
					<EuiFlexGroup>
						<EuiFlexItem>
							<EuiTitle>
								<h3>Commentaire</h3>
							</EuiTitle>
						</EuiFlexItem>
					</EuiFlexGroup>

					<EuiSpacer size="m" />

					<EuiTextArea
						fullWidth
						placeholder="Facultatif. Votre commentaire sera ajouté à l'attestation de l'opérateur. Vous pouvez y inclure des URL de vidéos Youtube."
						value={comment}
						onChange={(e) => setComment(e.target.value)}
					/>
				</EuiPanel>

				<EuiSpacer size="xl" />

				<EuiPanel hasBorder>
					<EuiFlexGroup>
						<EuiFlexItem>
							<EuiText>
								Score : {module_to_correct?.achievements?.filter((ach) => ach.is_validated).length || 0} / {module_to_correct?.achievements?.length || 0}
							</EuiText>
						</EuiFlexItem>

						<EuiFlexItem grow={false} style={{ placeContent: "center" }}>
							<EuiButton
								color="primary"
								isLoading={updating}
								onClick={() =>
									update_module({
										...module_to_correct,
										is_corrected: true,
										comment: comment || "",
									})
								}
							>
								Envoyer la conclusion
							</EuiButton>
						</EuiFlexItem>
					</EuiFlexGroup>
				</EuiPanel>
			</EuiPageTemplate.Section>
		</EuiPageTemplate>
	);
}

function ModuleToVerify({ path, module_instance_id }: { path: string; module_instance_id?: string }) {
	const [module_to_verify, setModuleToVerify] = useState<PracticalEvaluationModuleInstance | undefined>(undefined);

	const [fetch] = useApi("post", "modules/instance", (err, module_to_correct) => {
		if (err) {
			console.error(err);
			return;
		}
		setModuleToVerify(module_to_correct);
	});

	const [update_module, updating] = useApi("put", `modules/instance/verify/${module_instance_id}`, (err, module_to_correct) => {
		if (err) {
			console.error(err);
			return;
		} else {
			fetch();
			navigate("/");
		}
	});

	useEffect(() => {
		if (module_instance_id) fetch({ module_instance_id });
	}, [module_instance_id]);

	if (!module_to_verify)
		return (
			<EuiPageTemplate>
				<EuiPageTemplate.Header description={<EuiLoadingContent lines={1} />}></EuiPageTemplate.Header>
				<EuiPageTemplate.Section>
					<EuiLoadingContent lines={5} />
				</EuiPageTemplate.Section>
			</EuiPageTemplate>
		);

	return (
		<EuiPageTemplate>
			<EuiPageTemplate.Header pageTitle={""} tabs={[]} />

			<EuiPageTemplate.Section>
				{module_to_verify.media_id ? (
					<EuiAspectRatio width={16} height={9}>
						<video controls style={{ maxWidth: 800, objectFit: "contain" }}>
							<source src={API_PATH + "mp4videos/" + module_to_verify.media_id + ".mp4"} type="video/mp4" />
							<EuiText>
								<a href={API_PATH + "mp4videos/" + module_to_verify.media_id + ".mp4"}>Télécharger la vidéo</a>
							</EuiText>
						</video>
					</EuiAspectRatio>
				) : (
					<EuiEmptyPrompt title={<h2>Aucune vidéo</h2>} body={<p>Aucune vidéo n'a été téléversée pour cette évaluation pratique.</p>} />
				)}
			</EuiPageTemplate.Section>

			<EuiPageTemplate.Section>
				<EuiFlexGroup>
					<EuiFlexItem>
						<EuiTitle>
							<h3>Consignes</h3>
						</EuiTitle>
					</EuiFlexItem>
				</EuiFlexGroup>

				<EuiSpacer size="m" />

				{module_to_verify.achievements?.map((achievement) => {
					return (
						<>
							<EuiPanel key={achievement._id}>
								<EuiFlexGroup>
									<EuiFlexItem>{achievement.text}</EuiFlexItem>
									<EuiFlexItem grow={false} style={{ placeContent: "center" }}>
										<EuiSwitch label="Critère de compétence validé" checked={achievement.is_validated} disabled onChange={() => {}} />
									</EuiFlexItem>
								</EuiFlexGroup>
							</EuiPanel>
							<EuiSpacer size="m" />
						</>
					);
				})}

				<EuiSpacer size="xl" />

				<EuiPanel hasBorder>
					<EuiFlexGroup>
						<EuiFlexItem>
							<EuiTitle>
								<h3>Commentaire du correcteur</h3>
							</EuiTitle>
						</EuiFlexItem>
					</EuiFlexGroup>

					<EuiSpacer size="m" />

					<Comment text={module_to_verify.comment} />

					{/* <EuiTextArea
						fullWidth
						placeholder="Pas de commentaire du correcteur"
						value={module_to_verify.comment}
						disabled
					/> */}
				</EuiPanel>

				<EuiSpacer size="xl" />

				<EuiPanel hasBorder>
					<EuiFlexGroup>
						<EuiFlexItem></EuiFlexItem>

						<EuiFlexItem grow={false} style={{ placeContent: "center" }}>
							<EuiButton
								color="primary"
								fill
								isLoading={updating}
								onClick={() =>
									update_module({
										...module_to_verify,
										validated_by_verificator: true,
										is_awaiting_verification: false,
									})
								}
							>
								Valider la conclusion
							</EuiButton>
						</EuiFlexItem>

						<EuiFlexItem grow={false} style={{ placeContent: "center" }}>
							<EuiButton
								color="danger"
								isLoading={updating}
								onClick={() =>
									update_module({
										...module_to_verify,
										validated_by_verificator: false,
										is_awaiting_verification: false,
										is_corrected: false,
										is_validated: false,
										achievements: module_to_verify.achievements.map((a) => ({
											...a,
											is_validated: false,
										})),
									})
								}
							>
								Rejeter la conclusion
							</EuiButton>
						</EuiFlexItem>
					</EuiFlexGroup>
				</EuiPanel>
			</EuiPageTemplate.Section>
		</EuiPageTemplate>
	);
}

function SupervisedCommunications({ path }: { path: string }) {
	const [_communications, setCommunications] = useState<SpecialCommunication[]>([]);

	const [fetch] = useApi("get", "communications/delegated/get", (err, communications) => {
		if (err) {
			console.error(err);
			return;
		}
		setCommunications(communications);
	});

	const [sign, signing] = useApi("POST", `communications/sign`, async (err, communications) => {
		fetch();
	});

	useEffect(() => {
		fetch();
	}, []);

	const communications = _communications.filter((c) => !c.is_signed);

	return (
		<EuiPageTemplate>
			<EuiPageTemplate.Header pageTitle="Communications supervisées"></EuiPageTemplate.Header>

			<EuiPageTemplate.Section>
				{communications.length === 0 && <EuiEmptyPrompt title={<h2>Aucune communication à superviser</h2>} body={<p>Vous n'avez aucune communication à superviser</p>} />}

				{communications.length > 0 && (
					<>
						<EuiCallOut>{communications.length} communications internes non signées.</EuiCallOut>
						<EuiSpacer size="m" />
					</>
				)}

				{communications
					?.filter((communication) => !communication.is_signed)
					.map((communication) => (
						<>
							<EuiPanel hasBorder key={communication._id as unknown as string} color={communication.is_signed ? "subdued" : "plain"}>
								<EuiFlexGroup>
									<EuiFlexItem>
										<EuiTitle>
											<h2>
												{communication.header} ({(communication.operator as unknown as User).firstname} {(communication.operator as unknown as User).lastname})
											</h2>
										</EuiTitle>
										<EuiSpacer size="m" />

										<RichText read_only value={communication.description} onChange={(value) => {}} page_id={"special_comm"} />
									</EuiFlexItem>

									<EuiFlexItem grow={false}>
										{!communication.is_signed ? (
											<EuiButton
												onClick={() =>
													sign({
														communication_id: communication._id as unknown as string,
														formation_version: communication.formation_version,
														formation_instance_id: communication.instance,
													})
												}
												disabled={signing}
											>
												Signer
											</EuiButton>
										) : (
											<>
												<i>Signée {timeAgo.format(new Date(communication.signed_on))}</i>
											</>
										)}
									</EuiFlexItem>
								</EuiFlexGroup>
							</EuiPanel>

							<EuiSpacer size="l" />
						</>
					))}
			</EuiPageTemplate.Section>
		</EuiPageTemplate>
	);
}
