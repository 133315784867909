import { useEffect, useState } from "react"
import { useApi } from "../../../../effects/useApi"
import { Formation, FormationSession, Module, Quizz, TheoricEvaluationModule, User, Module as FormationModule, QuizzQuestion, QuizzPossibleAnswer } from "../../../../types"
import { EuiSpacer, EuiButton, EuiCallOut, EuiTitle, EuiBasicTable, EuiSelect, EuiFlexGroup, EuiFlexItem, EuiDescribedFormGroup, EuiFlexGrid, EuiCard, EuiRange, EuiHorizontalRule, EuiPanel, EuiAccordion, EuiSwitch, EuiTextArea, EuiCheckbox, EuiLoadingContent } from "@elastic/eui"
import { Link, navigate, Router } from "@reach/router"
import RichText from "../../../../components/RichText"
import _ from "lodash"

interface QuizzLibraryProps {
	path?: string
	modules: FormationModule[]
	formation: Formation
	reload_formation: () => void
	setDirty: (dirty: boolean) => void
}

export default function QuizzesLibrary({ path, formation, modules, reload_formation, setDirty }: QuizzLibraryProps) {

	const module = modules.find(m => m.type === "TheoricEvaluationModule") as TheoricEvaluationModule

	const quizzes = (module as TheoricEvaluationModule)?.quizzes || [] as Quizz[]

	const [create_quizz, adding_quizz] = useApi('POST', `theoric_evaluation_modules/${module._id}/quizzes`, (err, quizz) => {
		if (err) {
			console.error(err)
			return
		}
		// reload()
		setDirty(true)
	})

	return <>

		<Router primary={false}>
			<QuizzList path="*" module_id={module._id as string} quizzes={module.quizzes as unknown as Quizz[]} reload={reload_formation} module={module} setDirty={setDirty} />
		</Router>
		<EuiSpacer size="xxl" />
	</>
}


export function QuizzList({ path, module_id, reload, quizzes, module, setDirty }: { path: string, module_id: string, reload: () => void, quizzes?: Array<Quizz>, module: TheoricEvaluationModule, setDirty: (value: boolean) => void }) {

	const [create_quizz] = useApi('POST', `theoric_evaluation_modules/${module_id}/quizzes`, (err, quizz) => {
		if (err) {
			console.error(err)
			return
		}
		reload()
		setDirty(true)
	})


	return <Router primary={false}>
		<EditQuizz path=":quizz_id" module_id={module_id} reload={reload} setDirty={setDirty} />
		<List path="/" module_id={module_id} reload={reload} quizzes={quizzes} module={module} setDirty={setDirty} />
	</Router>
}

function List({ path, module_id, reload, quizzes, module, setDirty }: { path: string, module_id: string, reload: () => void, quizzes?: Array<Quizz>, module: TheoricEvaluationModule, setDirty: (value: boolean) => void }) {

	const [create_quizz, adding_quizz] = useApi('POST', `theoric_evaluation_modules/${module_id}/quizzes`, (err, quizz) => {
		if (err) {
			console.error(err)
			return
		}
		reload()
		setDirty(true)
	})

	const [minimal_score, setMinimalScore] = useState<number>(module.minimal_ratio)

	const [setNewRatio] = useApi('PUT', `theoric_evaluation_modules/${module_id}/minimal_ratio`, (err, module) => {
		if (err) {
			console.error(err)
			return
		}
		reload()
		setDirty(true)
	})


	return <>
		<EuiFlexGroup>
			<EuiFlexItem>
				<EuiTitle size="m">
					<h2>Bibliothèque de questionnaires</h2>
				</EuiTitle>
			</EuiFlexItem>
			<EuiFlexItem grow={false}>
				<EuiButton fill onClick={() => { create_quizz() }} isLoading={adding_quizz}>Ajouter un questionnaire</EuiButton>
			</EuiFlexItem>
		</EuiFlexGroup>

		<EuiSpacer size="xxl" />

		{/* <EuiDescribedFormGroup
			title={<h3>Score minimum (%)</h3>}
			description={
				<p>
					Pourcentage minimal de bonnes réponses pour valider le module
				</p>
			}
		>
			<EuiRange fullWidth step={10} showTicks value={minimal_score} onChange={(e: any) => { setMinimalScore(e.target.value); setNewRatio({ minimal_ratio: e.target.value }) }} />
		</EuiDescribedFormGroup>

		<EuiSpacer size="xxl" /> */}

		<EuiFlexGrid columns={4}>
			{quizzes?.map((quizz, i) => {
				return <EuiFlexItem><Link to={quizz._id as string}>
					<EuiCard title={`Questionnaire ${i + 1}`}>
						{/* {JSON.stringify(quizz, null, 2)} */}
						{quizz.questions.length} question{quizz.questions.length > 1 ? 's' : ''}
					</EuiCard></Link>
				</EuiFlexItem>
			})}
		</EuiFlexGrid>

		<EuiSpacer size="xxl" />
	</>
}


function EditQuizz({ path, module_id, quizz_id, setDirty, reload }: { path: string, module_id: string, quizz_id?: string, setDirty: (value: boolean) => void, reload: () => void }) {

	const [quizz, setQuizz] = useState<Quizz>()

	const [get_quizz] = useApi('GET', `theoric_evaluation_modules/${module_id}/quizzes/${quizz_id}`, (err, quizz) => {
		if (err) {
			console.error(err)
			return
		}
		setQuizz(quizz)
	})

	const [add_question] = useApi('POST', `theoric_evaluation_modules/${module_id}/quizzes/${quizz_id}/questions`, (err, quizz) => {
		if (err) {
			console.error(err)
			return
		}
		reload()
		setQuizz(quizz)
		setDirty(true)
	})

	const [delete_quizz] = useApi('DELETE', `theoric_evaluation_modules/${module_id}/quizzes/${quizz_id}`, (err, quizz) => {
		if (err) {
			console.error(err)
			return
		}
		reload()
		setQuizz(undefined)
		setDirty(true)

		navigate(`../`)
		window.location.reload()
	})

	useEffect(() => {
		get_quizz()
	}, [quizz_id])

	let errors = []

	if (quizz) {
		let i = 0
		if (quizz.questions.length < 2) {
			errors.push('Le questionnaire doit contenir au moins 2 questions')
		}
		for (let question of quizz.questions as unknown as Array<QuizzQuestion>) {
			if ((question.possible_answers || []).length < 2) {
				errors.push(`La question n°${i + 1} n'a pas assez de réponses`)
			}

			if ((question.correct_answers || []).length < 1) {
				errors.push(`La question n°${i + 1} n'a pas de bonne réponse`)
			}

			i++
		}
	}

	return <div>

		<EuiFlexGroup>
			<EuiFlexItem><EuiTitle><h2>Quizz</h2></EuiTitle></EuiFlexItem>
			<EuiFlexItem grow={false}>
				<EuiButton color="danger" onClick={() => { delete_quizz() }}>Supprimer le quizz</EuiButton>
			</EuiFlexItem>
			<EuiFlexItem grow={false}>
				<EuiButton fill onClick={() => { add_question() }}>Ajouter une question</EuiButton>
			</EuiFlexItem>
		</EuiFlexGroup>

		<EuiHorizontalRule />

		{errors.length > 0 && <EuiCallOut title="Il y a des erreurs dans ce questionnaire" color="danger">
			<ul>
				{errors.map((error, i) => {
					return <li key={i}>{error}</li>
				})}
			</ul>
		</EuiCallOut>}


		<EuiSpacer size="m" />

		{quizz?.questions.map((question, i) => {
			return <>
				{/* {JSON.stringify(question)} */}
				<QuestionPanel number={i} quizz_id={quizz_id as string} module_id={module_id} reload={() => { get_quizz(); reload() }} question={question._id as unknown as string} setDirty={setDirty} />
			</>
		})}
		{/* <EuiCard title="Ajouter une question" onClick={() => { add_question() }} /> */}
	</div>
}

const throttle = _.throttle((action: any, body: unknown) => {
	action(body)
}, 500)

function QuestionPanel({ question, number, quizz_id, module_id, setDirty, reload }: { question: string, number: number, quizz_id: string, module_id: string, reload: () => void, setDirty: (value: boolean) => void, }) {

	const [quizz_question, setQuizzQuestion] = useState<QuizzQuestion | null>(null)

	const [question_text, setQuestionText] = useState<string>(quizz_question?.question || '')

	const [fetch_question] = useApi('GET', `theoric_evaluation_modules/${module_id}/quizzes/${quizz_id}/questions/${question}`, (err, question) => {
		if (err) {
			console.error(err)
			return
		}
		setQuizzQuestion(question)
		setQuestionText(question.question)
	})

	const [update_question] = useApi('PUT', `theoric_evaluation_modules/${module_id}/quizzes/${quizz_id}/questions/${question}`, (err, question) => {
		if (err) {
			console.error(err)
			return
		}
		setQuizzQuestion(question)
		reload()
		setDirty(true)
	})

	const [delete_question, is_deleting_question] = useApi('DELETE', `theoric_evaluation_modules/${module_id}/quizzes/${quizz_id}/questions/${question}`, (err, question) => {
		if (err) {
			console.error(err)
			return
		}
		reload()
		setDirty(true)
	})

	const [add_answer, is_adding_answer] = useApi('POST', `theoric_evaluation_modules/${module_id}/quizzes/${quizz_id}/questions/${question}/answers`, (err, question) => {
		if (err) {
			console.error(err)
			return
		}
		reload()
		fetch_question()
		setDirty(true)
	})

	const [_update_correct_answers] = useApi('POST', `theoric_evaluation_modules/${module_id}/quizzes/${quizz_id}/questions/${question}/correct_answers`, (err, question) => {
		if (err) {
			console.error(err)
			return
		}
		reload()
		fetch_question()
		setDirty(true)
	})

	const update_correct_answers = (...props: any[]) => {
		reload()
		_update_correct_answers(...props)
	}

	useEffect(() => {
		fetch_question()
	}, [question])

	const correct_answers = quizz_question?.correct_answers.map(a => a._id as unknown as string) || []

	if (!question) return <EuiPanel hasBorder>
		<EuiLoadingContent lines={5} />
	</EuiPanel>

	return <>
		<EuiPanel hasBorder>
			<EuiAccordion
				id={question}
				// buttonContent={question_text.trim() === "" ? <i>Question vide</i> : question_text}
				buttonContent={"Question n°" + (number + 1)}
				forceState={question.trim() === '' ? 'open' : undefined}
			>
				<EuiSpacer size="m" />
				<EuiFlexGroup gutterSize='s'>
					<EuiFlexItem style={{ minWidth: 580 }}>

						{question_text && <RichText
							value={question_text as unknown as any[]}
							onChange={(value) => {
								setQuestionText(value)
								throttle(update_question, { question: value })
							}}
							page_id={number + ""}
						/>}


					</EuiFlexItem>
					<EuiFlexItem grow={false}>
						<EuiButton color='primary' onClick={() => { add_answer() }} isLoading={is_adding_answer}>Ajouter une réponse possible</EuiButton>
					</EuiFlexItem><EuiFlexItem grow={false}>
						<EuiButton color='danger' onClick={() => { delete_question() }} isLoading={is_deleting_question}>Supprimer</EuiButton>
					</EuiFlexItem>
				</EuiFlexGroup>
				<EuiSpacer size="m" />
				<EuiFlexGrid columns={2} gutterSize="s">
					{quizz_question?.possible_answers?.map((answer, i) => {
						return <AnswerPanel
							answer={answer as unknown as QuizzPossibleAnswer}
							reload={reload}
							refetch_question={fetch_question}
							quizz_id={quizz_id}
							module_id={module_id}
							question_id={question}
							correct_answers={quizz_question?.correct_answers.map(a => a._id as unknown as string)}
							update_correct_answers={update_correct_answers}
							setDirty={setDirty}
							key={i}
						/>
					})}

				</EuiFlexGrid>

			</EuiAccordion>

		</EuiPanel>

		<EuiSpacer size="m" />
	</>
}

function AnswerPanel({ answer, reload, refetch_question, module_id, quizz_id, question_id, correct_answers, update_correct_answers, setDirty }: { answer: QuizzPossibleAnswer, reload: () => void, module_id?: string, quizz_id?: string, question_id?: string, refetch_question: () => void, correct_answers: string[], update_correct_answers: (correct_answers: unknown) => void; setDirty: (value: boolean) => void }) {

	const [text, setText] = useState<string>(answer.text || '')

	const [update_answer_text] = useApi('PUT', `theoric_evaluation_modules/${module_id}/quizzes/${quizz_id}/questions/${question_id}/answers/${answer._id}`, (err, question) => {
		if (err) {
			console.error(err)
			return
		}
		reload()
		setDirty(true)
	})

	const [delete_answer, is_loading] = useApi('DELETE', `theoric_evaluation_modules/${module_id}/quizzes/${quizz_id}/questions/${question_id}/answers/${answer._id}`, async (err, question) => {
		if (err) {
			console.error(err)
			return
		}
		await reload()
		await refetch_question()
		setDirty(true)
	})

	return <EuiFlexItem>
		<EuiPanel hasBorder>
			<EuiTextArea fullWidth placeholder='Réponse' rows={2} value={text} onChange={(e: any) => {
				// setQuestionText(e.target.value)
				// throttle(update_question, { question: e.target.value })

				setText(e.target.value)
				throttle(update_answer_text, { text: e.target.value })

			}} isInvalid={text === ""} />
			<EuiSpacer size="s" />
			<EuiFlexGroup gutterSize='xs'>
				<EuiFlexItem grow={false}>
					<EuiCheckbox
						id={answer._id as unknown as string}
						label="Réponse correcte"
						checked={correct_answers.includes(answer._id as unknown as string)}
						onChange={(e) => {
							if (!e.target.checked) {
								update_correct_answers({ correct_answers: correct_answers.filter(a => a !== answer._id) })
							} else {
								update_correct_answers({ correct_answers: [...correct_answers, answer._id] })
							}
						}}
						compressed
					/>
				</EuiFlexItem>
				<EuiFlexItem>
					{/* Bonne réponse */}
				</EuiFlexItem>

				<EuiFlexItem grow={false}>
					<EuiButton color="danger" size="s" onClick={async () => { delete_answer() }} isLoading={is_loading}>Supprimer</EuiButton>
				</EuiFlexItem>
			</EuiFlexGroup>
		</EuiPanel>
	</EuiFlexItem>
}
