import { EuiSpacer, EuiTab, EuiTabs } from '@elastic/eui';
import { Redirect, Router, useMatch } from "@reach/router";
import { User } from '../../types';
import Communications from './Stats/Communications';
import Corrections from './Stats/Corrections';
import Habilitations from './Stats/Habilitations';
import Rehabilitations from './Stats/Rehabilitations'; 

export default function Stats({ path, laboratory_id, navigate, me }: { path: string, laboratory_id?: string, navigate?: (string) => void, me: User }) {

	const match = useMatch('/laboratories/:lab_id/stats/:tab')

	return <>

		<EuiTabs>
			{(me.referent_of.length > 0 || me.laboratory_biologist_of.length > 0) && <EuiTab isSelected={match?.tab === 'habilitations'} onClick={() => navigate('habilitations')}>Évaluations</EuiTab>}
			{(me.referent_of.length > 0 || me.laboratory_biologist_of.length > 0) && <EuiTab isSelected={match?.tab === 'communications'} onClick={() => navigate('communications')}>Communications</EuiTab>}
			{/* {(me.referent_of.length > 0 || me.laboratory_biologist_of.length > 0) && <EuiTab isSelected={match?.tab === 'rehabilitations'} onClick={() => navigate('rehabilitations')}>Questionnaire intermédiaire</EuiTab>} */}
			{(me.referent_of.length > 0 || me.biologist_verificator_of.length > 0 || me.formation_corrector_of.length > 0 || me.laboratory_biologist_of.length > 0) && <EuiTab isSelected={match?.tab === 'corrections'} onClick={() => navigate('corrections')}>Corrections</EuiTab>}
		</EuiTabs>

		<EuiSpacer size="m" />

		<Router primary={false}>
			<Habilitations path="habilitations" laboratory_id={laboratory_id} me={me} />
			<Communications path="communications" laboratory_id={laboratory_id} me={me} />
			<Rehabilitations path="rehabilitations" laboratory_id={laboratory_id} me={me} />
			<Corrections path="corrections" laboratory_id={laboratory_id} me={me} />

			<Default path="*" />
		</Router>

	</>

}

function Default({ path }: { path: string }) {
	return <Redirect to="habilitations" />
}