import { EuiBadge, EuiBasicTable, EuiButton, EuiButtonIcon, EuiCallOut, EuiCheckbox, EuiComboBox, EuiDescribedFormGroup, EuiEmptyPrompt, EuiFieldText, EuiFlexGroup, EuiFlexItem, EuiFlyout, EuiFlyoutBody, EuiFlyoutFooter, EuiFlyoutHeader, EuiFormRow, EuiHorizontalRule, EuiIcon, EuiKeyPadMenu, EuiKeyPadMenuItem, EuiLink, EuiPagination, EuiPanel, EuiProgress, EuiSelect, EuiSpacer, EuiSwitch, EuiTab, EuiTabs, EuiText, EuiTitle } from '@elastic/eui';
import { Redirect, Router, useMatch } from '@reach/router';
import { useEffect, useState } from "react";
import { API_PATH, useApi } from '../../effects/useApi';
import { Formation, Laboratory, Media, User } from '../../types';
import NewUserFlyout from './NewUserFlyout';
import UserFlyout from './UserFlyout';

function get_label(label: string): string {
	if (label === "qualitician") return 'Auteur'
	if (label === "corrector") return 'Évaluateur'
	if (label === "laboratory_verificator") return 'Vérificateur'
	if (label === "laboratory_subreferent") return 'Manager'
	if (label === "laboratory_biologist") return 'Auditeur interne'
	if (label === "referent") return 'Administrateur'
	if(label === "none") return 'Aucun rôle'
}

export default function LaboratoryMembers({ path, laboratory_id, navigate }: { path: string, laboratory_id?: string, navigate?: (string) => void }) {

	const [laboratory, setLaboratory] = useState<Laboratory | null>(null);

	const [members, setMembers] = useState<User[]>([])

	const [active_operators_number, setActiveOperatorsNumber] = useState(0)

	const [count, setCount] = useState(0)
	const [page, setPage] = useState(0)

	const [fetch_active_operators_number] = useApi('get', `laboratories/${laboratory_id}/active_operators_number`, (err, data) => {
		if (err) return console.error(err)
		setActiveOperatorsNumber(data)
	})

	const [fetch_members] = useApi('get', `laboratories/${laboratory_id}/filtered_members`, (err, { members, count, page }) => {
		if (err) return console.error(err)

		setMembers(members)
		setCount(count)
		setPage(page)
	})

	const [fetch_ranges] = useApi('get', `laboratories/${laboratory_id}/filtered_members_ranges`, (err, range: any) => {
		if (err) return console.error(err)

		setPossiblePlaces(range.possible_places || [])
		setPossiblePosts(range.possible_posts || [])
		setPossibleSubreferents(range.possible_subreferents)
		// setPageCount(Math.floor(range.number_of_members / 20) + 1)
	})


	const [fetch_laboratory] = useApi('GET', `laboratories/${laboratory_id}`, (err, laboratory) => {
		if (err) {
			console.error(err)
			return
		}
		setLaboratory(laboratory)
	})

	const [supervise_user] = useApi('post', `users/supervised_users`, (err, users: User[]) => {
		if (err) return console.error(err)

		// fetch_supervised_users()
		fetch_members(build_filter({}))
	})

	const [unsupervise_user] = useApi('delete', `users/supervised_users`, (err, users: User[]) => {
		if (err) return console.error(err)

		// fetch_supervised_users()
		fetch_members(build_filter({}))
	})

	useEffect(() => {
		fetch_members(build_filter({}))
		fetch_laboratory()
		fetch_ranges()
		fetch_active_operators_number()
	}, [laboratory_id])

	const [showing_flyout, setShowingFlyout] = useState(false)
	const [user_details_flyout, setUserDetailsFlyout] = useState<string | null>(null)

	const are_new_members_allowed = laboratory?.users_quota > active_operators_number

	const match = useMatch('/laboratories/:lab_id/members/:tab')

	const tab = match?.tab || 'all'

	const [possible_places, setPossiblePlaces] = useState<string[]>([])
	const [possible_posts, setPossiblePosts] = useState<string[]>([])
	const [possible_subreferents, setPossibleSubreferents] = useState<User[]>([])

	const [selected_roles, setSelectedRoles] = useState<string[]>([])
	const [selected_places, setSelectedPlaces] = useState<string[]>([])
	const [selected_posts, setSelectedPosts] = useState<string[]>([])
	const [name_constraint, setNameConstraint] = useState<string>('')
	const [has_no_subreferent, setHasNoSubreferent] = useState<boolean>(false)
	const [selected_subreferents, setSelectedSubreferents] = useState<string[]>([])

	function build_filter(partial: any) {

		if (tab === 'no_email') {
			return {
				type: 'no_email',
				page,
				// current_page: active_page,
				// selected_places,
				selected_posts,
				selected_roles,
				name_constraint,
				has_no_subreferent,
				selected_subreferents,
				...partial
			}
		}

		return {
			type: 'all',
			page,
			// current_page: active_page,
			selected_places,
			selected_posts,
			selected_roles,
			name_constraint,
			// has_no_subreferent,
			// selected_subreferents,
			...partial
		}

	}

	const columns_all = [{
		field: 'firstname',
		name: 'Nom',
		render: (name, user) => <>
			<EuiLink external onClick={() => setUserDetailsFlyout(user._id.toString())}>{user.firstname} {user.lastname}</EuiLink>  {
				user.is_archived ?
					<EuiBadge color="danger">Archivé</EuiBadge> :
					(user.is_active ?
						<EuiBadge color="primary">Actif</EuiBadge> :
						<EuiBadge color="warning">Désactivé</EuiBadge>
					)
			}
		</>
	}, {
		field: '-',
		name: 'Rôles',
		render: (name, user: User) => <div style={{ display: 'inline' }}>
			{(user.qualitician_of as unknown as string[]).includes(laboratory_id) && <EuiBadge color="hollow">Auteur</EuiBadge>}
			{(user.corrector_of as unknown as string[]).includes(laboratory_id) && <EuiBadge color="hollow">Évaluateur</EuiBadge>}
			{(user.laboratory_verificator_of as unknown as string[]).includes(laboratory_id) && <EuiBadge color="hollow">Vérificateur</EuiBadge>}
			{(user.laboratory_subreferent_of as unknown as string[]).includes(laboratory_id) && <EuiBadge color="hollow">Manager</EuiBadge>}
			{(user.laboratory_biologist_of as unknown as string[]).includes(laboratory_id) && <EuiBadge color="hollow">Auditeur interne</EuiBadge>}
			{(user.referent_of as unknown as string[]).includes(laboratory_id) && <EuiBadge color="hollow">Administrateur</EuiBadge>}
		</div>
	}, {
		field: 'posts',
		name: 'Poste(s)',
		render: (posts, user) => <><div style={{ display: 'inline' }}>
			{posts.map(post => <EuiBadge color="hollow">{post}</EuiBadge>)}
		</div></>
	}, {
		field: 'places',
		name: 'Lieu(x) d\'exercice',
		render: (places, user) => <><div style={{ display: 'inline' }}>
			{places.map(place => <EuiBadge color="hollow">{place}</EuiBadge>)}
		</div></>
	}, {
		field: 'username',
		name: "Nom d'utilisateur"
	}]

	const columns_no_email = [{
		field: 'firstname',
		name: 'Nom',
		render: (name, user) => <>
			<EuiLink external onClick={() => setUserDetailsFlyout(user._id.toString())}>{user.firstname} {user.lastname}</EuiLink>  {
				user.is_archived ?
					<EuiBadge color="danger">Archivé</EuiBadge> :
					(user.is_active ?
						<EuiBadge color="primary">Actif</EuiBadge> :
						<EuiBadge color="warning">Désactivé</EuiBadge>
					)
			}
		</>
	}, {
		field: 'subreferent',
		name: 'Manager',
		render: (subreferent, user) => <>{subreferent ?
			<>
				<EuiText>{subreferent.firstname} {subreferent.lastname}</EuiText>  <EuiButtonIcon
					size="s"
					color="danger"
					iconType="trash"
					onClick={() => {
						unsupervise_user({
							user_id: user._id,
							subreferent_id: subreferent._id
						})
					}}
				/>

			</> :
			<EuiComboBox
				placeholder="Selectionnez un manager"
				singleSelection={{ asPlainText: true }}
				options={[...possible_subreferents.map(user => ({ label: `${user.firstname} ${user.lastname}`, value: user._id.toString() }))]}
				selectedOptions={user.subreferent ? [{ label: `${user.subreferent.firstname} ${user.subreferent.lastname}`, value: user.subreferent._id.toString() }] : []}
				onChange={async (options) => {
					const subreferent_id = options[0]?.value
					if (subreferent_id) {
						supervise_user({ user_id: user._id as unknown as string, subreferent_id })
					}
				}}
			/>


		}</>
	}, {
		field: 'posts',
		name: 'Poste(s)',
		render: (posts, user) => <><div style={{ display: 'inline' }}>{(posts || []).map(post => <EuiBadge color="hollow">{post}</EuiBadge>)}</div></>
	}, {
		field: 'places',
		name: 'Lieu(x) d\'exercice',
		render: (places, user) => <><div style={{ display: 'inline' }}>{(places || []).map(place => <EuiBadge color="hollow">{place}</EuiBadge>)}</div></>
	}, {
		field: 'username',
		name: "Nom d'utilisateur"
	}]


	return <>

		{showing_flyout && <NewUserFlyout
			onClose={() => { setShowingFlyout(false); fetch_ranges() }}
			are_new_members_allowed={are_new_members_allowed}
			laboratory={laboratory}
			refetch_laboratory={fetch_laboratory}
			refetch_members={fetch_members}
			is_current_user_subreferent={false}
		/>}

		{user_details_flyout && <UserFlyout user_id={user_details_flyout} laboratory_id={laboratory_id} onClose={() => { setUserDetailsFlyout(null); fetch_members(build_filter({})); fetch_ranges(); fetch_active_operators_number() }} />}

		<EuiFlexGroup>
			<EuiFlexItem style={{ placeContent: "center" }}>
				{active_operators_number > 0 && <EuiProgress
					value={active_operators_number}
					max={laboratory?.users_quota}
					color={active_operators_number >= laboratory?.users_quota ? "danger" : "primary"}
					valueText={active_operators_number + " opérateurs / " + laboratory?.users_quota}
				/>}
			</EuiFlexItem>
			<EuiFlexItem grow={false}>
				<EuiButton target={"_blank"} href={API_PATH + 'users/lab_anonymous/' + laboratory_id + '.pdf'} color="text">
					Télécharger la liste des opérateurs sans adresse email
				</EuiButton>
			</EuiFlexItem><EuiFlexItem grow={false}>
				<EuiButton fill isDisabled={!are_new_members_allowed} iconType="plus" onClick={() => { setShowingFlyout(true) }}>
					Ajouter des membres
				</EuiButton>
			</EuiFlexItem>
		</EuiFlexGroup>

		<EuiSpacer size="l" />

		<EuiTabs>
			<EuiTab isSelected={tab === 'all'} onClick={() => { navigate('all'); fetch_members(build_filter({ type: 'all' })) }}>Membres</EuiTab>
			<EuiTab isSelected={tab === 'no_email'} onClick={() => { navigate('no_email'); fetch_members(build_filter({ type: 'no_email' })) }}>Opérateurs sans adresse email</EuiTab>
		</EuiTabs>

		<EuiSpacer size="m" />

		<EuiPanel hasBorder>

			<EuiDescribedFormGroup fullWidth title={<h3>Nom</h3>}>
				<EuiFieldText
					placeholder='Nom du membre'
					fullWidth
					value={name_constraint}
					onChange={(e) => {
						setNameConstraint(e.target.value);
						fetch_members(build_filter({
							name_constraint: e.target.value,
						}))
					}}
				/>

			</EuiDescribedFormGroup>

			{tab === 'all' && <EuiDescribedFormGroup fullWidth title={<h3>Rôles du membre</h3>}>
				<EuiComboBox
					fullWidth
					placeholder="Sélectionnez les rôles"
					options={[
						{ label: 'Aucun rôle', value: 'none' },
						{ label: 'Auteur', value: 'qualitician' },
						{ label: 'Évaluateur', value: 'corrector' },
						{ label: 'Vérificateur', value: 'laboratory_verificator' },
						{ label: 'Manager', value: 'laboratory_subreferent' },
						{ label: 'Auditeur interne', value: 'laboratory_biologist' },
						{ label: 'Administrateur', value: 'referent' }
					]}
					onChange={(options) => {
						setSelectedRoles(options.map(o => o.value));
						fetch_members(build_filter({
							selected_roles: options.map(o => o.value),
						}))
					}}
					selectedOptions={selected_roles.map(r => ({ label: get_label(r), value: r }))}
				/>
			</EuiDescribedFormGroup>}

			{true && <EuiDescribedFormGroup fullWidth title={<h3>Lieux d'exercice</h3>}>
				<EuiComboBox
					fullWidth
					placeholder="Sélectionnez les lieux"
					options={(possible_places || []).map(p => ({ label: p, value: p }))}
					onChange={(options) => {
						setSelectedPlaces(options.map(o => o.value));
						fetch_members(build_filter({
							selected_places: options.map(o => o.value),
						}))
					}}
					selectedOptions={selected_places.map(r => ({ label: r, value: r }))}
				/>
			</EuiDescribedFormGroup>}

			{true && <EuiDescribedFormGroup fullWidth title={<h3>Postes</h3>}>
				<EuiComboBox
					fullWidth
					placeholder="Sélectionnez les postes"
					options={(possible_posts || []).map(p => ({ label: p, value: p }))}
					onChange={(options) => {
						setSelectedPosts(options.map(o => o.value)); fetch_members(build_filter({
							selected_posts: options.map(o => o.value),
						}))
					}}
					selectedOptions={selected_posts.map(r => ({ label: r, value: r }))}
				/>
			</EuiDescribedFormGroup>}

			{tab === 'no_email' && <EuiDescribedFormGroup fullWidth title={<h3>Opérateurs sans manager uniquement</h3>}>
				{/* <EuiComboBox
					fullWidth
					placeholder="Sélectionnez les managers"
					options={(possible_subreferents.map(p => ({ label: p.firstname + " " + p.lastname, value: p._id as unknown as string })))}
					onChange={(options) => {
						setSelectedSubreferents(options.map(o => o.value));
						fetch_members(build_filter({
							selected_subreferents: options.map(o => o.value),
						}))
					}}
					selectedOptions={possible_subreferents.filter(sub => selected_subreferents.includes(sub._id as unknown as string)).map(r => ({ label: r.firstname + " " + r.lastname, value: r._id as unknown as string }))}
				/> */}

				<EuiCheckbox
					id='-1'
					label=""
					checked={has_no_subreferent}
					onChange={e => {
						setHasNoSubreferent(e.target.checked);

						if (e.target.checked) {
							setSelectedSubreferents([]);
						}

						fetch_members(build_filter({
							has_no_subreferent: e.target.checked,
							selected_subreferents: []
						}))
					}}
				/>
			</EuiDescribedFormGroup>}

			{tab === 'no_email' && <EuiDescribedFormGroup fullWidth title={<h3>Managers</h3>}>
				<EuiComboBox
					fullWidth
					isDisabled={has_no_subreferent}
					placeholder="Sélectionnez les managers"
					options={(possible_subreferents.map(p => ({ label: p.firstname + " " + p.lastname, value: p._id as unknown as string })))}
					onChange={(options) => {
						setSelectedSubreferents(options.map(o => o.value));
						fetch_members(build_filter({
							selected_subreferents: options.map(o => o.value),
						}))
					}}
					selectedOptions={possible_subreferents.filter(sub => selected_subreferents.includes(sub._id as unknown as string)).map(r => ({ label: r.firstname + " " + r.lastname, value: r._id as unknown as string }))}
				/>
			</EuiDescribedFormGroup>}

		</EuiPanel>


		<EuiSpacer size="m" />

		<div>
			{/* add pagination */}
			<EuiBasicTable
			noItemsMessage="Aucun résultat trouvé"
				items={members}
				columns={tab === "all" ? columns_all : columns_no_email}
				pagination={{
					pageIndex: page,
					pageSize: 25,
					totalItemCount: count,
					showPerPageOptions: false,
				}}
				onChange={({ page }) => {
					setPage(page.index)
					fetch_members(build_filter({
						page: page.index
					}))
				}}


			/>
		</div>
	</>
}