import { useApi } from "../../../../../effects/useApi";
import { CourseModule } from "../../../../../types";
import { EuiButton, EuiFlexGroup, EuiFlexItem, EuiSpacer, EuiTitle } from '@elastic/eui';
import { navigate, Router, useMatch } from "@reach/router";
import PageEditor from "./PageEditor";

export default function CourseModuleEditor({ module, formation_id, reload, reload_formation, setDirty }: { module: CourseModule, formation_id: string, reload: () => void, reload_formation?: () => void, setDirty: (value: boolean) => void }) {

	const [add_page, adding_page] = useApi('POST', `formations/${formation_id}/modules/${module._id}/pages`, (err, page) => {
		reload()
		reload_formation && reload_formation()

		setDirty(true)
	})

	const [remove_page] = useApi('DELETE', `formations/${formation_id}/modules/${module._id}/pages`, (err, page) => {
		reload()
		reload_formation && reload_formation()

		setDirty(true)

		navigate('../')
	})


	const match = useMatch('/formations/:formation_id/modules/:module_id/pages/:page_id')

	return <>
		<EuiFlexGroup>
			<EuiFlexItem>
				<EuiTitle size="m">
					<h2>{module.header}</h2>
				</EuiTitle>
			</EuiFlexItem>
			<EuiFlexItem grow={false}>
				{match?.page_id && <EuiButton color="danger" onClick={() => { remove_page({ page: match?.page_id }) }} isLoading={adding_page}>Supprimer page</EuiButton>}
			</EuiFlexItem>
			<EuiFlexItem grow={false}>
				<EuiButton fill onClick={() => { add_page({ position: module?.pages.length }) }} isLoading={adding_page}>Ajouter une page</EuiButton>
			</EuiFlexItem>
		</EuiFlexGroup>

		<EuiSpacer size="l" />


		<Router primary={false}>
			<PageEditor path="pages/:page_id" formation_id={formation_id} setDirty={setDirty} />
		</Router>
	</>
}