import { useEffect, useState } from "react"
import { API_PATH, useApi } from "../../../../effects/useApi"
import { FormationVersion } from "../../../../types"
import { EuiCard, EuiPanel, EuiTitle, EuiSpacer, EuiFlexGrid, EuiFlexItem, EuiButton, EuiFlexGroup, EuiText } from "@elastic/eui"
import { version } from "typescript"
import RichText from "../../../../components/RichText"

export default function FormationVersions({ path, formation_id }: { path: string, formation_id?: string }) {

	const [formation_versions, setFormationVersions] = useState<FormationVersion[]>([])

	const [fetch_formation] = useApi('GET', `formations/${formation_id}/versions`, (err, formation_versions) => {
		if (err) {
			console.error(err)
			return
		}
		setFormationVersions(formation_versions.reverse())
	})

	useEffect(() => {
		fetch_formation()
	}, [formation_id])


	return <>

		<EuiPanel hasBorder>
			<EuiFlexGroup>
				<EuiFlexItem style={{placeContent: 'center'}}>
					<EuiText>
						La version PDF ne contient pas les images, fichiers PDF et vidéos
					</EuiText>
				</EuiFlexItem>
				<EuiFlexItem grow={false}>

					<EuiButton href={`${API_PATH}formations/${formation_id}/history.pdf`} color="text">Télécharger au format PDF</EuiButton>

				</EuiFlexItem>
			</EuiFlexGroup>

		</EuiPanel>
		<EuiSpacer />

		{formation_versions?.map(version => <div key={version._id as string}>


			{version.is_dev && <>
				<EuiPanel color="subdued">

					<EuiTitle size="s">
						<h3>Version non publiée</h3>
					</EuiTitle>

					<EuiSpacer size="m" />

					{version.changes.length} changement(s) à publier

					{/* <EuiSpacer size="s" /> */}

					{/* {version.changes.length > 0 && <ul>
						{version.changes.map(change => <li key={change._id as string} style={{ marginTop: 5, marginBottom: 5 }}>{change.message}</li>)}
					</ul>} */}


					{/* <RichText
						read_only
						value={version.changelog}
						onChange={(value) => { }}
						page_id={version._id as string}
					/> */}
				</EuiPanel>
				<EuiSpacer size="m" />
			</>}

			{version.is_head && <>
				<EuiPanel hasShadow color="primary">

					<EuiTitle size="s">
						<h3>{version.changelog_header || "Version sans nom"} (dernière version publiée)</h3>
					</EuiTitle>

					<EuiSpacer size="m" />

					{/* {version.changes.length > 0 && <ul>
						{version.changes.map(change => <li key={change._id as string} style={{ marginTop: 5, marginBottom: 5 }}>{change.message}</li>)}
					</ul>} */}


					<RichText
						read_only
						value={version.changelog}
						onChange={(value) => { }}
						page_id={version._id as string}
					/>

				</EuiPanel>
				<EuiSpacer size="m" />
			</>}

			{!version.is_dev && !version.is_head && <>
				<EuiPanel>

					<EuiTitle size="s">
						<h3>{version.changelog_header || "Version sans nom"}</h3>
					</EuiTitle>

					<EuiSpacer size="m" />

					{version.changelog && <RichText
						read_only
						value={version.changelog}
						onChange={(value) => { }}
						page_id={version._id as string}
					/>}

					{/* {version.changes.length > 0 && <ul>
						{version.changes.map(change => <li key={change._id as string} style={{ marginTop: 5, marginBottom: 5 }}>{change.message}</li>)}
					</ul>} */}

				</EuiPanel>
				<EuiSpacer size="m" />
			</>}

		</div>)}
	</>
}