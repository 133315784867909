import { useEffect, useState } from "react"
import { Module, CourseModule, TheoricEvaluationModule, PracticalEvaluationModule } from "../../../../../types"
import { useApi } from "../../../../../effects/useApi"
import CourseModuleEditor from "./CourseModule"
import TheoricEvaluationModuleEditor from "./TheoricEvaluationModule"
import PracticalEvaluationModuleEditor from "./PracticalEvaluationModule"
import { EuiPageTemplate } from '@elastic/eui'

export default function ModuleEditor({ formation_id, module_id, path, reload_formation, setDirty }: { formation_id?: string, module_id?: string, path: string, reload_formation: () => void, setDirty: (value: boolean) => void }) {

	const [module, setModule] = useState<Module | null>(null)

	const [fetch] = useApi('GET', `formations/${formation_id}/modules/${module_id}`, (err, module) => {
		if (err) {
			console.error(err)
			return
		}
		setModule(module)
	})

	useEffect(() => { fetch() }, [module_id])

	const reload = () => { fetch() }

	if (module?.type === "CourseModule") {
		return <CourseModuleEditor module={module as CourseModule} formation_id={formation_id as string} reload={reload} reload_formation={reload_formation} setDirty={setDirty} />
	}

	else if (module?.type === "TheoricEvaluationModule") {
		return <TheoricEvaluationModuleEditor module={module as TheoricEvaluationModule} formation_id={formation_id as string} reload={reload} reload_formation={reload_formation} setDirty={setDirty} />
	}

	else if (module?.type === "PracticalEvaluationModule") {
		return <PracticalEvaluationModuleEditor module={module as PracticalEvaluationModule} reload_formation={reload_formation} setDirty={setDirty} />
	}

	return <div>
		Module editor!
		{JSON.stringify(module, null, 2)}
	</div>

}
