import {
	EuiBasicTable,
	EuiButton,
	EuiCallOut,
	EuiComboBox,
	EuiDatePicker,
	EuiDatePickerRange,
	EuiDescribedFormGroup,
	EuiFlexGroup,
	EuiFlexItem,
	EuiFormRow,
	EuiLink,
	EuiPanel,
	EuiSelect,
	EuiSpacer,
} from "@elastic/eui";
import { Redirect } from "@reach/router";
import moment from "moment";
import { useEffect, useState } from "react";
import { API_PATH, useApi } from "../../../effects/useApi";
import { Formation, User } from "../../../types";

enum HABILITATION_STATUS {
	CANCELED = -1,
	NOT_HABILITATED = 0,
	EXPIRED = 1,
	HABILITATED = 2,
	SUSPENDED = 3,
}

export interface Stat {
	operator_id: string;
	operator_name: string;
	start_date: string;
	end_date: string | null;
	habilited_until: string | null;
	formation_name: string;
	status: HABILITATION_STATUS;
	theoric_exam_score: number;
	theoric_exam_tries: number;
	practical_exam_score: number;
	practical_exam_tries: number;
	corrector_id: string;
	corrector_name: string;
	verificator_id: string;
	verificator_name: string;
	is_live: boolean;
	attestation: string | null;
	is_awaiting_verification: boolean;
}

export default function Habilitations({ path, laboratory_id, me }: { path: string; laboratory_id?: string; me: User }) {
	const [stats, setStats] = useState<Stat[]>([]);

	const [possible_operators, setPossibleOperators] = useState<{ label: string; value?: string }[]>([]);
	const [possible_formations, setPossibleFormations] = useState<{ label: string; value?: string }[]>([]);

	const [operators_filter, setOperatorsFilter] = useState<{ label: string; value?: string }[]>([]);
	const [formations_filter, setFormationsFilter] = useState<{ label: string; value?: string }[]>([]);

	const [start_date_filter, setStartDateFilter] = useState<moment.Moment | null>(null);
	const [end_date_filter, setEndDateFilter] = useState<moment.Moment | null>(null);

	const [status_filter, setStatusFilter] = useState<string>("all");

	const [page, setPage] = useState<number>(0);
	const [count, setCount] = useState<number>(0);
	

	const [fetch_ranges] = useApi("GET", `stats/laboratories/${laboratory_id}/ranges`, (err, { possible_formations, possible_operators }) => {
		if (err) {
			console.error(err);
			return;
		}
		setPossibleFormations(
			possible_formations
				.filter((formation) => {
					if (me.referent_of.length > 0) return true;
					if (me.biologist_of.length > 0 && me.biologist_of.includes(formation._id)) return true;
					return false;
				})
				.map((formation: Formation) => ({
					_id: formation._id,
					label: formation.header,
				}))
		);
		setPossibleOperators(
			possible_operators.map((operator: User) => ({
				label: `${operator.firstname} ${operator.lastname}`,
				_id: operator._id,
			}))
		);
	});

	const [fetch_stats] = useApi("GET", `stats/laboratories/${laboratory_id}`, (err, { stats, page, count }) => {
		if (err) {
			console.error(err);
			return;
		}

		setPage(page);
		setCount(count);

		setStats(
			stats.filter((stat) => {
				if (me.referent_of.length > 0) return true;
				if (me.biologist_of.length > 0 && me.biologist_of.includes(stat.formation_id)) return true;

				return false;
			})
		);
	});

	useEffect(() => {
		fetch_ranges({ as: me._id });
		fetch_stats({ as: me._id });
	}, [laboratory_id, path]);

	function build_body(body: any) {
		return {
			page,
			as: me._id,
			// @ts-ignore
			selected_operators: operators_filter.map((v) => v._id),
			// @ts-ignore
			selected_formations: formations_filter.map((v) => v._id),
			start_date: start_date_filter?.format("YYYY-MM-DD"),
			end_date: end_date_filter?.format("YYYY-MM-DD"),
			selected_status: status_filter,
			...body,
		};
	}

	return (
		<>
			<EuiPanel hasBorder>
				<EuiFlexGroup>
					<EuiFlexItem></EuiFlexItem>
					<EuiFlexItem grow={false}>
						<EuiButton
							iconType="download"
							color="text"
							target="_blank"
							href={`${API_PATH}stats/laboratories/${laboratory_id}?${new URLSearchParams(
								build_body({
									format: "csv",
								})
							).toString()}`}
						>
							Télécharger la vue filtrée au format CSV
						</EuiButton>
					</EuiFlexItem>
				</EuiFlexGroup>

				<EuiDescribedFormGroup fullWidth title={<h3>Opérateurs</h3>} description={<>Laissez vide pour sélectionner tous les opérateurs de l'espace.</>}>
					<EuiFormRow fullWidth>
						<EuiComboBox
							placeholder="Sélectionnez un ou plusieurs opérateur(s)"
							options={possible_operators}
							// @ts-ignore
							onChange={(values) => {
								setOperatorsFilter(values);
								fetch_stats(
									build_body({
										selected_operators: values.map(
											// @ts-ignore
											(v) => v._id
										),
									})
								);
							}}
							selectedOptions={operators_filter}
							isClearable={true}
							fullWidth
						/>
					</EuiFormRow>
				</EuiDescribedFormGroup>

				<EuiDescribedFormGroup fullWidth title={<h3>Évaluations</h3>} description={<>Laissez vide pour sélectionner toutes les évaluations de l'espace.</>}>
					<EuiFormRow fullWidth>
						<EuiComboBox
							placeholder="Sélectionnez une ou plusieurs évaluation(s)"
							options={possible_formations}
							// @ts-ignore
							onChange={(values) => {
								setFormationsFilter(values);
								fetch_stats(
									build_body({
										selected_formations: values.map(
											// @ts-ignore
											(v) => v._id
										),
									})
								);
							}}
							selectedOptions={formations_filter}
							isClearable={true}
							fullWidth
						/>
					</EuiFormRow>
				</EuiDescribedFormGroup>

				<EuiDescribedFormGroup fullWidth title={<h3>Fin d'évaluation'</h3>} description={<>Date à partir de laquelle l'évaluation sera considérée comme expirée.</>}>
					<EuiFormRow fullWidth>
						<EuiDatePickerRange
							fullWidth
							startDateControl={
								<EuiDatePicker
									selected={start_date_filter}
									// @ts-ignore
									onChange={(date) => {
										setStartDateFilter(date);
										fetch_stats(
											build_body({
												start_date: date?.format("YYYY-MM-DD"),
											})
										);
									}}
									isInvalid={start_date_filter && end_date_filter && start_date_filter.isAfter(end_date_filter)}
									dateFormat="DD/MM/YYYY"
								/>
							}
							endDateControl={
								<EuiDatePicker
									selected={end_date_filter}
									onChange={(date) => {
										setEndDateFilter(date);
										fetch_stats(
											build_body({
												end_date: date?.format("YYYY-MM-DD"),
											})
										);
									}}
									isInvalid={start_date_filter && end_date_filter && start_date_filter.isAfter(end_date_filter)}
									dateFormat="DD/MM/YYYY"
								/>
							}
						/>
					</EuiFormRow>
				</EuiDescribedFormGroup>

				<EuiDescribedFormGroup fullWidth title={<h3>Statut</h3>} description={<></>}>
					<EuiFormRow fullWidth>
						<EuiSelect
							fullWidth
							options={[
								{ value: "all", text: "Toutes" },
								{
									value: "non_habilitated",
									text: "Non évalué",
								},
								{
									value: "expired",
									text: "Évaluation expirée",
								},
								{
									value: "validated",
									text: "Évaluation validée",
								},
								{
									value: "canceled",
									text: "Évaluation invalidée",
								},
								{
									value: "suspended",
									text: "Évaluation suspendue",
								},
							]}
							value={status_filter}
							onChange={(e) => {
								setStatusFilter(e.target.value);
								fetch_stats(
									build_body({
										selected_status: e.target.value,
									})
								);
							}}
						/>
					</EuiFormRow>
				</EuiDescribedFormGroup>
			</EuiPanel>

			<EuiSpacer size="xxl" />

			<div>
				{/* add pagination */}
				<EuiBasicTable
					noItemsMessage="Aucun résultat trouvé"
					items={stats}
					columns={[
						// {
						// field: 'instance',
						// name: "id"
						// },
						{
							field: "operator_name",
							name: "Opérateur",
							render: (name, stat) => <strong>{name}</strong>,
						},
						{
							field: "formation_name",
							name: "Évaluation",
						},
						{
							field: "start_date",
							name: "Début / Fin d'évaluation",
							// render: (start_date, stat) => <>{moment(start_date).format('DD/MM/YYYY')} — {moment(stat.end_date).format('DD/MM/YYYY')}</>
							render: (start_date, stat) => {
								switch (stat.status) {
									case HABILITATION_STATUS.NOT_HABILITATED:
										return <>—</>;
									case HABILITATION_STATUS.EXPIRED: {
										return (
											<>
												{moment(start_date).format("DD/MM/YYYY")} — {moment(stat.end_date).format("DD/MM/YYYY")}
											</>
										);
									}
									case HABILITATION_STATUS.HABILITATED: {
										return (
											<>
												{moment(start_date).format("DD/MM/YYYY")} — {stat.end_date ? moment(stat.end_date).format("DD/MM/YYYY") : "..."}
											</>
										);
									}
								}
							},
						},
						{
							field: "status",
							name: "Statut",
							// render: (status) => {
							// 	return <p>{status}</p>;
							// },
							render: (status) => {
								switch (status) {
									case HABILITATION_STATUS.NOT_HABILITATED:
										return (
											<EuiCallOut
												style={{
													padding: 4,
													borderRadius: 4,
												}}
												color="danger"
											>
												Non évalué
											</EuiCallOut>
										);
									case HABILITATION_STATUS.EXPIRED:
										return (
											<EuiCallOut
												style={{
													padding: 4,
													borderRadius: 4,
												}}
												color="warning"
											>
												Évaluation expirée
											</EuiCallOut>
										);
									case HABILITATION_STATUS.HABILITATED:
										return (
											<EuiCallOut
												style={{
													padding: 4,
													borderRadius: 4,
												}}
												color="success"
											>
												Évaluation validée
											</EuiCallOut>
										);
									case HABILITATION_STATUS.CANCELED:
										return (
											<EuiCallOut
												style={{
													padding: 4,
													borderRadius: 4,
													background: "#eee",
												}}
												color="danger"
											>
												Évaluation invalidée
											</EuiCallOut>
										);
									case HABILITATION_STATUS.SUSPENDED:
										return (
											<EuiCallOut
												style={{
													padding: 4,
													borderRadius: 4,
												}}
												color="danger"
											>
												Évaluation suspendue
											</EuiCallOut>
										);
								}
							},
						},
						{
							field: "theoric_exam_score",
							name: "Questionnaire",
							render: (score, stat) => (
								<>
									{Math.floor(score)}/100 <br />({stat.theoric_exam_tries} essai
									{stat.theoric_exam_tries > 1 ? "s" : ""})
								</>
							),
						},
						{
							field: "practical_exam_score",
							name: "Examen pratique",
							render: (score, stat) => (
								<>
									{stat.is_awaiting_verification ? (
										<>Correction en cours</>
									) : (
										<>
											{Math.floor(score)}/100 <br />({stat.practical_exam_tries} essai
											{stat.practical_exam_tries > 1 ? "s" : ""})
										</>
									)}
								</>
							),
						},
						{
							field: "corrector_name",
							name: "Évaluateur",
							render: (name, stat) => <>{name}</>,
						},
						{
							field: "verificator_name",
							name: "Vérificateur",

							render: (name, stat) => <>{name}</>,
						},
						{
							field: "is_live",
							name: "Évaluation présentielle",
							render: (is_live) => (is_live ? "Oui" : "Non"),
						},
						{
							field: "attestation",
							name: "Attestation",
							render: (attestation) => (attestation ? <EuiLink href={attestation}>Télécharger</EuiLink> : "—"),
						},
						// {
						// 	field: "is_awaiting_verification",
						// 	name: "En attente de vérification",
						// 	render: (is_awaiting_verification) => (is_awaiting_verification ? "Oui" : "Non"),
						// }
					]}
					pagination={{
						pageIndex: page,
						pageSize: 25,
						totalItemCount: count,
						showPerPageOptions: false,
					}}
					onChange={({ page }) => {
						setPage(page.index);
						fetch_stats(
							build_body({
								page: page.index,
							})
						);
					}}
				/>
			</div>
		</>
	);
}
