import { EuiButton, EuiCallOut, EuiEmptyPrompt, EuiFlexGroup, EuiFlexItem, EuiPanel, EuiSpacer, EuiText, EuiTitle } from "@elastic/eui"
import TimeAgo from 'javascript-time-ago'
import fr from 'javascript-time-ago/locale/fr'
import { useEffect, useState } from "react"
import RichText from "../../components/RichText"
import { useApi } from "../../effects/useApi"
import { SpecialCommunication } from "../../types/SpecialCommunication"

TimeAgo.addLocale(fr)

const timeAgo = new TimeAgo('fr-FR')

export default function Communications({ path, formation_instance_id, refetch_formation }: { path: string, formation_instance_id?: string, refetch_formation?: () => void }) {

	const [communications, setCommunications] = useState<SpecialCommunication[]>([])

	const [fetch_communications] = useApi('GET', `formation_instances/${formation_instance_id}/communications`, async (err, communications) => {
		setCommunications(communications.reverse())
	})

	const [sign, signing] = useApi('POST', `communications/sign`, async (err, communications) => {
		fetch_communications()
		refetch_formation && refetch_formation()
	})

	useEffect(() => {
		fetch_communications()
	}, [])

	return <div>
		<EuiTitle>
			<h1>Communications</h1>
		</EuiTitle>
		<EuiSpacer size="s" />

		<EuiCallOut>Ici, vous pourrez consulter et valider les communications envoyées par votre administrateur.</EuiCallOut>
		<EuiSpacer size="l" />

		{communications.length === 0 && <EuiEmptyPrompt
			title={<h2>Aucune communication</h2>}
			body={<p>Vous n'avez pas encore reçu de communication.</p>}
			titleSize="s"
		/>}

		{communications.map(communication => <><EuiPanel hasBorder key={communication._id as unknown as string} color={communication.is_signed ? "subdued" : "plain"} >

			<EuiFlexGroup>
				<EuiFlexItem>

					<EuiTitle>
						<h2>{communication.header}</h2>
					</EuiTitle>
					<EuiSpacer size="m" />

					{/* <EuiMarkdownFormat>{communication.description}</EuiMarkdownFormat> */}

					<RichText
						read_only
						value={communication.description}
						onChange={(value) => { }}
						page_id={"special_comm"}
					/>


				</EuiFlexItem>

				<EuiFlexItem grow={false}>
					{!communication.is_signed ? <EuiButton onClick={() => sign({
						communication_id: communication._id as unknown as string,
						formation_instance_id: formation_instance_id,
						formation_version: communication.formation_version
					})} disabled={signing}>Signer</EuiButton> : <><i>Signée {timeAgo.format(new Date(communication.signed_on))}</i></>}
				</EuiFlexItem>
			</EuiFlexGroup>

		</EuiPanel>

			<EuiSpacer size="l" />
		</>)}

	</div>
}