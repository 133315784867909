import {
	EuiButton,
	EuiButtonEmpty, EuiCard, EuiFieldText, EuiFilePicker, EuiFlexGrid, EuiFlexItem, EuiForm,
	EuiFormRow, EuiImage, EuiModal,
	EuiModalBody,
	EuiModalFooter,
	EuiModalHeader, EuiModalHeaderTitle, EuiSuperSelect, EuiTextArea, useGeneratedHtmlId
} from '@elastic/eui';
import { navigate } from "@reach/router";
import axios from 'axios';
import { useEffect, useState } from "react";
import { API_PATH, useApi } from "../../../effects/useApi";
import { Formation, Laboratory } from "../../../types";

export default function LaboratoryFormations({ path, laboratory_id }: { path: string, laboratory_id?: string }) {

	const [laboratory, setLaboratory] = useState<Laboratory | null>(null);

	const [formations, setFormations] = useState<Formation[]>([])

	const [fetch_formations] = useApi('GET', `laboratories/${laboratory_id}/formations`, (err, formations) => {
		if (err) {
			console.error(err)
			return
		}
		setFormations(formations)
	})

	const [fetch_laboratory] = useApi('GET', `laboratories/${laboratory_id}`, (err, laboratory) => {
		if (err) {
			console.error(err)
			return
		}
		setLaboratory(laboratory)
	})

	useEffect(() => {
		fetch_formations()
		fetch_laboratory()
	}, [laboratory_id])

	const [post, loading] = useApi('POST', 'formations', (error, result) => {
		if (!error) {
			fetch_formations()
		} else {
			console.error(error)
		}
	})

	const [isModalVisible, setIsModalVisible] = useState(false);

	const [header, setHeader] = useState('')
	const [description, setDescription] = useState('')
	const [type, setType] = useState('online')
	const [illustration, setIllustration] = useState<string | null>(null)

	const modalFormId = useGeneratedHtmlId({ prefix: 'modalForm' });

	const closeModal = () => setIsModalVisible(false);

	const submit = async () => {
		await post({ header, description, laboratory_id, type, illustration })
		fetch_formations()
		closeModal()
	}

	const modal = <EuiModal onClose={closeModal} initialFocus="[name=popswitch]">
		<EuiModalHeader>
			<EuiModalHeaderTitle>
				<h1>Nouvelle évaluation</h1>
			</EuiModalHeaderTitle>
		</EuiModalHeader>

		<EuiModalBody>

			<EuiForm id={modalFormId} component="form" onSubmit={() => {}} >

				<EuiFormRow label="Nom de l'évaluation">
					<EuiFieldText required name="header" value={header} onChange={(e => setHeader(e.target.value))} />
				</EuiFormRow>

				<EuiFormRow label="Description">
					<EuiTextArea name="description" value={description} onChange={(e => setDescription(e.target.value))} />
				</EuiFormRow>

				<EuiFormRow label="Image de l'automate"><>
					<EuiFilePicker
						id={"file"}
						multiple={false}
						initialPromptText={"Choisissez un fichier"}

						onChange={(files: FileList) => {
							const formData = new FormData()
							for (let i = 0; i < files.length; i++) { formData.append('file', files[i]) }
							const config = { onUploadProgress: progressEvent => { }, headers: { 'content-type': 'multipart/form-data' } }
							axios.post(API_PATH + "upload", formData, config)
								.then(({ data }: any) => {
									// const new_proofs = proofs.map(a => { if (a._id === proof._id) { return { ...a, media_id: data.media_id } } else { return a; } })
									// setProofs(new_proofs);
									// update(update_module_instance_proof, { proofs: new_proofs });
									setIllustration(data.media_id)
								}).catch((error) => { console.error(error) });
						}}
						fullWidth
					/>
				</></EuiFormRow>

				<EuiFormRow label="Type">
					<EuiSuperSelect
						options={[{
							value: 'online',
							inputDisplay: 'Évaluation en ligne',
						}, 
						// {
						// 	value: 'live',
						// 	inputDisplay: 'Évaluation présentielle',
						// }
					]}
						valueOfSelected={type}
						onChange={(value) => setType(value)}
					/>
				</EuiFormRow>
			</EuiForm>
		</EuiModalBody>



		<EuiModalFooter>
			<EuiButtonEmpty onClick={closeModal}>Annuler</EuiButtonEmpty>

			{/* @ts-ignore */}
			<EuiButton type="submit" form={modalFormId} onClick={submit} fill isLoading={loading}>
				Créer
			</EuiButton>
		</EuiModalFooter>
	</EuiModal>


	return <>
		<EuiFlexGrid columns={3} gutterSize="xl">
			{formations.map(formation => {
				return <EuiFlexItem>
					<EuiCard
						title={formation.header}
						layout="horizontal"
						icon={formation.image && <EuiImage src={API_PATH + "videos/" + formation.image} size="s" hasShadow={false} style={{ borderRadius: 5, maxWidth: 50, maxHeight: 50 }} allowFullScreen alt=" " />}
						onClick={() => navigate('/formations/' + formation._id as string)}
						betaBadgeProps={formation.is_live ? {
							label: 'Évaluation présentielle',
							color: 'hollow',
							tooltipContent: 'Cette formation est dispensée en présentiel',
						} : {
							label: 'Évaluation en ligne',
							color: 'hollow',
							tooltipContent: 'Cette formation est dispensée en ligne',
						}}
					>
						
						{formation.description}
						{/* {formation.image} */}
					</EuiCard>
				</EuiFlexItem>
			})}

			<EuiFlexItem>
				<EuiCard
					title="Ajouter une évaluation"
					onClick={() => setIsModalVisible(true)}
				>
				</EuiCard>
			</EuiFlexItem>

			{isModalVisible && modal}
		</EuiFlexGrid>
	</>
}