import { FormEvent, useEffect, useState } from "react";
import { H2 } from "../../components/Titles";
import { API_PATH, useApi } from "../../effects/useApi";
import { Formation, Laboratory, User } from "../../types";

import csv from "csvtojson";

import {
	EuiBasicTable,
	EuiButton,
	EuiCallOut,
	EuiCheckbox,
	EuiComboBox,
	EuiDatePicker,
	EuiDatePickerRange,
	EuiDescribedFormGroup,
	EuiFieldNumber,
	EuiFieldText,
	EuiFilePicker,
	EuiFlexGrid,
	EuiFlexGroup,
	EuiFlexItem,
	EuiForm,
	EuiFormRow,
	EuiLink,
	EuiLoadingContent,
	EuiPageTemplate,
	EuiPanel,
	EuiSelect,
	EuiSpacer,
	EuiStat,
	EuiText,
	EuiTitle,
} from "@elastic/eui";
import { Router, useLocation } from "@reach/router";
import UserSummary from "../../components/UserSummary";
import { Stat } from "../Laboratories/Stats/Habilitations";

export default function EditLaboratory({ path, laboratory_id, navigate }: { path: string; laboratory_id?: string; navigate?: any }) {
	const [laboratory, setLaboratory] = useState<Laboratory | undefined>(undefined);

	const [errors, setErrors] = useState<{ [key: string]: string }>({});

	const [fetch_laboratory, loading] = useApi("GET", `laboratories/${laboratory_id}`, (err, lab) => {
		if (!err) {
			setLaboratory(lab);
		}
	});

	useEffect(() => {
		fetch_laboratory();
	}, [laboratory_id]);

	async function handleChange(selectorFiles: FileList) {
		const _csv = await selectorFiles[0].text();

		const json = await csv().fromString(_csv);

		if (json.length > 0) {
			const users_to_create = [];

			let i = 0;

			for (let user of json) {
				i++;

				if (!user["prénom"] || !user["nom"]) {
					setErrors({
						...errors,
						csv: `La ligne ${i} n'a pas de nom ou prénom`,
					});
					continue;
				}

				const _user: Partial<User> = {
					firstname: user["prénom"],
					lastname: user["nom"],
					email: user["email"],
					phone: user["téléphone"],
					job_title: user["numero adeli"],
					rpps_number: user["rpps"],
				};

				users_to_create.push(_user);
			}

			const body = {
				laboratory: laboratory_id,
				users: users_to_create,
			};
		}
	}

	const { pathname } = useLocation();

	if (!laboratory)
		return (
			<EuiPageTemplate restrictWidth={800} bottomBorder={true}>
				<EuiPageTemplate.Section>
					<EuiLoadingContent lines={5} />
					<EuiLoadingContent lines={3} />
					<EuiLoadingContent lines={4} />
				</EuiPageTemplate.Section>
			</EuiPageTemplate>
		);

	if (loading)
		return (
			<EuiPageTemplate restrictWidth={800} bottomBorder={true}>
				<EuiPageTemplate.Section>
					<EuiLoadingContent lines={5} />
					<EuiLoadingContent lines={3} />
					<EuiLoadingContent lines={4} />
				</EuiPageTemplate.Section>
			</EuiPageTemplate>
		);

	return (
		<>
			<EuiPageTemplate restrictWidth={800} bottomBorder={true}>
				<EuiPageTemplate.Header
					pageTitle={laboratory.name}
					rightSideItems={[]}
					tabs={[
						{
							label: "Membres",
							isSelected: pathname.indexOf("members") > -1 || (pathname.indexOf("statistics") === -1 && pathname.indexOf("cgu") === -1 && pathname.indexOf("settings") === -1),
							onClick: () => navigate("members"),
						},
						{
							label: "Conditions générales",
							isSelected: pathname.indexOf("cgu") > -1,
							onClick: () => navigate("cgu"),
						},
						{
							label: "Statistiques",
							isSelected: pathname.indexOf("statistics") > -1,
							onClick: () => navigate("statistics"),
						},
						{
							label: "Réglages",
							isSelected: pathname.indexOf("settings") > -1,
							onClick: () => navigate("settings"),
						},
					]}
				/>
				<EuiPageTemplate.Section>
					<Router primary={false}>
						<Statistics path="statistics" laboratory={laboratory} />
						<Settings path="settings" laboratory={laboratory} fetch_laboratory={fetch_laboratory} />
						<Cgu path="cgu" laboratory={laboratory} fetch_laboratory={fetch_laboratory} />
						<Members path="*" />
					</Router>
				</EuiPageTemplate.Section>
			</EuiPageTemplate>
		</>
	);
}

function Members({ path, laboratory_id }: { path: string; laboratory_id?: string }) {
	const [laboratoryUsers, setLaboratoryUsers] = useState<Array<User> | undefined>(undefined);

	const [users_count, setUsersCount] = useState<number | undefined>(undefined);
	const [page, setPage] = useState(0);

	const [fetch_laboratory_users] = useApi("GET", `laboratories/${laboratory_id}/users`, (err, { users, count, page }) => {
		if (!err) {
			setLaboratoryUsers(users);
			setUsersCount(count);
			setPage(page);
		}
	});

	async function handle_file(selectorFiles: FileList) {
		const _csv = await selectorFiles[0].text();

		const json = await csv().fromString(_csv);

		if (json.length > 0) {
			const _users_to_create = [];

			let i = 0;

			for (let user of json) {
				i++;

				if (!user["prénom"] || !user["nom"]) {
					// setErrors({ ...errors, csv: `La ligne ${i} n'a pas de nom ou prénom` })
					continue;
				}

				const _user: Partial<User> = {
					firstname: user["prénom"],
					lastname: user["nom"],
					email: user["email"],
					phone: user["téléphone"],
					job_title: user["numero adeli"],
					rpps_number: user["rpps"],
				};

				_users_to_create.push(_user);
			}

			setUsersToCreate([...users_to_create, ..._users_to_create]);
		}
	}

	const [users_to_create, setUsersToCreate] = useState<Partial<User>[]>([]);
	const [create_users, creating_users] = useApi("POST", "users/batch", async (err, users) => {
		if (!err) {
			setUsersToCreate([]);
			await fetch_laboratory_users();
		} else {
			console.error(err);
			// setAddingUsers(false)
		}
	});

	useEffect(() => {
		// fetch_users_count()
		fetch_laboratory_users({ page: page });
	}, [laboratory_id]);

	return (
		<div>
			<EuiBasicTable
				noItemsMessage="Aucun résultat trouvé"
				items={laboratoryUsers || []}
				columns={[
					{
						field: "firstname",
						name: "Nom",
						render: (firstname: any, user: any): string => {
							return `${user.firstname} ${user.lastname}`;
						},
					},
					{
						field: "username",
						name: "Username",
					},
					{
						field: "email",
						name: "Email",
					},
					{
						field: "phone",
						name: "Téléphone",
					},
					{
						field: "job_title",
						name: "Champ personnalisé 1",
					},
				]}
				pagination={{
					pageIndex: page,
					pageSize: 25,
					totalItemCount: users_count || 0,
					showPerPageOptions: false,
				}}
				onChange={({ page }) => {
					setPage(page.index);
					fetch_laboratory_users({ page: page.index });
				}}
			/>

			<EuiSpacer size="l" />

			<EuiTitle>
				<h3>Ajouter des membres depuis un fichier CSV</h3>
			</EuiTitle>

			<EuiSpacer size="m" />

			<EuiFilePicker id="file-picker" initialPromptText="Déposez un fichier ici ou cliquez pour sélectionner" onChange={handle_file} fullWidth />

			<EuiSpacer size="l" />

			{users_to_create.length ? (
				<EuiBasicTable
					noItemsMessage="Aucun résultat trouvé"
					items={users_to_create}
					columns={[
						{
							field: "firstname",
							name: "Nom",
							render: (name, user) => (
								<>
									{user.firstname} {user.lastname}
								</>
							),
						},
						{
							field: "email",
							name: "Email",
						},
						{
							field: "job_title",
							name: "Champ personnalisé 1",
						},
						{
							field: "rpps_number",
							name: "Champ personnalisé 2",
						},
					]}
				/>
			) : null}

			{/* <EuiSpacer size="l" /> */}

			{users_to_create.length > 0 && (
				<EuiButton
					isLoading={creating_users}
					fullWidth
					onClick={() => {
						// setAddingUsers(true);
						create_users({
							laboratory: laboratory_id,
							users: users_to_create,
						});
					}}
				>
					{users_to_create.length === 1 ? "Ajouter 1 utilisateur" : `Ajouter ${users_to_create.length} utilisateurs`}
				</EuiButton>
			)}
		</div>
	);
}

function Statistics({ path, laboratory }: { path: string; laboratory: Laboratory }) {
	const [stats, setStats] = useState<any>(null);

	const [fetch_laboratory_statistics] = useApi("GET", `laboratories/${laboratory._id}/admin_stats`, (err, stats) => {
		if (!err) {
			setStats(stats);
		}
	});

	useEffect(() => {
		fetch_laboratory_statistics();
	}, [laboratory._id]);

	if (!stats) return <EuiLoadingContent />;

	return (
		<div>
			<EuiTitle>
				<h2>Membres</h2>
			</EuiTitle>

			<EuiSpacer size="m" />

			<EuiFlexGrid columns={4}>
				<EuiFlexItem>
					<EuiPanel hasBorder>
						<EuiStat title={stats.users} description="Utilisateurs" />
					</EuiPanel>
				</EuiFlexItem>

				<EuiFlexItem>
					<EuiPanel hasBorder>
						<EuiStat title={stats.operators} description="Opérateurs actifs" />
					</EuiPanel>
				</EuiFlexItem>

				<EuiFlexItem>
					<EuiPanel hasBorder>
						<EuiStat title={stats.referents} description="Administrateurs" />
					</EuiPanel>
				</EuiFlexItem>

				<EuiFlexItem>
					<EuiPanel hasBorder>
						<EuiStat title={stats.qualiticians} description="Auteurs" />
					</EuiPanel>
				</EuiFlexItem>

				<EuiFlexItem>
					<EuiPanel hasBorder>
						<EuiStat title={stats.correctors} description="Évaluateurs" />
					</EuiPanel>
				</EuiFlexItem>

				<EuiFlexItem>
					<EuiPanel hasBorder>
						<EuiStat title={stats.verificator} description="Vérificateurs" />
					</EuiPanel>
				</EuiFlexItem>

				<EuiFlexItem>
					<EuiPanel hasBorder>
						<EuiStat title={stats.subreferents} description="Managers" />
					</EuiPanel>
				</EuiFlexItem>

				<EuiFlexItem>
					<EuiPanel hasBorder>
						<EuiStat title={stats.biologists} description="Auditeurs internes" />
					</EuiPanel>
				</EuiFlexItem>
			</EuiFlexGrid>

			<EuiSpacer size="l" />

			<EuiTitle>
				<h2>Évaluations</h2>
			</EuiTitle>

			<EuiSpacer size="m" />

			<EuiFlexGrid columns={3}>
				<EuiFlexItem>
					<EuiPanel hasBorder>
						<EuiStat title={stats.habilitations} description="Évaluations" />
					</EuiPanel>
				</EuiFlexItem>

				<EuiFlexItem>
					<EuiPanel hasBorder>
						<EuiStat title={stats.started} description="Évaluations en cours" />
					</EuiPanel>
				</EuiFlexItem>

				<EuiFlexItem>
					<EuiPanel hasBorder>
						<EuiStat title={stats.validated} description="Évaluations validées" />
					</EuiPanel>
				</EuiFlexItem>

				<EuiFlexItem>
					<EuiPanel hasBorder>
						<EuiStat title={stats.expired} description="Évaluations expirées" />
					</EuiPanel>
				</EuiFlexItem>

				<EuiFlexItem>
					<EuiPanel hasBorder>
						<EuiStat title={stats.canceled} description="Évaluations annulées" />
					</EuiPanel>
				</EuiFlexItem>

				<EuiFlexItem>
					<EuiPanel hasBorder>
						<EuiStat title={stats.suspended} description="Évaluations suspendues" />
					</EuiPanel>
				</EuiFlexItem>
			</EuiFlexGrid>
		</div>
	);
}

function Settings({ path, laboratory, fetch_laboratory }: { path: string; laboratory: Laboratory; fetch_laboratory: () => void }) {
	const [laboratory_users, setLaboratoryUsers] = useState<Array<User> | undefined>(undefined);

	const [users_quota, setUsersQuota] = useState<number | undefined>(laboratory.users_quota || 0);

	const [can_display_logo, setCanDisplayLogo] = useState<boolean>(laboratory.can_display_logo || false);

	const [fetch_laboratory_users] = useApi("GET", `laboratories/${laboratory._id}/users`, (err, { users, count, page }) => {
		if (!err) {
			setLaboratoryUsers(users);
			setIsArchived(laboratory.is_archived);
			if (laboratory.delete_on) setDeleteOn(new Date(laboratory.delete_on));
		}
	});

	const [delete_referent] = useApi("POST", `users/delete_referent/${laboratory._id}`, (err, lab) => {
		if (!err) {
			fetch_laboratory();
		}
	});

	const [update_laboratory] = useApi("PUT", `laboratories/${laboratory._id}`, (err, lab) => {
		if (!err) {
			// fetch_laboratory()
		}
	});

	const operators_number = laboratory_users?.filter((user) => user.operator_of.length > 0 && !!user.is_active && !user.is_archived).length;

	const [is_archived, setIsArchived] = useState<boolean>(false);
	const [delete_on, setDeleteOn] = useState<Date | undefined>(undefined);

	const [archive_laboratory, archiving] = useApi("post", `laboratories/archive/${laboratory._id}`, (err, user: User) => {
		if (err) return console.error(err);

		fetch_laboratory();
	});

	const [unarchive_laboratory, unarchiving] = useApi("post", `laboratories/unarchive/${laboratory._id}`, (err, user: User) => {
		if (err) return console.error(err);

		fetch_laboratory();
	});

	const [archive_laboratory_now, archiving_now] = useApi("post", `laboratories/archive_now/${laboratory._id}`, (err, user: User) => {
		if (err) return console.error(err);

		// redirect to main page
		window.location.href = "/";
	});

	useEffect(() => {
		fetch_laboratory_users();
	}, [laboratory._id]);

	return (
		<div>
			<EuiPanel hasBorder>
				<EuiDescribedFormGroup
					title={<h3>Administrateur</h3>}
					description={
						<>
							{laboratory.referent ? (
								<>
									<EuiButton
										size="s"
										color="danger"
										onClick={() => {
											delete_referent({
												referent_id: laboratory.referent,
											});
										}}
									>
										Supprimer l'administrateur
									</EuiButton>
								</>
							) : (
								<></>
							)}
						</>
					}
				>
					<EuiFormRow>
						{laboratory.referent ? <UserSummary _id={laboratory.referent} /> : <DesignateReferent laboratory_id={laboratory._id} fetch_laboratory={fetch_laboratory} />}
					</EuiFormRow>
				</EuiDescribedFormGroup>

				{operators_number > users_quota && (
					<>
						<EuiCallOut color="danger" title="Le quota d'utilisateurs est dépassé">
							{/* <p>Le quota d'utilisateurs est dépassé. Vous ne pouvez plus ajouter d'utilisateurs.</p> */}
						</EuiCallOut>
						<EuiSpacer />
					</>
				)}
			</EuiPanel>

			<EuiSpacer />

			<EuiPanel hasBorder>
				<EuiDescribedFormGroup title={<h3>Opérateurs</h3>} description={<>Nombre actuel d'opérateurs de l'entreprise.</>}>
					<EuiFormRow>{laboratory_users ? <EuiText>{operators_number}</EuiText> : <>—</>}</EuiFormRow>
				</EuiDescribedFormGroup>

				<EuiDescribedFormGroup title={<h3>Quota d'opérateurs</h3>} description={<>Nombre maximal d'opérateurs autorisés pour cette entreprise.</>}>
					<EuiFormRow>
						<EuiFieldNumber
							placeholder="0"
							value={users_quota}
							onChange={(e) => {
								setUsersQuota(parseInt(e.target.value));
								update_laboratory({
									users_quota: parseInt(e.target.value),
								});
							}}
						/>
					</EuiFormRow>
				</EuiDescribedFormGroup>
			</EuiPanel>

			<EuiSpacer />

			<EuiPanel hasBorder>
				<EuiDescribedFormGroup title={<h3>Affichage du logo</h3>} description={<>Définir si les administrateurs de l'entreprise peuvent en afficher le logo dans l'interface.</>}>
					<EuiFormRow>
						<EuiCheckbox
							id={"can_display_logo"}
							label=" "
							checked={can_display_logo}
							onChange={(e) => {
								setCanDisplayLogo(e.target.checked);
								update_laboratory({
									can_display_logo: e.target.checked,
								});
							}}
						/>
					</EuiFormRow>
				</EuiDescribedFormGroup>
			</EuiPanel>

			<EuiSpacer />

			<EuiPanel hasBorder>
				<EuiFormRow
					fullWidth
					label="Archiver l'entreprise"
					helpText={
						"Toutes les informations liées à cette entreprise seront supprimées de l'application après 30 jours. Cette action est réversible jusqu'à la date de suppression." +
						(is_archived
							? " Suppression prévue le " +
							  delete_on.toLocaleDateString("fr-FR", {
									timeZone: "Europe/Paris",
							  }) +
							  "."
							: "")
					}
				>
					{is_archived ? (
						<EuiButton
							color="text"
							fullWidth
							onClick={() => {
								unarchive_laboratory();
							}}
							isLoading={archiving || unarchiving}
						>
							Annuler l'archivage de l'entreprise {laboratory.name}
						</EuiButton>
					) : (
						<EuiButton
							color="danger"
							fullWidth
							onClick={() => {
								archive_laboratory();
							}}
							isLoading={archiving || unarchiving}
						>
							Archiver l'entreprise' {laboratory.name}
						</EuiButton>
					)}
				</EuiFormRow>
			</EuiPanel>

			<EuiSpacer />

			<EuiPanel hasBorder>
				<EuiFormRow
					fullWidth
					label="Supprimer l'entreprise immédiatement"
					helpText={"Cette action est irréversible. Toutes les informations liées à cette entreprise seront supprimées de l'application immédiatement."}
				>
					<EuiButton
						color="danger"
						fullWidth
						onClick={() => {
							archive_laboratory_now();
						}}
						isLoading={archiving_now}
					>
						Supprimer l'entreprise {laboratory.name} immédiatement
					</EuiButton>
				</EuiFormRow>
			</EuiPanel>
		</div>
	);
}

function Cgu({ path, laboratory, fetch_laboratory }: { path: string; laboratory: Laboratory; fetch_laboratory: () => void }) {
	const [laboratoryUsers, setLaboratoryUsers] = useState<Array<User> | undefined>(undefined);

	const [me, setMe] = useState<User | null>(null);

	const [fetch_me] = useApi("GET", "users/me", (err, user) => {
		if (!err) {
			setMe(user);
		}
	});

	const laboratory_id = laboratory._id;

	const [stats, setStats] = useState<Stat[]>([]);

	const [possible_operators, setPossibleOperators] = useState<{ label: string; value?: string }[]>([]);
	const [possible_formations, setPossibleFormations] = useState<{ label: string; value?: string }[]>([]);

	const [operators_filter, setOperatorsFilter] = useState<{ label: string; value?: string }[]>([]);
	const [formations_filter, setFormationsFilter] = useState<{ label: string; value?: string }[]>([]);

	const [start_date_filter, setStartDateFilter] = useState<moment.Moment | null>(null);
	const [end_date_filter, setEndDateFilter] = useState<moment.Moment | null>(null);

	const [status_filter, setStatusFilter] = useState<string>("all");

	const [page, setPage] = useState<number>(0);
	const [count, setCount] = useState<number>(0);

	const [fetch_ranges] = useApi("GET", `stats/laboratories/${laboratory_id}/ranges`, (err, { possible_formations, possible_operators }) => {
		if (err) {
			console.error(err);
			return;
		}
		setPossibleFormations([]);
		setPossibleOperators(
			possible_operators.map((operator: User) => ({
				label: `${operator.firstname} ${operator.lastname}`,
				_id: operator._id,
			}))
		);
	});

	const [fetch_stats] = useApi("GET", `stats/laboratories/${laboratory_id}`, (err, { stats, page, count }) => {
		if (err) {
			console.error(err);
			return;
		}

		setPage(page);
		setCount(count);

		setStats(stats);
	});

	useEffect(() => {
		if (!me) {
			fetch_me();
			return;
		}
		fetch_ranges();
		// { as: me._id }
		fetch_stats();
		// { as: me._id }
	}, [laboratory_id, path, me]);

	function build_body(body: any) {
		return {
			page,
			// as: me._id,
			// @ts-ignore
			selected_operators: operators_filter.map((v) => v._id),
			// @ts-ignore
			selected_formations: formations_filter.map((v) => v._id),
			start_date: start_date_filter?.format("YYYY-MM-DD"),
			end_date: end_date_filter?.format("YYYY-MM-DD"),
			selected_status: status_filter,
			...body,
		};
	}

	if (!me) return <EuiLoadingContent />;

	return (
		<>
			<EuiPanel hasBorder>
				<EuiDescribedFormGroup fullWidth title={<h3>Opérateurs</h3>} description={<>Laissez vide pour sélectionner tous les opérateurs de l'espace.</>}>
					<EuiFormRow fullWidth>
						<EuiComboBox
							placeholder="Sélectionnez un ou plusieurs opérateur(s)"
							options={possible_operators}
							// @ts-ignore
							onChange={(values) => {
								setOperatorsFilter(values);
								fetch_stats(
									build_body({
										selected_operators: values.map(
											// @ts-ignore
											(v) => v._id
										),
									})
								);
							}}
							selectedOptions={operators_filter}
							isClearable={true}
							fullWidth
						/>
					</EuiFormRow>
				</EuiDescribedFormGroup>

				{/* <pre>
					{JSON.stringify(stats, null, 2)}
				</pre> */}

				{/* <EuiDescribedFormGroup fullWidth title={<h3>Évaluations</h3>} description={<>Laissez vide pour sélectionner toutes les évaluations de l'espace.</>}>
					<EuiFormRow fullWidth>
						<EuiComboBox
							placeholder="Sélectionnez une ou plusieurs évaluation(s)"
							options={possible_formations}
							// @ts-ignore
							onChange={(values) => {
								setFormationsFilter(values);
								fetch_stats(
									build_body({
										selected_formations: values.map(
											// @ts-ignore
											(v) => v._id
										),
									})
								);
							}}
							selectedOptions={formations_filter}
							isClearable={true}
							fullWidth
						/>
					</EuiFormRow>
				</EuiDescribedFormGroup> */}

				{/* <EuiDescribedFormGroup fullWidth title={<h3>Statut</h3>} description={<></>}>
					<EuiFormRow fullWidth>
						<EuiSelect
							fullWidth
							options={[
								{ value: "all", text: "Toutes" },
								{
									value: "non_habilitated",
									text: "Non évalué",
								},
								{
									value: "expired",
									text: "Évaluation expirée",
								},
								{
									value: "validated",
									text: "Évaluation validée",
								},
								{
									value: "canceled",
									text: "Évaluation invalidée",
								},
								{
									value: "suspended",
									text: "Évaluation suspendue",
								},
							]}
							value={status_filter}
							onChange={(e) => {
								setStatusFilter(e.target.value);
								fetch_stats(
									build_body({
										selected_status: e.target.value,
									})
								);
							}}
						/>
					</EuiFormRow>
				</EuiDescribedFormGroup> */}
			</EuiPanel>

			<EuiSpacer size="xxl" />

			<div>
				{/* add pagination */}
				<EuiBasicTable
					noItemsMessage="Aucun résultat trouvé"
					items={stats}
					columns={[
						// {
						// field: 'instance',
						// name: "id"
						// },
						{
							field: "operator_name",
							name: "Opérateur",
							render: (name, stat) => <strong>{name}</strong>,
						},
						{
							field: "formation_name",
							name: "Évaluation",
						},
						{
							field: "allowed_to_start",
							name: "CGU Signées",
							render: (allowed_to_start) => (allowed_to_start ? "Oui" : "Non"),
						},
						{
							field: "allowed_to_start_on",
							name: "Date de signature",
							render: (allowed_to_start_on) => (allowed_to_start_on ? new Date(allowed_to_start_on).toLocaleDateString("fr-FR") : "—"),
						},
						// {
						// 	field: "allowed_to_start_on",
						// 	name: "Date de signature",
						// },
						// {
						// 	field: "start_date",
						// 	name: "Début / Fin d'évaluation",
						// 	// render: (start_date, stat) => <>{moment(start_date).format('DD/MM/YYYY')} — {moment(stat.end_date).format('DD/MM/YYYY')}</>
						// 	render: (start_date, stat) => {
						// 		switch (stat.status) {
						// 			case HABILITATION_STATUS.NOT_HABILITATED:
						// 				return <>—</>;
						// 			case HABILITATION_STATUS.EXPIRED: {
						// 				return (
						// 					<>
						// 						{moment(start_date).format("DD/MM/YYYY")} — {moment(stat.end_date).format("DD/MM/YYYY")}
						// 					</>
						// 				);
						// 			}
						// 			case HABILITATION_STATUS.HABILITATED: {
						// 				return (
						// 					<>
						// 						{moment(start_date).format("DD/MM/YYYY")} — {stat.end_date ? moment(stat.end_date).format("DD/MM/YYYY") : "..."}
						// 					</>
						// 				);
						// 			}
						// 		}
						// 	},
						// },
						// {
						// 	field: "status",
						// 	name: "Statut",
						// 	// render: (status) => {
						// 	// 	return <p>{status}</p>;
						// 	// },
						// 	render: (status) => {
						// 		switch (status) {
						// 			case HABILITATION_STATUS.NOT_HABILITATED:
						// 				return (
						// 					<EuiCallOut
						// 						style={{
						// 							padding: 4,
						// 							borderRadius: 4,
						// 						}}
						// 						color="danger"
						// 					>
						// 						Non évalué
						// 					</EuiCallOut>
						// 				);
						// 			case HABILITATION_STATUS.EXPIRED:
						// 				return (
						// 					<EuiCallOut
						// 						style={{
						// 							padding: 4,
						// 							borderRadius: 4,
						// 						}}
						// 						color="warning"
						// 					>
						// 						Évaluation expirée
						// 					</EuiCallOut>
						// 				);
						// 			case HABILITATION_STATUS.HABILITATED:
						// 				return (
						// 					<EuiCallOut
						// 						style={{
						// 							padding: 4,
						// 							borderRadius: 4,
						// 						}}
						// 						color="success"
						// 					>
						// 						Évaluation validée
						// 					</EuiCallOut>
						// 				);
						// 			case HABILITATION_STATUS.CANCELED:
						// 				return (
						// 					<EuiCallOut
						// 						style={{
						// 							padding: 4,
						// 							borderRadius: 4,
						// 							background: "#eee",
						// 						}}
						// 						color="danger"
						// 					>
						// 						Évaluation invalidée
						// 					</EuiCallOut>
						// 				);
						// 			case HABILITATION_STATUS.SUSPENDED:
						// 				return (
						// 					<EuiCallOut
						// 						style={{
						// 							padding: 4,
						// 							borderRadius: 4,
						// 						}}
						// 						color="danger"
						// 					>
						// 						Évaluation suspendue
						// 					</EuiCallOut>
						// 				);
						// 		}
						// 	},
						// },
						// {
						// 	field: "theoric_exam_score",
						// 	name: "Questionnaire",
						// 	render: (score, stat) => (
						// 		<>
						// 			{Math.floor(score)}/100 <br />({stat.theoric_exam_tries} essai
						// 			{stat.theoric_exam_tries > 1 ? "s" : ""})
						// 		</>
						// 	),
						// },
						// {
						// 	field: "practical_exam_score",
						// 	name: "Examen pratique",
						// 	render: (score, stat) => (
						// 		<>
						// 			{stat.is_awaiting_verification ? (
						// 				<>Correction en cours</>
						// 			) : (
						// 				<>
						// 					{Math.floor(score)}/100 <br />({stat.practical_exam_tries} essai
						// 					{stat.practical_exam_tries > 1 ? "s" : ""})
						// 				</>
						// 			)}
						// 		</>
						// 	),
						// },
						// {
						// 	field: "corrector_name",
						// 	name: "Évaluateur",
						// 	render: (name, stat) => <>{name}</>,
						// },
						// {
						// 	field: "verificator_name",
						// 	name: "Vérificateur",

						// 	render: (name, stat) => <>{name}</>,
						// },
						// {
						// 	field: "is_live",
						// 	name: "Évaluation présentielle",
						// 	render: (is_live) => (is_live ? "Oui" : "Non"),
						// },
						{
							field: "allowed_to_start_attestation",
							name: "Attestation",
							render: (attestation) => (attestation ? <EuiLink href={attestation}>Attestation</EuiLink> : "—"),
						},
						// {
						// 	field: "is_awaiting_verification",
						// 	name: "En attente de vérification",
						// 	render: (is_awaiting_verification) => (is_awaiting_verification ? "Oui" : "Non"),
						// }
					]}
					pagination={{
						pageIndex: page,
						pageSize: 25,
						totalItemCount: count,
						showPerPageOptions: false,
					}}
					onChange={({ page }) => {
						setPage(page.index);
						fetch_stats(
							build_body({
								page: page.index,
							})
						);
					}}
				/>
			</div>
		</>
	);
}

function DesignateReferent({ laboratory_id, fetch_laboratory }: { laboratory_id: string; fetch_laboratory: () => void }) {
	const [firstname, setFirstname] = useState("");
	const [lastname, setLastname] = useState("");

	const [email, setEmail] = useState("");

	const [error, setError] = useState("");

	const [possible_referents, setPossibleReferents] = useState<User[]>([]);

	const [designate_referent, loading] = useApi("POST", "users/referent", async (err, referent) => {
		if (!err) {
			await fetch_laboratory();
			window.location.reload();
		} else {
			setError(err.message);
		}
	});

	const [fetch_possible_referents] = useApi("GET", `users/possible_referents/${laboratory_id}`, (err, users) => {
		if (!err) {
			setPossibleReferents(users);
		}
	});

	const [assign_referent, _loading] = useApi("POST", `users/assign_referent/${laboratory_id}`, async (err, referent) => {
		if (!err) {
			await fetch_laboratory();
			window.location.reload();
			// window.location.reload()
		} else {
			setError(err.message);
		}
	});

	useEffect(() => {
		fetch_possible_referents();
	}, [laboratory_id]);

	const handleForm = async (e: FormEvent) => {
		e.preventDefault();

		const body = {
			firstname,
			lastname,
			email,
			laboratory: laboratory_id,
		};

		await designate_referent(body);
	};

	return (
		<>
			{/* {JSON.stringify(possible_referents)} */}

			{error && <EuiCallOut title={error} color="danger" iconType="alert"></EuiCallOut>}

			<EuiTitle size="xs">
				<h3>Désigner un administrateur existant</h3>
			</EuiTitle>

			<EuiSelect
				options={[
					{ value: null, text: "Aucun" },
					...possible_referents.map((user) => ({
						value: user._id as unknown as string,
						text: `${user.firstname} ${user.lastname}`,
					})),
				]}
				value={null}
				onChange={(e) => {
					assign_referent({ referent_id: e.target.value });
				}}
			/>

			<EuiSpacer size="l" />

			<EuiPanel color="subdued" hasBorder>
				<EuiTitle size="xs">
					<h3>Créer un nouvel administrateur</h3>
				</EuiTitle>

				<EuiForm component="form" onSubmit={handleForm}>
					<EuiFormRow label="Prénom">
						<EuiFieldText value={firstname} onChange={(e) => setFirstname(e.target.value)} />
					</EuiFormRow>

					<EuiFormRow label="Nom">
						<EuiFieldText value={lastname} onChange={(e) => setLastname(e.target.value)} />
					</EuiFormRow>

					<EuiFormRow label="Adresse email">
						<EuiFieldText type="email" value={email} onChange={(e) => setEmail(e.target.value)} />
					</EuiFormRow>

					<EuiFormRow>
						<EuiButton type="submit" fullWidth onClick={handleForm} isLoading={loading}>
							Désigner l'administrateur
						</EuiButton>
					</EuiFormRow>
				</EuiForm>
			</EuiPanel>
		</>
	);
}
