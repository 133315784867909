import { useEffect, useState } from "react"
import { API_PATH, useApi } from "../../../../effects/useApi"
import { Formation, FormationSession, FormationVersionInstance, Laboratory, User } from "../../../../types"
import { EuiSpacer, EuiButton, EuiCallOut, EuiTitle, EuiBasicTable, EuiSelect, EuiFlexGroup, EuiFlexItem, EuiPanel, EuiDescribedFormGroup, EuiFormRow, EuiComboBox, EuiTextArea, EuiFieldText, EuiBadge, EuiStat } from "@elastic/eui"

interface MembersListProps {
	path?: string
	formation_id?: string
	laboratory_id?: string
	formation: Formation
}

interface RolesInterface {
	is_admin: boolean,
	biologist_of: string[],
	biologist_verificator_of: string[],
	corrector_of: string[],
	formation_corrector_of: string[],
	formation_subreferent_of: string[],
	formation_qualitician_of: string[],
	member_of: string[],
	operator_of: string[],
	qualitician_of: string[],
	subreferent_of: string[],
	referent_of: string[],
	laboratory_subreferent_of: string[],
	laboratory_biologist_of: string[],
}

export default function MembersList({ path, formation_id, laboratory_id, formation }: MembersListProps) {

	const [operators, setOperators] = useState<User[] | null>(null)
	const [operators_count, setOperatorsCount] = useState<number>(0)

	const [page, setPage] = useState<number>(0)

	const [laboratory_members, setLaboratoryMembers] = useState<User[] | null>(null)
	const [laboratory_members_count, setLaboratoryMembersCount] = useState<number>(0)
	const [laboratory_members_page, setLaboratoryMembersPage] = useState<number>(0)

	const [formation_sessions, setFormationSessions] = useState<FormationSession[]>([])

	const [name_constraint, setNameFilter] = useState<string>("")
	const [selected_places, setPlaceFilter] = useState<string[]>([])
	const [selected_posts, setPostFilter] = useState<string[]>([])

	const [laboratory, setLaboratory] = useState<Laboratory | null>(null)


	const [roles, setRoles] = useState<RolesInterface | undefined>(undefined)
	const [fetch] = useApi('GET', 'users/roles', async (err, roles) => {
		setRoles(roles)
	})

	const [fetch_laboratory] = useApi('GET', `laboratories/${laboratory_id}`, (err, laboratory) => {
		if (err) {
			console.error(err)
			return
		}
		setLaboratory(laboratory)
	})

	const [fetch_sessions] = useApi('GET', `formations/${formation_id}/sessions`, (err, sessions) => {
		if (err) {
			console.error(err);
		} else {
			setFormationSessions(sessions);
		}
	})

	const [fetch_operators, operators_loading] = useApi('GET', `formations/${formation_id}/operators`, (err, { operators, count: operators_count, page }) => {
		if (err) {
			console.error(err)
			return
		}
		setOperators(operators)
		setOperatorsCount(operators_count)
		setPage(page)
	})

	const [fetch_laboratory_members, laboratory_members_loading] = useApi('GET', `laboratories/${laboratory_id}/filtered_members`, (err, { members, count, page }) => {
		if (err) {
			console.error(err)
			return
		}
		setLaboratoryMembers(members)
		setLaboratoryMembersCount(count)
		setLaboratoryMembersPage(page)
	})

	const [create_operator] = useApi('POST', `formations/${formation_id}/operators`, (err, operator) => {
		if (err) {
			console.error(err)
			return
		}
		fetch_operators({ page })
	})

	const [assignate_session] = useApi('POST', `formations/${formation_id}/sessions/operators`, (err, session) => {
		if (err) {
			console.error(err)
			return
		}
		fetch_sessions()
		fetch_operators({ page })
	})

	useEffect(() => {
		fetch_operators({ page })
		fetch_laboratory_members({})
		fetch_sessions()
		fetch_laboratory()
		fetch()
	}, [formation_id])

	let columns = [{
		field: 'operator.firstname',
		name: 'Nom',
		render: (name, instance) => <>{instance?.operator?.firstname} {instance?.operator?.lastname}</>
	}, {
		field: 'operator.email',
		name: 'Email'
	}, {
		field: 'operator.job_title',
		name: 'Champ personnalisé 1'
	}, {
		field: 'operator.places',
		name: 'Lieu d\'exercice',
		render: (places, user) => <><div style={{ display: 'inline' }}>
			{places.map(place => <EuiBadge color="hollow">{place}</EuiBadge>)}
		</div></>
	}, {
		field: 'operator.posts',
		name: 'Poste',
		render: (posts, user) => <><div style={{ display: 'inline' }}>
			{posts.map(post => <EuiBadge color="hollow">{post}</EuiBadge>)}
		</div></>
	}, {
		field: 'valid_until',
		name: 'Actions',
		render: (_, instance) => <ActionButton instance={instance} formation={formation} fetch_operators={fetch_operators} />
	}]

	// const search_filter = (user: User) => {
	// 	if (name_filter) {
	// 		if (!(user.firstname + " " + user.lastname).toLowerCase().includes(name_filter.toLowerCase())) {
	// 			return false
	// 		}
	// 	}

	// 	if (place_filter) {
	// 		if (!user.places.includes(place_filter)) {
	// 			return false
	// 		}
	// 	}

	// 	if (post_filter) {
	// 		if (!user.posts.includes(post_filter)) {
	// 			return false
	// 		}
	// 	}
	// 	return true
	// }


	function build_filter(partial: any) {

		return {
			type: 'all',
			page,
			selected_places,
			selected_posts,
			name_constraint,
			...partial
		}

	}

	if (formation.is_live) {
		columns.push({
			field: 'session',
			name: 'Session',
			render: (session, instance) => <>
				{/* {instance.session?.date ? new Date(instance.session?.date).toLocaleDateString() : <></>} */}

				<EuiSelect
					options={[...(formation_sessions || []).map(session => ({
						value: session._id,
						text: new Date(session.date).toLocaleDateString('fr-FR', { timeZone: 'Europe/Paris' })
					})), {
						value: '',
						text: 'Aucune'
					}]}
					value={instance.session?._id as unknown as string || ''}
					onChange={(e) => { assignate_session({ formation_version_id: instance._id, session_id: e.target.value }) }}
				/>


			</>
		})
	}

	if (!laboratory) {
		return <>Chargement...</>
	}

	const ongoing_habilitations = (operators as unknown as FormationVersionInstance[] || []).filter((instance: FormationVersionInstance) => !instance.has_been_finished).length
	const finished_habilitations = (operators as unknown as FormationVersionInstance[] || []).filter(instance => instance.is_validated).length

	return <>
		<EuiFlexGroup>
			<EuiFlexItem>
				<EuiTitle>
					<h2>Opérateurs inscrits à l'évaluations</h2>
				</EuiTitle>
			</EuiFlexItem>

			<EuiFlexItem grow={false}>

			</EuiFlexItem>
		</EuiFlexGroup>
		<EuiSpacer size="m" />

		{!formation.head_version ? <>
			<EuiCallOut title="Vous ne pas encore ajouter d'opérateurs" color="warning" iconType="help">
				<p>Vous devez publier votre formation avant de pouvoir y ajouter des opérateurs.</p>
			</EuiCallOut>
			<EuiSpacer size="l" />
		</> : <>

			<EuiSpacer size="m" />

			<EuiFlexGroup>

				<EuiFlexItem>
					<EuiPanel hasBorder>
						<EuiStat
							title={(operators || []).length}
							description="Opérateurs"
						/>
					</EuiPanel>
				</EuiFlexItem>

				<EuiFlexItem>
					<EuiPanel hasBorder>
						<EuiStat
							title={ongoing_habilitations}
							description="Évaluations en cours"
						/>
					</EuiPanel>
				</EuiFlexItem>

				<EuiFlexItem>
					<EuiPanel hasBorder>
						<EuiStat
							title={finished_habilitations}
							description="Opérateurs évalués"
						/>
					</EuiPanel>
				</EuiFlexItem>

				<EuiFlexItem>
					<EuiPanel hasBorder>
						<EuiStat
							title={((Math.floor((finished_habilitations / (operators || []).filter(instance => true).length) * 1000) / 10) || "—") + "%"}
							description="Taux de complétion"
						/>
					</EuiPanel>
				</EuiFlexItem>


			</EuiFlexGroup>

			<EuiSpacer size="m" />

			<EuiBasicTable
			noItemsMessage="Aucun résultat trouvé"
				items={operators || []}
				columns={columns}

				pagination={{
					pageIndex: page,
					pageSize: 25,
					totalItemCount: operators_count,
					showPerPageOptions: false,
				}}
				onChange={({ page }) => {
					setPage(page.index)
					fetch_operators({ page: page.index })
				}}
				loading={operators_loading}
			/>

			<EuiSpacer size="l" />

		</>}

		{/* <pre>{JSON.stringify(roles, null, 2)}</pre> */}

		{((roles?.referent_of as unknown as Laboratory[])?.map(r => r._id).includes(laboratory_id) ||
			(roles?.laboratory_subreferent_of as unknown as Laboratory[])?.map(r => r._id).includes(laboratory_id) ||
			(roles?.formation_qualitician_of as unknown as Formation[])?.map(r => r._id).includes(formation_id)) && <>


				<EuiTitle>
					<h2>Membres</h2>
				</EuiTitle>
				<EuiSpacer size="m" />

				<EuiPanel hasBorder>
					<EuiDescribedFormGroup
						fullWidth
						title={<h3>Nom de l'opérateur</h3>}
					>
						<EuiFieldText fullWidth name="name" placeholder="Filtrer par nom" value={name_constraint} onChange={e => {
							setNameFilter(e.target.value)

							fetch_laboratory_members({
								name_constraint: e.target.value,
							})
						}} />
					</EuiDescribedFormGroup >

					<EuiDescribedFormGroup
						fullWidth
						title={<h3>Lieu d'exercice</h3>}
						description={<>Filtrer par lieu d'exercice de l'opérateur</>}
					>
						<EuiSelect
							options={[{ label: "Tous", text: "" }, ...(laboratory.possible_places || []).map((place) => {
								return {
									label: place,
									text: place
								}
							})]}
							fullWidth
							value={selected_places[0] || ''}
							onChange={(e) => {
								setPlaceFilter([e.target.value])

								fetch_laboratory_members({
									selected_places: [e.target.value],
								})
							}}
						/>
					</EuiDescribedFormGroup >

					<EuiDescribedFormGroup
						fullWidth
						title={<h3>Poste</h3>}
						description={<>Filtrer par poste de l'opérateur</>}
					>
						<EuiSelect
							options={[{ label: "Tous", text: "" }, ...(laboratory.possible_posts || []).map((post) => {
								return {
									label: post,
									text: post
								}
							})]}
							fullWidth
							value={selected_posts[0] || ''}
							onChange={(e) => {
								setPostFilter([e.target.value])

								fetch_laboratory_members(build_filter({
									selected_posts: [e.target.value],
								}))
							}}
						/>
					</EuiDescribedFormGroup >
				</EuiPanel>

				<EuiSpacer size="m" />

				<EuiBasicTable noItemsMessage="Aucun résultat trouvé" items={(laboratory_members || [])
					// .filter(search_filter)
					.filter(user => !operators?.map(op => op.operator._id).includes(user._id))
				} columns={[{
					field: 'firstname',
					name: 'Nom',
					render: (name, user) => <>{user.firstname} {user.lastname}</>
				}, {
					field: 'email',
					name: 'Email'
				}, {
					field: 'job_title',
					name: 'Champ personnalisé 1'
				}, {
					field: 'rpps_number',
					name: 'Champ personnalisé 2'
				}, {
					field: 'places',
					name: 'Lieu d\'exercice',
					render: (places, user) => <><div style={{ display: 'inline' }}>
						{(places || []).map(place => <EuiBadge color="hollow">{place}</EuiBadge>)}
					</div></>
				}, {
					field: 'posts',
					name: 'Poste',
					render: (posts, user) => <><div style={{ display: 'inline' }}>
						{(posts || []).map(post => <EuiBadge color="hollow">{post}</EuiBadge>)}
					</div></>
				}, {
					name: 'Actions',
					render: (user) => formation.head_version ? <EuiButton size="s" isDisabled={!user.is_active} onClick={() => create_operator({ user_id: user._id })}>Ajouter à l'évaluations</EuiButton> : <></>
				}]}
					pagination={{
						pageIndex: laboratory_members_page,
						pageSize: 25,
						totalItemCount: laboratory_members_count,
						showPerPageOptions: false,
					}}
					onChange={({ page }) => {
						// setLaboratoryMembersPage(page.index)
						fetch_laboratory_members({ page: page.index })
					}}
					loading={laboratory_members_loading}

				/>

			</>}
	</>
}

function ActionButton({ instance, formation, fetch_operators }: { instance: FormationVersionInstance, formation: Formation, fetch_operators: () => void }) {

	const [create_operator, creating] = useApi('POST', `formations/${formation._id}/operators`, (err, operator) => {
		if (err) {
			console.error(err)
			return
		}
		fetch_operators()
	})

	const [cancel_instance, canceling] = useApi('POST', `formation_instances/${instance._id}/cancel`, (err, operator) => {
		if (err) { console.error(err); return }
		fetch_operators()
	})

	const [delete_instance, deleting] = useApi('DELETE', `formation_instances/${instance._id}`, (err, operator) => {
		if (err) { console.error(err); return }
		fetch_operators()
	})

	return new Date(instance.valid_until).getTime() - (new Date()).getTime() < 0 ? <EuiButton
		color="primary"
		size="s"
		isLoading={creating}
		onClick={() => { create_operator({ user_id: instance.operator._id }) }}
	>
		Réassigner l'opérateur
	</EuiButton>

		: !formation.is_live ? (instance.is_started ?

			<EuiButton isLoading={canceling} color="warning" size="s" onClick={() => { cancel_instance() }}>
				Invalider l'évaluation
			</EuiButton > :

			<EuiButton isLoading={deleting} color="danger" size="s" onClick={() => { delete_instance() }}>
				Supprimer l'évaluation
			</EuiButton>) : (instance.is_allowed_to_start ?
				<EuiButton isLoading={canceling} color="warning" size="s" onClick={() => { cancel_instance() }}>
					Invalider l'évaluation
				</EuiButton > :

				<EuiButton isLoading={deleting} color="danger" size="s" onClick={() => { delete_instance() }}>
					Supprimer l'évaluation
				</EuiButton>)
}