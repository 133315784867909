import {
	EuiButton,
	EuiCallOut,
	EuiEmptyPrompt,
	EuiFlexGroup,
	EuiFlexItem,
	EuiMarkdownFormat,
	EuiNotificationEvent,
	EuiPageTemplate,
	EuiPanel,
	EuiSpacer,
	EuiTab,
	EuiTabs,
	EuiText,
	EuiTitle,
} from "@elastic/eui";
import { Link, navigate, Router, useMatch } from "@reach/router";
import TimeAgo from "javascript-time-ago";
import { useEffect, useState } from "react";
import { useApi } from "../effects/useApi";
import {
	HabilitationCanceled,
	MissingOperator,
	Notification,
	RehabilitationRequired,
	SpecialCommunication,
	SpecialCommunicationNotif,
} from "../types";
import fr from "javascript-time-ago/locale/fr";
import RichText from "../components/RichText";
import Comment from "../components/Comment";

TimeAgo.addLocale(fr);

const timeAgo = new TimeAgo("fr-FR");

function get_type_label(type: string) {
	switch (type) {
		case "special_communication":
			return "Nouvelle communication";
		case "quizz_failed":
			return "Échec questionnaire";
		case "new_correction":
			return "Nouvelle correction";
		case "formation_created":
			return "Formation créée";
		case "formation":
			return "Formation terminée";
		case "practical_exam_corrected":
			return "Épreuve pratique corrigée";
		case "account_creation":
			return "Compte créé";
		case "operator_missing":
			return "Opérateur manquant";
		case "awaiting_verification":
			return "En attente de vérification";
		case "formation_finished":
			return "Formation terminée";
		case "rehabilitation_required":
			return "Réhabilitation nécessaire";
		case "habilitation_canceled":
			return "Évaluation à réassigner";
		default:
			return "Notification";
	}
}

function is_task(type: string, notification?: Notification) {
	if (
		type === "operator_missing" ||
		type === "rehabilitation_required" ||
		type === "special_communication" ||
		type === "habilitation_canceled" ||
		type === "subreferent_recycling_alert" ||
		type === "subreferent_recycling"
	) {
		return true;
	}

	if (
		type === "practical_exam_corrected" &&
		notification &&
		notification.description &&
		notification.description.indexOf("reposter une vidéo") > -1
	)
		return true;

	return false;
}

export default function Notifications({
	path,
	refetch_notifs,
}: {
	path: string;
	refetch_notifs: () => void;
}) {
	const [notifications, setNotifications] = useState<Notification[]>([]);

	const [tab, setTab] = useState<"inbox" | "archived">("inbox");

	const [fetch_inbox] = useApi(
		"GET",
		"notifications",
		async (err, _notifications) => {
			if (err) return;
			setNotifications(
				_notifications.sort((a, b) => b.createdAt - a.createdAt)
			);
			refetch_notifs();
		}
	);

	const [fetch_archived] = useApi(
		"GET",
		"notifications/include_archived",
		async (err, _notifications) => {
			if (err) return;
			setNotifications(_notifications);
			refetch_notifs();
		}
	);

	const { opened_notification_id } = useMatch(
		"/notifications/:opened_notification_id"
	) || { opened_notification_id: undefined };

	useEffect(() => {
		if (tab === "inbox") fetch_inbox();
		else fetch_archived();
	}, []);

	return (
		<EuiPageTemplate>
			<EuiPageTemplate.Sidebar
				minWidth={400}
				style={{ overflow: "scroll", maxHeight: "100vh" }}
				paddingSize="s"
			>
				<EuiTabs size="s">
					<EuiTab
						isSelected={tab === "inbox"}
						onClick={() => {
							setTab("inbox");
							fetch_inbox();
						}}
					>
						Boîte de réception
					</EuiTab>

					<EuiTab
						isSelected={tab === "archived"}
						onClick={() => {
							setTab("archived");
							fetch_archived();
						}}
					>
						Archive
					</EuiTab>
				</EuiTabs>

				<EuiSpacer size="m" />

				{/* @ts-ignore */}
				{notifications
					.filter((notif) => notif.title)
					.sort(
						(a, b) =>
							// @ts-ignore
							new Date(b.createdAt).getTime() -
							// @ts-ignore
							new Date(a.createdAt).getTime()
					)
					.map((notification) => (
						<EuiPanel
							paddingSize="none"
							style={{ marginBottom: 10, cursor: "pointer" }}
							color={
								opened_notification_id === notification._id
									? "plain"
									: "subdued"
							}
							hasShadow={
								opened_notification_id === notification._id
							}
						>
							<EuiNotificationEvent
								id={notification._id as string}
								type={
									is_task(notification.type, notification)
										? "Tâche à effectuer"
										: get_type_label(notification.type)
								}
								title={
									notification.title.substring(0, 50) + "..."
								}
								badgeColor={
									is_task(notification.type, notification) &&
									!notification.is_seen
										? "accent"
										: "default"
								}
								// primaryAction="Ouvrir"
								messages={[
									JSON.stringify(
										notification.description
											.replace(
												/\n|\t|\(.*\)|\[|\]|\*/g,
												" "
											)
											.substring(0, 100) + "..."
									),
								]}
								isRead={notification.is_seen}
								// onRead={onRead}
								// onOpenContextMenu={onOpenContextMenu}
								onClickPrimaryAction={() => {
									navigate(
										"/notifications/" + notification._id
									);
								}}
								onClickTitle={() => {
									navigate(
										"/notifications/" + notification._id
									);
								}}
								onClick={() => {
									navigate(
										"/notifications/" + notification._id
									);
								}}
								// @ts-ignore
								time={`${timeAgo.format(
									/* @ts-ignore */
									new Date(notification.createdAt)
								)}`}
							/>
						</EuiPanel>
					))}

				{notifications.filter((notif) => notif.title).length === 0 && (
					<EuiEmptyPrompt
						titleSize="s"
						title={<h4>Aucune notification</h4>}
						body={
							<EuiText>
								Vous n'avez aucune notification à afficher.
							</EuiText>
						}
					/>
				)}
			</EuiPageTemplate.Sidebar>

			<EuiPageTemplate.Section>
				<Router primary={false}>
					<OpenNotification
						path=":notification_id"
						refetch={() => {
							if (tab === "inbox") fetch_inbox();
							else fetch_archived();
						}}
					/>
					<NoNotificationSelected path="*" />
				</Router>
			</EuiPageTemplate.Section>
		</EuiPageTemplate>
	);
}

const OpenNotification = ({
	path,
	notification_id,
	refetch,
}: {
	path: string;
	notification_id?: string;
	refetch: () => void;
}) => {
	const [notification, setNotification] = useState<Notification | null>(null);

	const [fetch_notification] = useApi(
		"GET",
		`notifications/${notification_id}`,
		async (err, notification) => {
			if (err) return;
			setNotification(notification);

			if (
				!is_task(notification.type, notification) &&
				!notification.is_seen
			) {
				mark_as_seen();
			}
		}
	);

	const [mark_as_seen] = useApi(
		"POST",
		`notifications/${notification_id}/seen`,
		async (err, notification) => {
			if (err) return;
			refetch();
		}
	);

	const [mark_as_cleared, clearing] = useApi(
		"POST",
		`notifications/${notification_id}/clear`,
		async (err, notification) => {
			if (err) return;
			refetch();
			navigate("/notifications");
		}
	);

	const [sign, signing] = useApi(
		"POST",
		`communications/sign`,
		async (err, communications) => {
			await mark_as_seen();
			await refetch();

			fetch_notification();

			// window.location.reload()
		}
	);

	useEffect(() => {
		fetch_notification();

		// mark_as_seen()
	}, [notification_id]);

	if (!notification) return <EuiEmptyPrompt title={<h2>Loading...</h2>} />;

	const communication = (notification as unknown as SpecialCommunicationNotif)
		.special_communication as unknown as SpecialCommunication;

	let habilitation_is_expired = false;

	try {
		// @ts-ignore
		habilitation_is_expired =
			notification.type === "rehabilitation_required" &&
			/* @ts-ignore */
			new Date(notification?.formation_instance?.valid_until).getTime() -
				new Date().getTime() <
				0;
	} catch (e) {}

	return (
		<>
			{/* <EuiPanel> */}

			<EuiPanel hasBorder paddingSize="s">
				<EuiFlexGroup gutterSize="none">
					<EuiFlexItem style={{ placeContent: "center" }}>
						{/* <EuiTitle size="s"> */}
						{/* <h3>{notification.title}</h3> */}
						{/* </EuiTistle> */}
						{/* @ts-ignore */}
						<EuiText>
							Reçue le{" "}
							{new Date(
								/* @ts-ignore */
								notification.createdAt
							).toLocaleDateString("fr-FR", {
								timeZone: "Europe/Paris",
							})}{" "}
							à{" "}
							{new Date(
								/* @ts-ignore */
								notification.createdAt
							).toLocaleTimeString("fr-FR", {
								timeZone: "Europe/Paris",
							})}
						</EuiText>
					</EuiFlexItem>

					<EuiFlexItem grow={false}>
						<EuiButton
							color="primary"
							onClick={() => {
								mark_as_cleared();
							}}
							disabled={
								(notification.is_cleared ||
									(is_task(notification.type, notification) &&
										!notification.is_seen)) &&
								!habilitation_is_expired
							}
							isLoading={clearing}
						>
							Archiver
						</EuiButton>
					</EuiFlexItem>
				</EuiFlexGroup>
			</EuiPanel>

			<EuiSpacer size="m" />

			<EuiTitle size="l">
				<h2>{notification.title}</h2>
			</EuiTitle>

			<EuiSpacer size="m" />

			<EuiText>
				{notification.description.indexOf("reposter une vidéo") > -1 ? (
					<>
						<Comment text={notification.description} />
					</>
				) : (
					<EuiMarkdownFormat>
						{notification.description}
					</EuiMarkdownFormat>
				)}
			</EuiText>

			{!notification.is_cleared &&
				notification.type === "special_communication" &&
				communication && (
					<>
						<EuiSpacer size="m" />

						<EuiPanel
							hasBorder
							key={communication._id as unknown as string}
							color={
								communication?.is_signed ? "subdued" : "plain"
							}
						>
							<EuiFlexGroup>
								<EuiFlexItem>
									<EuiTitle>
										<h2>{communication.header}</h2>
									</EuiTitle>
									<EuiSpacer size="m" />

									{/* <EuiMarkdownFormat>{communication.description}</EuiMarkdownFormat> */}

									<RichText
										read_only
										value={communication.description}
										onChange={(value) => {}}
										page_id={"special_comm"}
									/>
								</EuiFlexItem>

								<EuiFlexItem grow={false}>
									{!communication.is_signed ? (
										<EuiButton
											onClick={() =>
												sign({
													communication_id:
														communication._id as unknown as string,
													formation_instance_id: (
														notification as unknown as SpecialCommunicationNotif
													).formation_instance,
													formation_version:
														communication.formation_version,
												})
											}
											disabled={signing}
										>
											Signer
										</EuiButton>
									) : (
										<>
											<i>
												Signée{" "}
												{timeAgo.format(
													new Date(
														communication.signed_on
													)
												)}
											</i>
										</>
									)}
								</EuiFlexItem>
							</EuiFlexGroup>
						</EuiPanel>
					</>
				)}

			{!notification.is_cleared &&
				notification.type === "operator_missing" && (
					<>
						<EuiSpacer size="m" />

						<EuiPanel hasBorder paddingSize="s">
							<EuiFlexGroup gutterSize="none">
								<EuiFlexItem style={{ placeContent: "center" }}>
									<EuiText>Tâche à effectuer</EuiText>
								</EuiFlexItem>

								<EuiFlexItem grow={false}>
									<Link
										to={`/formations/${
											(notification as MissingOperator)
												.formation
										}/members`}
									>
										<EuiButton>
											Réassigner l'opérateur
										</EuiButton>
									</Link>
								</EuiFlexItem>
							</EuiFlexGroup>
						</EuiPanel>
					</>
				)}

			{!notification.is_cleared &&
				notification.type === "practical_exam_corrected" &&
				notification.description &&
				notification.description.indexOf("reposter une vidéo") > -1 && (
					<>
						<EuiSpacer size="m" />

						<EuiPanel hasBorder paddingSize="s">
							<EuiFlexGroup gutterSize="none">
								<EuiFlexItem style={{ placeContent: "center" }}>
									<EuiText>Tâche à effectuer</EuiText>
								</EuiFlexItem>

								<EuiFlexItem grow={false}>
									<Link
										to={`/formation_instances/${
											(
												notification as RehabilitationRequired
											).formation_instance._id
										}`}
									>
										<EuiButton>
											Reposter une vidéo
										</EuiButton>
									</Link>
								</EuiFlexItem>
							</EuiFlexGroup>
						</EuiPanel>
					</>
				)}

			{!notification.is_cleared &&
				notification.type === "habilitation_canceled" && (
					<>
						<EuiSpacer size="m" />

						<EuiPanel hasBorder paddingSize="s">
							<EuiFlexGroup gutterSize="s">
								<EuiFlexItem style={{ placeContent: "center" }}>
									<EuiText>Tâche à effectuer</EuiText>
								</EuiFlexItem>

								<EuiFlexItem grow={false}>
									<EuiButton
										color="danger"
										onClick={() => {
											mark_as_seen();
											mark_as_cleared();
										}}
									>
										Ne pas réaffecter l'opérateur
									</EuiButton>
								</EuiFlexItem>

								<EuiFlexItem grow={false}>
									{(notification as HabilitationCanceled)
										.as === "referent" ? (
										<>
											<Link
												to={`/formations/${
													(
														notification as HabilitationCanceled
													).formation._id
												}/members`}
											>
												<EuiButton>
													Réaffecter l'opérateur
												</EuiButton>
											</Link>
										</>
									) : (
										<>
											<Link to={`/`}>
												<EuiButton>
													Réaffecter l'opérateur
												</EuiButton>
											</Link>
										</>
									)}
								</EuiFlexItem>
							</EuiFlexGroup>
						</EuiPanel>
					</>
				)}

			{!notification.is_seen &&
				!notification.is_cleared &&
				notification.type === "rehabilitation_required" && (
					<>
						<EuiSpacer size="m" />

						{new Date(
							/* @ts-ignore */
							notification?.formation_instance?.valid_until
						).getTime() -
							new Date().getTime() <
						0 ? (
							<>
								<EuiCallOut
									color="danger"
									title="Évaluation expiré"
								>
									<p>
										L'évaluation est expirée depuis le{" "}
										{new Date(
											/* @ts-ignore */
											notification?.formation_instance?.valid_until
										).toLocaleDateString("fr-FR", {
											timeZone: "Europe/Paris",
										})}
										. Vous ne pouvez plus accéder au module
										de questionnaire intermédiaire.
									</p>
								</EuiCallOut>
							</>
						) : (
							<>
								<EuiPanel hasBorder paddingSize="s">
									<EuiFlexGroup gutterSize="none">
										<EuiFlexItem
											style={{ placeContent: "center" }}
										>
											<EuiText>Tâche à effectuer</EuiText>
										</EuiFlexItem>

										<EuiFlexItem grow={false}>
											<Link
												to={`/formation_instances/${
													(
														notification as RehabilitationRequired
													).formation_instance._id
												}`}
											>
												<EuiButton>
													Module de questionnaire intermédiaire.
												</EuiButton>
											</Link>
										</EuiFlexItem>
									</EuiFlexGroup>
								</EuiPanel>
							</>
						)}
					</>
				)}

			{!notification.is_cleared &&
				notification.type === "subreferent_recycling" && (
					<>
						<EuiSpacer size="m" />

						<EuiPanel hasBorder paddingSize="s">
							<EuiFlexGroup gutterSize="none">
								<EuiFlexItem style={{ placeContent: "center" }}>
									<EuiText>Tâche à effectuer</EuiText>
								</EuiFlexItem>

								<EuiFlexItem grow={false}>
									<EuiButton
										color="primary"
										onClick={() => {
											mark_as_seen();
											window.location.reload();
										}}
									>
										J'ai informé l'opérateur
									</EuiButton>
								</EuiFlexItem>
							</EuiFlexGroup>
						</EuiPanel>
					</>
				)}

			{!notification.is_cleared &&
				notification.type === "subreferent_recycling_alert" && (
					<>
						<EuiSpacer size="m" />

						<EuiPanel hasBorder paddingSize="s">
							<EuiFlexGroup gutterSize="none">
								<EuiFlexItem style={{ placeContent: "center" }}>
									<EuiText>Tâche à effectuer</EuiText>
								</EuiFlexItem>

								<EuiFlexItem grow={false}>
									<EuiButton
										color="primary"
										onClick={() => {
											mark_as_seen();
											window.location.reload();
										}}
									>
										J'ai informé l'opérateur
									</EuiButton>
								</EuiFlexItem>
							</EuiFlexGroup>
						</EuiPanel>
					</>
				)}
		</>
	);
};

const NoNotificationSelected = ({ path }: { path: string }) => {
	return (
		<EuiText>
			<EuiTitle size="l">
				<h1>Notifications</h1>
			</EuiTitle>

			<EuiEmptyPrompt
				iconType="bell"
				title={<h2>Choisissez une notification</h2>}
				body={
					<p>
						Toutes les notifications sont également envoyées par
						email. Si vous ne les recevez pas, vérifiez vos réglages
						utilisateur.
					</p>
				}
			/>
		</EuiText>
	);
};
