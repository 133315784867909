import { useEffect, useState } from "react";
import { useApi } from "../../../../../effects/useApi";
import { Page } from "../../../../../types";

import { EuiLoadingContent } from "@elastic/eui";
import _ from 'lodash';
import RichText, { serialize } from "../../../../../components/RichText";

const update = _.throttle((cb, st) => {
	cb({ components: st })
}, 150)


export default function PageEditor({ page_id, formation_id, path, setDirty }: { page_id?: string, formation_id: string, path?: string, setDirty: (value: boolean) => void }) {

	const [page, setPage] = useState<Page | null>(null)

	const [state, setState] = useState()

	const [fetch] = useApi('GET', `formations/${formation_id}/pages/${page_id}`, (err, _page) => {
		if (err) {
			console.error(err)
			return
		}
		setPage(_page)
		setState(_page.components)
	})

	const [update_page] = useApi('POST', `formations/${formation_id}/pages/${page_id}`, (err, page) => {
		if (err) {
			console.error(err)
			return
		}
		setPage(page)

		setDirty(true)
	})


	useEffect(() => {
		fetch()
		// window.location.reload()
	}, [page_id])


	if (!page) return <>
		<EuiLoadingContent lines={5} />
	</>


	return <div className="mb-4 border">
		<RichText value={state} onChange={(value) => { setState(value); update(update_page, value) }} page_id={page_id} />
	</div>
}
