import { EuiButton, EuiCallOut, EuiComboBox, EuiConfirmModal, EuiEmptyPrompt, EuiFieldText, EuiFlexGroup, EuiFlexItem, EuiFlyout, EuiFlyoutBody, EuiFlyoutHeader, EuiFormRow, EuiIcon, EuiKeyPadMenu, EuiKeyPadMenuItem, EuiPanel, EuiSpacer, EuiTab, EuiTabs, EuiTitle } from '@elastic/eui';
import { useEffect, useState } from "react";
import { API_PATH, useApi } from '../../effects/useApi';
import { Formation, Laboratory, Media, User } from '../../types';

export default function UserFlyout({ user_id, laboratory_id, onClose }: { user_id: string, laboratory_id: string, onClose?: () => void }) {

    const [tab, setTab] = useState('profile')

    const [me, setMe] = useState<User | null>(null)

    const [fetch_me] = useApi('GET', 'users/me', async (err, me) => {
        setMe(me)
    })

    const [fetch_roles] = useApi('GET', 'users/roles', async (err, roles) => {
        setLaboratory(roles.member_of.find(l => l._id === laboratory_id))
    })

    const [laboratory, setLaboratory] = useState<Laboratory | null>(null)


    const [user_certificates, setUserCertificates] = useState<Media[]>([])

    // const [laboratory_members, setLaboratoryMembers] = useState<User[]>([])

    const [supervised_users, setSupervisedUsers] = useState<String[]>([])

    const [is_only_operator, setIsOnlyOperator] = useState(false)
    const [is_user_referent, setIsUserReferent] = useState(false)

    const [firstname, setFirstname] = useState<string>('')
    const [lastname, setLastname] = useState<string>('')
    const [username, setUsername] = useState<string>('')
    const [email, setEmail] = useState<string>('')
    const [phone, setPhone] = useState<string>('')

    const [is_inactive, setIsInactive] = useState<boolean>(false)
    const [is_archived, setIsArchived] = useState<boolean>(false)

    const [biologist_of, setBiologistOf] = useState<Formation[]>([])
    const [biologist_verificator_of, setBiologistVerificatorOf] = useState<Formation[]>([])
    const [formation_qualitician_of, setFormationQualiticianOf] = useState<Formation[]>([])
    const [formation_corrector_of, setFormationCorrectorOf] = useState<Formation[]>([])

    const [delete_on, setDeleteOn] = useState<Date | null>(null)

    // const [place, setPlace] = useState<string>('')
    // const [post, setPost] = useState<string>('')

    const [places, setPlaces] = useState<string[]>([])
    const [posts, setPosts] = useState<string[]>([])

    const [job_title, setJobTitle] = useState<string>('')

    const [rpps_number, setRppsNumber] = useState<string>('')

    const [is_referent, setIsReferent] = useState<boolean>(false)
    const [is_qualitician, setIsQualitician] = useState<boolean>(false)
    const [is_corrector, setIsCorrector] = useState<boolean>(false)
    const [is_verificator, setIsVerificator] = useState<boolean>(false)
    const [is_subreferent, setIsSubreferent] = useState<boolean>(false)
    const [is_biologist, setIsBiologist] = useState<boolean>(false)

    const [subreferent, setSubreferent] = useState<User | null>(null)

    const [fetch_certificates] = useApi('get', 'certificates/user/' + user_id, (err, certificates: Media[]) => {
        if (err) return console.error(err)

        setUserCertificates(certificates)
    })

    const [fetch_supervised_users] = useApi('get', `users/supervised_users/${user_id}`, (err, users: string[]) => {
        if (err) return console.error(err)

        setSupervisedUsers(users)
    })

    const [fetch_user] = useApi('get', `users/${user_id}`, (err, user: User) => {
        if (err) return console.error(err)

        setFirstname(user.firstname)
        setLastname(user.lastname)
        setEmail(user.email)
        setPhone(user.phone)
        setJobTitle(user.job_title)
        setRppsNumber(user.rpps_number)
        setUsername(user.username)

        setIsInactive(!user.is_active)
        setIsArchived(user.is_archived)

        if (user.delete_on) setDeleteOn(new Date(user.delete_on))

        // setPlace(user.place)
        // setPost(user.post)
        setPlaces(user.places)
        setPosts(user.posts)

        setIsReferent((user.referent_of as unknown as string[]).includes(laboratory_id))
        setIsQualitician((user.qualitician_of as unknown as string[]).includes(laboratory_id))
        setIsCorrector((user.corrector_of as unknown as string[]).includes(laboratory_id))
        setIsVerificator((user.laboratory_verificator_of as unknown as string[]).includes(laboratory_id))
        setIsSubreferent((user.laboratory_subreferent_of as unknown as string[]).includes(laboratory_id))
        setIsBiologist((user.laboratory_biologist_of as unknown as string[]).includes(laboratory_id))

        setBiologistOf(user.biologist_of as unknown as Formation[])
        setBiologistVerificatorOf(user.biologist_verificator_of as unknown as Formation[])
        setFormationQualiticianOf(user.formation_qualitician_of as unknown as Formation[])
        setFormationCorrectorOf(user.formation_corrector_of as unknown as Formation[])

        if (user.subreferent) setSubreferent(user.subreferent)

        if (
            user.referent_of.length === 0 &&
            user.referent_of.length === 0 &&
            user.biologist_of.length === 0 &&
            user.biologist_verificator_of.length === 0 &&
            user.corrector_of.length === 0 &&
            user.formation_corrector_of.length === 0 &&
            user.formation_subreferent_of.length === 0 &&
            user.formation_qualitician_of.length === 0 &&
            user.laboratory_subreferent_of.length === 0 &&
            user.qualitician_of.length === 0
        ) {
            setIsOnlyOperator(true)
        }

        if (user.referent_of.length) setIsUserReferent(true)
    })

    const [update_user, updating] = useApi('post', `users/profile/${user_id}`, (err, user: User) => {
        if (err) return console.error(err)

        fetch_user()
    })

    const [inactivate_user, inactivating] = useApi('post', `users/inactivate/${user_id}`, (err, user: User) => {
        if (err) return console.error(err)

        fetch_user()
    })

    const [activate_user, activating] = useApi('post', `users/reactivate/${user_id}`, (err, user: User) => {
        if (err) return console.error(err)

        fetch_user()
    })

    const [archive_user, archiving] = useApi('post', `users/archive/${user_id}`, (err, user: User) => {
        if (err) return console.error(err)

        fetch_user()
    })

    const [unarchive_user, unarchiving] = useApi('post', `users/unarchive/${user_id}`, (err, user: User) => {
        if (err) return console.error(err)

        fetch_user()
    })

    const _is_referent = me && (me.referent_of as unknown as string[]).includes(laboratory_id as unknown as string) ? "referent" : "not referent"

    useEffect(() => {
        fetch_me()
        fetch_certificates()
        fetch_supervised_users()
        // fetch_laboratory_members()
        fetch_user()
        fetch_roles()

    }, [user_id])

    const [show_referent_modal, setShowReferentModal] = useState<boolean>(false)

    const confirm_ref_modal = <EuiConfirmModal
        title={"Attention"}
        onCancel={() => setShowReferentModal(false)}
        onConfirm={() => {
            setIsReferent(!is_referent)
            setShowReferentModal(false)
        }}
        cancelButtonText={"Annuler"}
        confirmButtonText={"Confirmer"}
        buttonColor="primary">
        Attention, vous ne pourrez pas supprimer un administrateur.
        <br /><br />
        L'administrateur pourra :
        <br /><br />
        <ul>
            <li>Accéder et modifier tous les paramètres de l'entreprise</li>
            <li>Créer, suspendre et archiver des utilisateurs</li>
            <li>Créer et modifier des évaluations</li>
            <li>Accéder à toutes les statistiques de tous les évaluations</li>
        </ul>

    </EuiConfirmModal>

    return <EuiFlyout onClose={onClose ? onClose : () => { }}>
        <EuiFlyoutHeader hasBorder>
            <EuiTitle size="m">
                <h2 id={""}>{firstname || "—"} {lastname || "—"} ({username || "—"})</h2>
            </EuiTitle>
            <EuiSpacer />

            <EuiTabs size="s" style={{ marginBottom: '-25px' }}>
                {/* <EuiTab onClick={() => setTab('stats')} isSelected={tab === 'stats'}>Statistiques</EuiTab> */}
                <EuiTab onClick={() => setTab('profile')} isSelected={tab === 'profile'}>Profil</EuiTab>
                <EuiTab onClick={() => setTab('certificates')} isSelected={tab === 'certificates'}>Attestations</EuiTab>
                {/* <EuiTab onClick={() => setTab('subreferent')} isSelected={tab === 'subreferent'}>Désigner comme manager</EuiTab> */}

            </EuiTabs>
        </EuiFlyoutHeader>
        <EuiFlyoutBody>

            {(tab === 'profile' && laboratory) && <>

                {subreferent && <><EuiCallOut title={"Cet utilisateur est supervisé par " + subreferent.firstname + " " + subreferent.lastname} color="primary" iconType="user" /><EuiSpacer /></>}


                {is_inactive && <><EuiCallOut title="Cet utilisateur est inactif" color="warning" iconType="alert" /><EuiSpacer /></>}

                {is_archived && <><EuiCallOut title={"Cet utilisateur est archivé et son contenu sera supprimé le " + delete_on.toLocaleDateString('fr-FR', { timeZone: 'Europe/Paris' })} color="danger" iconType="alert" /><EuiSpacer /></>}

                <EuiPanel hasBorder>



                    <EuiFormRow
                        fullWidth
                        label="Prénom"
                    >
                        <EuiFieldText fullWidth disabled={!_is_referent || is_inactive || is_archived} required value={firstname} onChange={(e) => { setFirstname(e.target.value) }} />
                    </EuiFormRow>

                    <EuiFormRow
                        fullWidth
                        label="Nom"
                    >
                        <EuiFieldText disabled={!_is_referent || is_inactive || is_archived} fullWidth required value={lastname} onChange={(e) => { setLastname(e.target.value) }} />
                    </EuiFormRow>

                    <EuiFormRow
                        fullWidth
                        label="Nom d'utilisateur"
                    >
                        <EuiFieldText disabled fullWidth value={username} onChange={(e) => { }} />
                    </EuiFormRow>

                    <EuiFormRow
                        fullWidth
                        label="Téléphone"
                    >
                        <EuiFieldText fullWidth disabled={!_is_referent || is_inactive || is_archived} value={phone} onChange={(e) => { setPhone(e.target.value) }} />
                    </EuiFormRow>

                    <EuiFormRow
                        fullWidth
                        label="Email"
                    >
                        <EuiFieldText fullWidth disabled={!_is_referent || is_inactive || is_archived} value={email} onChange={(e) => { setEmail(e.target.value) }} />
                    </EuiFormRow>

                    <EuiFormRow
                        fullWidth
                        label="Champ personnalisé 1"
                    >
                        <EuiFieldText fullWidth disabled={!_is_referent || is_inactive || is_archived} value={job_title} onChange={(e) => { setJobTitle(e.target.value) }} />
                    </EuiFormRow>

                    <EuiFormRow
                        fullWidth
                        label="Champ personnalisé 2"
                    >
                        <EuiFieldText fullWidth disabled={!_is_referent || is_inactive || is_archived} value={rpps_number} onChange={(e) => { setRppsNumber(e.target.value) }} />
                    </EuiFormRow>

                </EuiPanel>

                <EuiSpacer />

                <EuiPanel hasBorder>
                    <EuiFormRow
                        fullWidth
                        label="Lieu d'exercice"
                    >
                        <EuiComboBox
                            placeholder="Lieux d'exercice"
                            options={laboratory.possible_places?.map((place) => { return { label: place, value: place } })}
                            onChange={(values) => { setPlaces(values.map((value) => value.value)) }}
                            selectedOptions={(places || []).map((place) => { return { label: place, value: place } })}
                            isClearable={false}
                            fullWidth
                            isDisabled={is_inactive || is_archived}
                        />
                    </EuiFormRow>

                    <EuiFormRow
                        fullWidth
                        label="Poste"
                    >
                        <EuiComboBox
                            placeholder="Postes"
                            options={laboratory.possible_posts?.map((post) => { return { label: post, value: post } })}
                            onChange={(values) => { setPosts(values.map((value) => value.value)) }}
                            selectedOptions={(posts || []).map((post) => { return { label: post, value: post } })}
                            isClearable={false}
                            fullWidth
                            isDisabled={is_inactive || is_archived}
                        />
                    </EuiFormRow>


                </EuiPanel>

                <EuiSpacer />

                {show_referent_modal && confirm_ref_modal}

                <EuiPanel hasBorder>

                    <EuiFormRow fullWidth label="Rôles">

                        <EuiKeyPadMenu checkable={true} style={{ width: "100%" }}>

                            <EuiKeyPadMenuItem checkable="multi" label="Administrateur" isSelected={is_referent} onChange={() => { setShowReferentModal(true) }} isDisabled={is_inactive || is_archived || !email || is_referent}>
                                <EuiIcon type="user" size="m" />
                            </EuiKeyPadMenuItem>

                            <EuiKeyPadMenuItem checkable="multi" label="Auteur" isSelected={is_qualitician} onChange={() => { setIsQualitician(!is_qualitician) }} isDisabled={is_inactive || is_archived || !email || is_qualitician}>
                                <EuiIcon type="user" size="m" />
                            </EuiKeyPadMenuItem>

                            <EuiKeyPadMenuItem checkable="multi" label="Évaluateur" isSelected={is_corrector} onChange={() => { setIsCorrector(!is_corrector) }} isDisabled={is_inactive || is_archived || !email || is_corrector}>
                                <EuiIcon type="user" size="m" />
                            </EuiKeyPadMenuItem>

                            <EuiKeyPadMenuItem checkable="multi" label="Vérificateur" isSelected={is_verificator} onChange={() => { setIsVerificator(!is_verificator) }} isDisabled={is_inactive || is_archived || !email || is_verificator}>
                                <EuiIcon type="user" size="m" />
                            </EuiKeyPadMenuItem>

                            <EuiKeyPadMenuItem checkable="multi" label="Manager" isSelected={is_subreferent} onChange={() => { setIsSubreferent(!is_subreferent) }} isDisabled={is_inactive || is_archived || !email || is_subreferent}>
                                <EuiIcon type="user" size="m" />
                            </EuiKeyPadMenuItem>

                            <EuiKeyPadMenuItem checkable="multi" label="Auditeur interne" isSelected={is_biologist} onChange={() => { setIsBiologist(!is_biologist) }} isDisabled={is_inactive || is_archived || !email || is_biologist}>
                                <EuiIcon type="user" size="m" />
                            </EuiKeyPadMenuItem>

                        </EuiKeyPadMenu>

                    </EuiFormRow>

                </EuiPanel>

                <EuiSpacer />

                {biologist_of.length > 0 && <><EuiPanel hasBorder>
                    <EuiFormRow fullWidth label="Auditeur interne de :"><>
                        <EuiSpacer size="s" />
                        <ul>
                            {biologist_of.map(f => <li>{f.header}</li>)}
                        </ul>
                    </></EuiFormRow>
                </EuiPanel><EuiSpacer /></>}

                {formation_qualitician_of.length > 0 && <><EuiPanel hasBorder>
                    <EuiFormRow fullWidth label="Auteur de :"><>
                        <EuiSpacer size="s" />
                        <ul>
                            {formation_qualitician_of.map(f => <li>{f.header}</li>)}
                        </ul>
                    </></EuiFormRow>
                </EuiPanel><EuiSpacer /></>}

                {formation_corrector_of.length > 0 && <><EuiPanel hasBorder>
                    <EuiFormRow fullWidth label="Évaluateur de :"><>
                        <EuiSpacer size="s" />
                        <ul>
                            {formation_corrector_of.map(f => <li>{f.header}</li>)}
                        </ul>
                    </></EuiFormRow>
                </EuiPanel><EuiSpacer /></>}

                {biologist_verificator_of.length > 0 && <><EuiPanel hasBorder>
                    <EuiFormRow fullWidth label="Vérificateur de :"><>
                        <EuiSpacer size="s" />
                        <ul>
                            {biologist_verificator_of.map(f => <li>{f.header}</li>)}
                        </ul>
                    </></EuiFormRow>
                </EuiPanel><EuiSpacer /></>}


                {((!is_user_referent || is_only_operator) && !is_corrector && !is_verificator) && <EuiPanel hasBorder>
                    {(!is_user_referent) && <EuiFormRow
                        fullWidth
                        label="Désactiver l'utilisateur"
                        helpText="L'opérateur ne pourra plus se connecter à son espace personnel et ne recevra plus de notifications. Cette action est réversible."
                    >

                        {is_inactive ? <EuiButton
                            color="text"
                            fullWidth
                            onClick={() => { activate_user() }}
                            isLoading={activating || inactivating}
                        >
                            Réactiver l'utilisateur {firstname} {lastname}
                        </EuiButton> : <EuiButton
                            color="warning"
                            fullWidth
                            onClick={() => { inactivate_user() }}
                            isLoading={activating || inactivating}
                        >
                            Désactiver l'utilisateur {firstname} {lastname}
                        </EuiButton>}


                    </EuiFormRow>}

                    {is_only_operator && <EuiFormRow
                        fullWidth
                        label="Archiver l'opérateur"
                        helpText={"Le profil et les données associées à cet utilisateur seront supprimées de l'application après 90 jours. Cette action est réversible jusqu'à la date de suppression." + (is_archived ? (" Suppression prévue le " + delete_on.toLocaleDateString('fr-FR', { timeZone: 'Europe/Paris' }) + ".") : "")}
                    >
                        {is_archived ? <EuiButton
                            color="text"
                            fullWidth
                            onClick={() => { unarchive_user() }}
                            isLoading={archiving || unarchiving}
                        >
                            Annuler l'archivage de l'utilisateur {firstname} {lastname}
                        </EuiButton> : <EuiButton
                            color="danger"
                            fullWidth
                            onClick={() => { archive_user() }}
                            isLoading={archiving || unarchiving}
                        >
                            Archiver l'utilisateur {firstname} {lastname}
                        </EuiButton>}

                    </EuiFormRow>}
                </EuiPanel>}

                <EuiSpacer size="l" />

                {_is_referent && <EuiButton fullWidth isLoading={updating} isDisabled={!firstname || !lastname || is_inactive || is_archived} onClick={() => {
                    update_user({
                        firstname,
                        lastname,
                        email,
                        phone,
                        job_title,
                        rpps_number,
                        places,
                        posts,
                        is_referent,
                        is_qualitician,
                        is_corrector,
                        is_verificator,
                        is_subreferent,
                        is_biologist,
                        laboratory_id: laboratory_id ? laboratory_id : null,
                    })
                }}>Sauvegarder</EuiButton>}

            </>}


            {tab === 'certificates' && <>

                {user_certificates.length === 0 && <EuiEmptyPrompt
                    title={<h2>Aucune attestation</h2>}
                    body={<p>Cet utilisateur n'a pas encore reçu d'attestation</p>}
                />}


                {user_certificates?.map(attestation => <>
                    <EuiPanel title={attestation.description}>
                        <EuiFlexGroup>
                            <EuiFlexItem>
                                <EuiTitle>
                                    <h3>{attestation.description}</h3>
                                </EuiTitle>

                                {/* @ts-ignore */}
                                Le {new Date(attestation.createdAt).toLocaleString('fr-FR', { timeZone: 'Europe/Paris' })} à {new Date(attestation.createdAt).toLocaleString('fr-FR', { timeZone: 'Europe/Paris' })}
                            </EuiFlexItem>

                            <EuiFlexItem grow={false}>
                                <a href={API_PATH + "files/certificates/" + attestation._id + '.pdf'} target="_blank">
                                    <EuiButton>Télécharger</EuiButton>
                                </a>
                            </EuiFlexItem>
                        </EuiFlexGroup>
                    </EuiPanel>
                    <EuiSpacer size="m" />
                </>)}

            </>}

        </EuiFlyoutBody>
    </EuiFlyout >
}
