import { EuiButton, EuiComboBox, EuiDescribedFormGroup, EuiFieldText, EuiFilePicker, EuiFlexGroup, EuiFlexItem, EuiFormRow, EuiImage, EuiPanel, EuiSpacer, EuiText, EuiTextArea, EuiTitle } from "@elastic/eui";
import axios from "axios";
import _ from "lodash";
import { throttle } from "lodash";
import { useEffect, useState } from "react";
import { API_PATH, useApi } from "../../effects/useApi";
import { Formation, Laboratory } from "../../types";

const update = _.throttle((cb, st) => { cb(st) }, 500)


export default function Settings({ path, laboratory_id }: { path: string, laboratory_id?: string }) {

    const [laboratory, setLaboratory] = useState<Laboratory | null>(null);

    const [possible_places, setPossiblePlaces] = useState<{ label: string, value: string }[]>([])
    const [possible_posts, setPossiblePosts] = useState<{ label: string, value: string }[]>([])

    const [fetch_laboratory] = useApi('GET', `laboratories/${laboratory_id}`, (err, _laboratory) => {
        if (err) {
            console.error(err)
            return
        }
        setLaboratory(_laboratory)

        setPossiblePlaces(_laboratory.possible_places ? _laboratory.possible_places.map(v => ({ label: v, value: v })) : [])
        setPossiblePosts(_laboratory.possible_posts ? _laboratory.possible_posts.map(v => ({ label: v, value: v })) : [])

        setName(_laboratory.name)
        setSiret(_laboratory.siret)
        setAddress(_laboratory.address)
        setCity(_laboratory.city)
        setPostalCode(_laboratory.postal_code)
        setPhone(_laboratory.phone)
        setCountry(_laboratory.country)

        setLogo(_laboratory.logo)
    })


    const [update_possible_posts, loading_possible_posts] = useApi('PUT', `laboratories/${laboratory_id}/possible_posts`, (err, laboratory) => { 
        if (err) {
            console.error(err)
            return
        }

        setLaboratory(laboratory)
    })

    const [update_possible_places, loading_possible_places] = useApi('PUT', `laboratories/${laboratory_id}/possible_places`, (err, laboratory) => { 
        if (err) {
            console.error(err)
            return
        }

        setLaboratory(laboratory)
    })

    const [update_logo] = useApi('PUT', `laboratories/${laboratory_id}/logo`, (err, laboratory) => {
        if (err) {
            console.error(err)
            return
        }

        setLaboratory(laboratory)
    })

    const [formations, setFormations] = useState<Formation[]>([])

    const [fetch_formations] = useApi('GET', `laboratories/${laboratory_id}/formations`, (err, formations) => {
        if (err) {
            console.error(err)
            return
        }
        setFormations(formations)
    })

    const [name, setName] = useState<string>('')

    const [siret, setSiret] = useState<string>('')
    const [address, setAddress] = useState<string>('')
    const [city, setCity] = useState<string>('')
    const [postal_code, setPostalCode] = useState<string>('')
    const [phone, setPhone] = useState<string>('')
    const [country, setCountry] = useState<string>('')

    const [logo, setLogo] = useState<string | null>(null)

    const [progress, setProgress] = useState(0)
    const [file_size, setFileSize] = useState(0)

    const [update_laboratory, loading] = useApi('PUT', `laboratories/${laboratory_id}`, (err, lab) => {
        if (!err) {
            fetch_laboratory()
        }
    })

    const onChange = (files: FileList) => {

        if (files.length === 0) {
            setLogo(null)
            return
        }

        const formData = new FormData()

        for (let i = 0; i < files.length; i++) { formData.append('file', files[i]) }

        const config = {
            onUploadProgress: progressEvent => { setProgress(progressEvent.loaded); setFileSize(progressEvent.total) },
            headers: { 'content-type': 'multipart/form-data' }
        }

        axios.post(API_PATH + "upload", formData, config)
            .then(({ data }: any) => {
                setLogo(data.media_id)
                update_logo({ logo: data.media_id })
            })
            .catch((error) => { console.error(error) });

    }

    useEffect(() => {
        fetch_laboratory()
        fetch_formations()
    }, [laboratory_id])

    return <>
        <EuiPanel hasBorder>

            <EuiTitle>
                <h2>Coordonnées de l'entreprise</h2>
            </EuiTitle>

            <EuiSpacer size="l" />

            <EuiDescribedFormGroup fullWidth
                title={<h3>Nom de l'entreprise</h3>}
                description="Le nom de l'entreprise tel qu'il sera affiché sur les documents."
            >
                <EuiFormRow fullWidth>
                    <EuiFieldText disabled={loading} required fullWidth value={name} onChange={(e) => setName(e.target.value)} />
                </EuiFormRow>
            </EuiDescribedFormGroup>

            <EuiSpacer size="m" />

            <EuiDescribedFormGroup fullWidth
                title={<h3>Numéro de Siret</h3>}
            >
                <EuiFormRow fullWidth>
                    <EuiFieldText disabled={loading} required fullWidth value={siret} onChange={(e) => setSiret(e.target.value)} />
                </EuiFormRow>
            </EuiDescribedFormGroup>

            <EuiSpacer size="m" />

            <EuiDescribedFormGroup fullWidth
                title={<h3>Numéro de téléphone</h3>}
            >
                <EuiFormRow fullWidth>
                    <EuiFieldText disabled={loading} required fullWidth value={phone} onChange={(e) => setPhone(e.target.value)} />
                </EuiFormRow>
            </EuiDescribedFormGroup>

            <EuiSpacer size="m" />

            <EuiDescribedFormGroup fullWidth
                title={<h3>Coordonnées postales</h3>}
            >
                <EuiFormRow label="Adresse" fullWidth>
                    <EuiTextArea disabled={loading} required fullWidth value={address} onChange={(e) => setAddress(e.target.value)} />
                </EuiFormRow>

                <EuiFormRow label="Code postal" fullWidth>
                    <EuiFieldText disabled={loading} required fullWidth value={postal_code} onChange={(e) => setPostalCode(e.target.value)} />
                </EuiFormRow>

                <EuiFormRow label="Ville" fullWidth>
                    <EuiFieldText disabled={loading} required fullWidth value={city} onChange={(e) => setCity(e.target.value)} />
                </EuiFormRow>

                <EuiFormRow label="Pays" fullWidth>
                    <EuiFieldText disabled={loading} required fullWidth value={country} onChange={(e) => setCountry(e.target.value)} />
                </EuiFormRow>
            </EuiDescribedFormGroup>

            <EuiDescribedFormGroup fullWidth
                title={<h2> </h2>}
            >
                <EuiFormRow fullWidth>
                    <EuiButton isLoading={loading} fullWidth onClick={async () => {
                        if (name && siret && address && city && postal_code && phone && country) update_laboratory({
                            name,
                            siret,
                            address,
                            city,
                            postal_code,
                            phone,
                            country
                        })
                    }}>Modifier l'entreprise</EuiButton>
                </EuiFormRow>
            </EuiDescribedFormGroup>

        </EuiPanel>

        <EuiSpacer />

        <EuiPanel hasBorder>

            <EuiTitle size="m">
                <h3>Champs personnalisables</h3>
            </EuiTitle>

            <EuiText>Ajoutez des valeurs pour filtrer vos membres et les inscrire plus facilement aux évaluations.</EuiText>

            <EuiSpacer size="s" />


            <EuiDescribedFormGroup
                fullWidth
                title={<h3>Lieux d'exercice</h3>}
                description={<>Lieux d'exercice attribuables aux membres.</>}
            >
                <EuiFormRow fullWidth>
                    <EuiComboBox
                        placeholder="Ajoutez un lieu d'exercice"
                        options={possible_places}
                        onChange={(values) => {
                            // @ts-ignore
                            setPossiblePlaces(values)

                            update_possible_places({ possible_places: values.map(v => v.value) })
                        }}

                        selectedOptions={possible_places}
                        // isClearable={true}
                        onCreateOption={(value) => {
                            // @ts-ignore
                            if (possible_places.map(p => p.label).includes(value.label)) { return }

                            setPossiblePlaces([...possible_places, { label: value, value }])
                            update_possible_places({ possible_places: [...possible_places.map(v => v.value), value] })
                        }}
                        isClearable={false}
                        customOptionText="Ajouter un lieu d'exercice : {searchValue}"
                        fullWidth
                        noSuggestions
                        isLoading={loading_possible_places}
                    />
                </EuiFormRow>
            </EuiDescribedFormGroup>

            <EuiSpacer size="m" />

            <EuiDescribedFormGroup
                fullWidth
                title={<h3>Postes</h3>}
                description={<>Postes attribuables aux membres.</>}
            >
                <EuiFormRow fullWidth>

                    <EuiComboBox
                        placeholder="Ajoutez un poste"
                        options={possible_posts}
                        // @ts-ignore
                        onChange={(values) => {
                            // @ts-ignore
                            setPossiblePosts(values)

                            update_possible_posts({ possible_posts: values.map(v => v.value) })
                        }}

                        selectedOptions={possible_posts}
                        // isClearable={true}
                        onCreateOption={(value) => {
                            // @ts-ignore
                            if (possible_posts.map(p => p.label).includes(value.label)) { return }

                            setPossiblePosts([...possible_posts, { label: value, value }])
                            update_possible_posts({ possible_posts: [...possible_posts.map(v => v.value), value] })
                        }}
                        customOptionText="Ajouter un lieu d'exercice : {searchValue}"
                        isClearable={false}
                        fullWidth
                        noSuggestions
                        isLoading={loading_possible_posts}
                    />
                </EuiFormRow>
            </EuiDescribedFormGroup>
        </EuiPanel>

        <EuiSpacer />


        {laboratory?.can_display_logo && <><EuiPanel hasBorder>

            <EuiTitle size="m">
                <h3>Personnaliser votre espace</h3>
            </EuiTitle>

            <EuiSpacer size="s" />

            <EuiDescribedFormGroup
                fullWidth
                title={<h3>Logo de l'entreprise</h3>}
                description={<>
                    Il sera affiché en haut à gauche de votre espace et sur vos attestations.

                    <br /><br />

                    {logo ? <EuiImage
                        alt={"logo"}
                        src={API_PATH + "videos/" + logo}
                        size="s"
                    /> : <></>}

                </>}
            >
                <EuiFormRow fullWidth>
                    <EuiFilePicker
                        fullWidth
                        id="filePicker"
                        initialPromptText="Téléverser un nouveau logo"
                        onChange={onChange}
                    />
                </EuiFormRow>
            </EuiDescribedFormGroup>

        </EuiPanel>

            <EuiSpacer /></>}

        <EuiPanel hasBorder>
            <EuiTitle>
                <h2>Identifiant externe des évaluations</h2>
            </EuiTitle>

            <EuiSpacer size="l" />

            {formations.map((formation, index) => {

                return <FormationExternalId
                    key={index}
                    formation={formation}
                />
            })}

        </EuiPanel>
    </>
}

const FormationExternalId = ({ formation }: { formation: Formation }) => {
    const [set_external_id, upd] = useApi('PUT', `formations/${formation._id}/external_id`, (err, formation) => {
        if (err) {
            console.error(err)
            return
        }

        // setLaboratory(laboratory)
    })

    return <EuiDescribedFormGroup fullWidth
        title={<h3>{formation.header}</h3>}
        description={<>Identifiant externe de la formation {formation.header}.</>}
    >
        <EuiFormRow fullWidth>
            <EuiFieldText isLoading={upd} fullWidth value={formation.external_id} onChange={(e) => {
                update(set_external_id, { external_id: e.target.value })
            }} />
        </EuiFormRow>
    </EuiDescribedFormGroup>

}