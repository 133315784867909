import { EuiLoadingContent } from "@elastic/eui"
import { useEffect, useState } from "react"
import { useApi } from "../../effects/useApi"
import { CourseModuleInstance, FormationVersionInstance, ModuleInstance, PracticalEvaluationModuleInstance, TheoricEvaluationModuleInstance } from "../../types"
import CourseModule from "./CourseModule"
import PracticalEvaluationModule from "./PracticalEvaluationModule"
import TheoricEvaluationModule from "./TheoricEvaluationModule"

export default function ModulenstancePage({ path, formation_instance_id, module_instance_id, navigate, refetch_formation, formation_version_instance }: { path: string, formation_instance_id?: string, module_instance_id?: string, navigate?: (path: string) => void, refetch_formation?: () => void, formation_version_instance: FormationVersionInstance }) {

	const [module_instance, setModuleInstance] = useState<ModuleInstance | undefined>(undefined)

	const [fetch_module_instance] = useApi('GET', `formation_instances/${formation_instance_id}/module_instances/${module_instance_id}`, async (err, module_instance) => {
		setModuleInstance(module_instance)
	})

	useEffect(() => {
		fetch_module_instance()
	}, [module_instance_id])

	if (!module_instance) return <EuiLoadingContent lines={5} />

	if (module_instance.type === "CourseModuleInstance") return <CourseModule formation_instance_id={formation_instance_id} formation_version_instance={formation_version_instance} module_instance={module_instance as CourseModuleInstance} refetch_formation={refetch_formation} navigate={navigate} />

	if (module_instance.type === "TheoricEvaluationModuleInstance") return <TheoricEvaluationModule formation_instance_id={formation_instance_id} formation_version_instance={formation_version_instance} module_instance={module_instance as TheoricEvaluationModuleInstance} refetch_formation={refetch_formation} refetch_module_instance={fetch_module_instance} navigate={navigate} />

	if (module_instance.type === "PracticalEvaluationModuleInstance") return <PracticalEvaluationModule formation_instance_id={formation_instance_id} formation_version_instance={formation_version_instance} module_instance={module_instance as PracticalEvaluationModuleInstance} refetch_formation={refetch_formation} refetch_module_instance={fetch_module_instance} />

	return <pre>
		{JSON.stringify(module_instance, null, 2)}
	</pre>
}