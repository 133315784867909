import { EuiButton, EuiCallOut, EuiComboBox, EuiConfirmModal, EuiFieldText, EuiFlyout, EuiFlyoutBody, EuiFlyoutHeader, EuiFormRow, EuiIcon, EuiKeyPadMenu, EuiKeyPadMenuItem, EuiPanel, EuiSpacer, EuiText, EuiTitle } from "@elastic/eui";
import { useState } from "react";
import { useApi } from "../../effects/useApi";
import { Laboratory, User } from "../../types";

export default function NewUserFlyout({
    onClose,
    are_new_members_allowed,
    laboratory,
    refetch_laboratory,
    refetch_members,
    is_current_user_subreferent,
    subreferent_id
}: {
    onClose: () => void,
    are_new_members_allowed: boolean,
    laboratory: Laboratory,
    refetch_laboratory: () => void,
    refetch_members: () => void,
    is_current_user_subreferent: boolean,
    subreferent_id?: string
}) {

    const [error_message, set_error_message] = useState<string | undefined>(undefined)

    const [new_firstname, setNewFirstname] = useState<string>('')
    const [new_last_name, setNewLastname] = useState<string>('')
    const [new_email, setNewEmail] = useState<string>('')
    const [new_phone, setNewPhone] = useState<string>('')
    const [new_job_title, setNewJobTitle] = useState<string>('')
    const [new_rpps_number, setNewRppsNumber] = useState<string>('')

    const [new_places, setNewPlaces] = useState<string[]>([])
    const [new_posts, setNewPosts] = useState<string[]>([])

    const [is_qualitician, setIsQualitician] = useState<boolean>(false)
    const [is_corrector, setIsCorrector] = useState<boolean>(false)
    const [is_verificator, setIsVerificator] = useState<boolean>(false)
    const [is_subreferent, setIsSubreferent] = useState<boolean>(false)
    const [is_biologist, setIsBiologist] = useState<boolean>(false)
    const [is_referent, setIsReferent] = useState<boolean>(false)

    const [users_to_create, setUsersToCreate] = useState<Partial<User>[]>([])

    const [user_added, setUserAdded] = useState<boolean>(false)

    const [supervise_user] = useApi('post', `users/supervised_users`, (err, users: User[]) => {
        if (err) return console.error(err)

        refetch_laboratory()
        refetch_members()
    })

    const [unsupervise_user] = useApi('delete', `users/supervised_users`, (err, users: User[]) => {
        if (err) return console.error(err)

        refetch_laboratory()
        refetch_members()
    })

    const [create_users, creating_users] = useApi('POST', 'users/batch', async (err, users) => {

        if (!err) {
            setUsersToCreate([])
            // setShowingFlyout(false)
            // setAddingUsers(false)

            setNewFirstname('')
            setNewLastname('')
            setNewEmail('')
            setNewPhone('')
            setNewJobTitle('')
            setNewRppsNumber('')
            setNewPlaces([])
            setNewPosts([])

            setIsQualitician(false)
            setIsCorrector(false)
            setIsVerificator(false)
            setIsSubreferent(false)
            setIsBiologist(false)

            setUserAdded(true)

            if (is_current_user_subreferent) {
                await supervise_user({
                    user_id: users[0]._id,
                    subreferent_id: subreferent_id
                })
            }

            // await fetch_members()
            // await fetch_laboratory()

            await refetch_laboratory()
            await refetch_members()

        } else {
            console.error(err)
            // setAddingUsers(false)
            set_error_message(err.message)
        }
    })

    const [show_referent_modal, setShowReferentModal] = useState<boolean>(false)

    const confirm_ref_modal = <EuiConfirmModal
        title={"Attention"}
        onCancel={() => setShowReferentModal(false)}
        onConfirm={() => {
            setIsReferent(!is_referent)
            setShowReferentModal(false)
        }}
        cancelButtonText={"Annuler"}
        confirmButtonText={"Confirmer"}
        buttonColor="primary">
        Attention, vous ne pourrez pas supprimer un administrateur.
        <br /><br />
        L'administrateur pourra :
        <br /><br />
        <ul>
            <li>Accéder et modifier tous les paramètres de l'entreprise</li>
            <li>Créer, suspendre et archiver des utilisateurs</li>
            <li>Créer et modifier des évaluations</li>
            <li>Accéder à toutes les statistiques de toutes les évaluations</li>
        </ul>

    </EuiConfirmModal>


    return <EuiFlyout onClose={onClose}>
        <EuiFlyoutHeader hasBorder >
            <EuiTitle>
                <h2>Ajouter des membres à l'entreprise</h2>
            </EuiTitle>
        </EuiFlyoutHeader>
        <EuiFlyoutBody>

            <EuiText>
            Si vous souhaitez importer plusieurs utilisateurs à la fois, envoyez <a href="../../users.xlsx" target="_blank" > ce fichier </a> rempli à <a href="mailto:hello@360skillvue.com">hello@360skillvue.com</a > afin que nous puissions nous en charger.
            </EuiText>

            <EuiSpacer size="l" />

            {!are_new_members_allowed && <><EuiCallOut title="Vous avez atteint le nombre maximum d'utilisateurs autorisés pour cette entreprise" color="danger" iconType="alert" /> <EuiSpacer /></ >}

            {
                user_added && <>
                    <EuiCallOut
                        size="s"
                        title="Utilisateur ajouté"
                        iconType="user" >
                    </EuiCallOut>
                    <EuiSpacer size="m" />
                </>}

            <EuiPanel color="subdued" >
                <EuiFormRow label="Prénom" fullWidth >
                    <EuiFieldText value={new_firstname} required onChange={(e) => { setNewFirstname(e.target.value); setUserAdded(false) }} fullWidth disabled={!are_new_members_allowed} />
                </EuiFormRow>

                <EuiFormRow label="Nom" fullWidth >
                    <EuiFieldText value={new_last_name} required onChange={(e) => { setNewLastname(e.target.value); setUserAdded(false) }} fullWidth disabled={!are_new_members_allowed} />
                </EuiFormRow>

                {!is_current_user_subreferent && <EuiFormRow label="Email" fullWidth helpText="Nous vous conseillons de ne pas renseigner l’email pour les opérateurs qui n’ont pas un accès facile à leur messagerie." isInvalid={error_message ? true : false} error={error_message}>
                    <EuiFieldText value={new_email} onChange={(e) => { setNewEmail(e.target.value); setUserAdded(false) }} fullWidth disabled={!are_new_members_allowed} />
                </EuiFormRow>}

                <EuiFormRow label="Téléphone" fullWidth >
                    <EuiFieldText value={new_phone} onChange={(e) => { setNewPhone(e.target.value); setUserAdded(false) }} fullWidth disabled={!are_new_members_allowed} />
                </EuiFormRow>

                <EuiFormRow label="Champ personnalisé 1" fullWidth >
                    <EuiFieldText value={new_job_title} onChange={(e) => { setNewJobTitle(e.target.value); setUserAdded(false) }} fullWidth disabled={!are_new_members_allowed} />
                </EuiFormRow>

                <EuiFormRow label="Champ personnalisé 2" fullWidth >
                    <EuiFieldText value={new_rpps_number} onChange={(e) => { setNewRppsNumber(e.target.value); setUserAdded(false) }} fullWidth disabled={!are_new_members_allowed} />
                </EuiFormRow>

                <EuiFormRow
                    fullWidth
                    label="Lieu d'exercice"
                >
                    <EuiComboBox
                        placeholder="Lieux d'exercice"
                        options={laboratory.possible_places?.map((place) => { return { label: place, value: place } })}
                        onChange={(values) => { setNewPlaces(values.map((value) => value.value)) }}
                        selectedOptions={new_places.map((place) => { return { label: place, value: place } })}
                        isClearable={false}
                        fullWidth
                        isDisabled={!are_new_members_allowed}
                    />
                </EuiFormRow>

                <EuiFormRow
                    fullWidth
                    label="Poste"
                >
                    <EuiComboBox
                        placeholder="Postes"
                        options={laboratory.possible_posts?.map((post) => { return { label: post, value: post } })}
                        onChange={(values) => { setNewPosts(values.map((value) => value.value)) }}
                        selectedOptions={new_posts.map((post) => { return { label: post, value: post } })}
                        isClearable={false}
                        fullWidth
                        isDisabled={!are_new_members_allowed}
                    />
                </EuiFormRow>

                {show_referent_modal && confirm_ref_modal}

                {!is_current_user_subreferent && <EuiFormRow
                    fullWidth
                    label="Rôles"
                >
                    <EuiKeyPadMenu checkable={true} style={{ width: "100%" }}>

                        <EuiKeyPadMenuItem checkable="multi" label="Administrateur" isSelected={is_referent} onChange={() => { if(!is_referent) setShowReferentModal(true) }} isDisabled={!are_new_members_allowed || !new_email}>
                            <EuiIcon type="user" size="m" />
                        </EuiKeyPadMenuItem>

                        <EuiKeyPadMenuItem checkable="multi" label="Auteur" isSelected={is_qualitician} onChange={() => { setIsQualitician(!is_qualitician) }} isDisabled={!are_new_members_allowed || !new_email}>
                            <EuiIcon type="user" size="m" />
                        </EuiKeyPadMenuItem>

                        <EuiKeyPadMenuItem checkable="multi" label="Évaluateur" isSelected={is_corrector} onChange={() => { setIsCorrector(!is_corrector) }} isDisabled={!are_new_members_allowed || !new_email}>
                            <EuiIcon type="user" size="m" />
                        </EuiKeyPadMenuItem>

                        <EuiKeyPadMenuItem checkable="multi" label="Vérificateur" isSelected={is_verificator} onChange={() => { setIsVerificator(!is_verificator) }} isDisabled={!are_new_members_allowed || !new_email}>
                            <EuiIcon type="user" size="m" />
                        </EuiKeyPadMenuItem>

                        <EuiKeyPadMenuItem checkable="multi" label="Manager" isSelected={is_subreferent} onChange={() => { setIsSubreferent(!is_subreferent) }} isDisabled={!are_new_members_allowed || !new_email}>
                            <EuiIcon type="user" size="m" />
                        </EuiKeyPadMenuItem>

                        <EuiKeyPadMenuItem checkable="multi" label="Auditeur interne" isSelected={is_biologist} onChange={() => { setIsBiologist(!is_biologist) }} isDisabled={!are_new_members_allowed || !new_email}>
                            <EuiIcon type="user" size="m" />
                        </EuiKeyPadMenuItem>

                    </EuiKeyPadMenu>
                </EuiFormRow>}


                <EuiSpacer size="m" />

                <EuiButton fullWidth disabled={!new_firstname || !new_last_name || creating_users || !are_new_members_allowed} onClick={() => {
                    // setAddingUsers(true);
                    create_users({
                        laboratory: laboratory._id,
                        users: [{
                            firstname: new_firstname,
                            lastname: new_last_name,
                            email: new_email,
                            phone: new_phone,
                            job_title: new_job_title,
                            rpps_number: new_rpps_number,
                            places: new_places,
                            posts: new_posts,
                            is_referent: is_referent,
                            is_qualitician: is_qualitician,
                            is_corrector: is_corrector,
                            is_verificator: is_verificator,
                            is_subreferent: is_subreferent,
                            is_biologist: is_biologist,
                        }]
                    })
                }}>
                    Ajouter un utilisateur
                </EuiButton>


            </EuiPanel>

        </EuiFlyoutBody>
    </EuiFlyout>
}